import type { Resource } from 'i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { EN_TRANSLATIONS, FR_TRANSLATIONS } from './languages';

export type TranslationsType = {
  [key in keyof typeof EN_TRANSLATIONS]: string;
};

const resources: Resource = {
  fr: {
    translation: FR_TRANSLATIONS,
  },
  en: {
    translation: EN_TRANSLATIONS,
  },
};

const supportedLanguages: string[] = ['fr', 'en'];

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: supportedLanguages[0],
    interpolation: {
      escapeValue: false,
    },
    resources: resources,
    supportedLngs: supportedLanguages,
  });

export default i18n;
