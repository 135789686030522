import { notificationsAPI } from './apis';

export default class NotificationsService {
  static async getChatNotifications(signal?: AbortSignal) {
    const response = await notificationsAPI.get(`/v1/notifications`, {
      signal,
    });
    return response;
  }

  static async readChatNotifications(signal?: AbortSignal) {
    const response = await notificationsAPI.patch(`/v1/notifications`, {
      signal,
    });
    return response;
  }
}
