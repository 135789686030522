export const operationsErrors = {
  transactions: {
    BAD_TRANSACTION_IS_ALREADY_VALIDATED_BY_ACCOUNTANT:
      'BAD_TRANSACTION_IS_ALREADY_VALIDATED_BY_ACCOUNTANT',
    RECEIPT_ALREADY_ASSIGNED_TO_OTHER_TRANSACTIONS:
      'RECEIPT_ALREADY_ASSIGNED_TO_OTHER_TRANSACTIONS',
  },
  FiscalYear: {
    YEAR_IS_LOCKED: 'YEAR_IS_LOCKED',
  },
  invoices: {
    RECEIPT_ALREADY_VALIDATED_BY_ACCOUNTANT:
      'RECEIPT_ALREADY_VALIDATED_BY_ACCOUNTANT',
  },
};
