import type { FC } from 'react';

import { Backdrop } from '@mui/material';

import { UveyWindow } from '@/assets/images';

interface SuspenseFallbackProps {}

const SuspenseFallback: FC<SuspenseFallbackProps> = () => {
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
    >
      <img src={UveyWindow} alt="logo" height={90} width={90} />
    </Backdrop>
  );
};

export default SuspenseFallback;
