import { type FC, useState } from 'react';

import {
  Close,
  DashboardOutlined,
  ExitToAppOutlined,
  Menu,
  Percent,
  SettingsOutlined,
} from '@mui/icons-material';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Logo } from '@/assets/images';
import { useAppDispatch } from '@/hooks';
import {
  setCompanySubscription,
  setConnectedUser,
} from '@/store/actions/action-creators';
import { APP_VERSION } from '@/utils/constants';

import {
  LangSelector,
  Notification,
  PageCounter,
  Profile, // ThemeButton,
} from '../../Navbar';

interface NavbarProps {}

const Navbar: FC<NavbarProps> = () => {
  const dispatch = useAppDispatch();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleSignOut = () => {
    dispatch(setConnectedUser(null));
    dispatch(setCompanySubscription(null));
    localStorage.removeItem('access-token');
    document.cookie =
      'refresh_token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    navigate('/');
  };

  const runAndHideMenu = (callback: any) => {
    callback();
    setIsMenuOpen(false);
  };

  const menuItems = [
    {
      type: 'item',
      icon: <DashboardOutlined className="text-xs" />,
      label: t('Dashboard'),
      onClick: () => runAndHideMenu(() => navigate('/accounting')),
    },
    {
      type: 'item',
      icon: <NotificationsOutlinedIcon />,
      label: t('Notification'),
      onClick: () => runAndHideMenu(() => navigate('/dashboard/notifications')),
    },
    {
      type: 'item',
      icon: <Percent />,
      label: t('Accounting'),
      onClick: () =>
        runAndHideMenu(() => navigate('/dashboard/accounting/export-data')),
    },
    {
      type: 'item',
      icon: <SettingsOutlined className="text-xs" />,
      label: t('Settings'),
      onClick: () => runAndHideMenu(() => navigate('/settings/profile')),
    },
  ];

  return (
    <>
      {/* //? Default Navbar */}
      <div className="hidden min-[800px]:flex fixed top-0 z-50  items-center w-full gap-12 py-2 pr-6 bg-first scroll-bar-style z-index ">
        <div className="flex items-center grow">
          <div className="min-w-[250px] flex items-center">
            <div className="w-[80%] ml-auto mr-auto items-center relative bottom-2 ">
              <Logo />
              {/* <Company /> */}
            </div>
          </div>
        </div>

        <div className="flex gap-6 items-center">
          {/* //! Disabled for non-function right */}
          {/* <ThemeButton /> */}
          <LangSelector />
          <Notification />
          <Profile />
        </div>
      </div>

      {/* //? Navbar Menu for mobile */}
      <div className="min-[800px]:hidden max-[800px]:flex fixed top-0 z-50 items-center justify-between w-full pt-4 pb-4 pr-6 bg-first scroll-bar-style z-index">
        <div className="flex items-center grow "></div>
        <button
          onClick={() => setIsMenuOpen(true)}
          className="flex items-center justify-end"
        >
          <Menu className="cursor-pointer" />
        </button>
      </div>

      {/* //? Navbar (Sidebar) for mobile */}
      <div
        style={{
          right: isMenuOpen ? 0 : '-100%',
        }}
        className="h-full  bg-first z-50 w-4/5 fixed flex flex-col justify-between duration-300"
      >
        <div className="flex items-center justify-between w-full absolute top-3">
          {/* //! Keep it just in case  */}
          <div className="flex justify-center px-4 py-2 w-full">
            <PageCounter />
          </div>
          <button
            onClick={() => setIsMenuOpen(false)}
            className="w-full flex items-center justify-end p-6 w-full "
          >
            <Close className="cursor-pointer" />
          </button>
        </div>

        <div className="flex flex-col gap-2 mt-28">
          {menuItems.map((item, idx) => (
            <div key={idx}>
              <div
                className="flex cursor-pointer text-[#797e8a] gap-2 font-bold hover:bg-[#eff3fd] px-5 py-3 duration-300 whitespace-nowrap"
                onClick={item.onClick}
              >
                {item.icon}
                <p>{item.label}</p>
              </div>
              <hr />
            </div>
          ))}
        </div>

        <div className="mb-8">
          <div className="flex w-full p-6 items-start justify-between relative ">
            <button
              onClick={() => runAndHideMenu(() => handleSignOut())}
              className="flex cursor-pointer text-[#797e8a] gap-2 font-bold "
            >
              <ExitToAppOutlined className="text-xs rotate-180" />
              <span>{t('Sign Out')}</span>
            </button>
            <LangSelector mobileView />
          </div>
          <h4 className="text-center pt-4 text-black pb-4">V {APP_VERSION}</h4>
        </div>
      </div>
    </>
  );
};

export default Navbar;
