import * as yup from 'yup';

import type {
  InvoiceType,
  PaymentMethod,
} from '@/pages/dashboard/Receipts/enums';
import type { PaymentAction, Vat } from '@/services';

export type ReceiptFileFormType = {
  file: File[];
};

export const ReceiptFileFormSchema = yup.object().shape({
  file: yup.mixed(),
});

export const PaymentActionFormSchema = yup.object().shape({
  paymentChannel: yup.string().required(),
  source: yup.string().required(),
  amount: yup
    .number()
    .required('Amount is required in TND')
    .typeError('Amount is required in TND')
    .positive()
    .moreThan(0, 'Amount must be greater than 0'),
  date: yup.string().required(),
});

export const ReceiptFormUpdateSchema = yup.object().shape({
  categoryCode: yup.string().required(),
  invoiceDate: yup.string().required().nullable(),
  totalAmount: yup.string().required(),
});

export type ReceiptForm = {
  supplierName: string;
  invoiceDate: Date | string;
  invoiceType: InvoiceType | null;
  totalAmount: number | null;
  originalAmount: string | number | null;
  originalTotalTaxAmount: string | number | null;
  paymentActions: PaymentAction[] | null;
  vatList?: Vat[] | null;
  categoryCode: string | null;
  currency: string | null;
  currencyTnd: string | null;
};

export type ReceiptFormUpdate = {
  invoiceDate: Date;
  invoiceType: InvoiceType;
  totalAmount: number;
  paymentActions: PaymentAction[];
  categoryCode: string;
};

export type PaymentActionForm = {
  paymentChannel?: PaymentMethod;
  paymentActionSource?: string;
  amount?: number;
  paymentDate?: Date | null;
  associatedTransactionId?: string | null;
};
