import type { FC } from 'react';
import React, { useEffect, useState } from 'react';

import { Close } from '@mui/icons-material';
import { Dialog } from '@mui/material';
import debounce from 'debounce-promise';

import { empty } from '@/assets/images/dashboard';
import { Input, LoadingSpinner } from '@/components/global';
import { useAppTranslation } from '@/hooks';
import { AccountingService } from '@/services';

import type {
  CategoriesRes,
  Category,
} from '../../Accounting/AccountingPlan/AccountingPlan';

interface CategoryModalProps {
  isOpen: boolean;
  onClose: () => void;
  action: (category: Category) => void;
}

enum CategoryStatus {
  INITIAL,
  IN_PROGRESS,
  SUCCESS,
  ERROR,
}

const CategoryModal: FC<CategoryModalProps> = ({ isOpen, onClose, action }) => {
  const { t } = useAppTranslation();

  const [categories, setCategories] = useState<CategoriesRes>();
  const [categoryStatus, setCategoryStatus] = useState(CategoryStatus.INITIAL);

  const [filters, setFilters] = useState({
    page: 1,
    query: '',
    code: null as unknown as number,
    pageSize: 99999,
  });

  const getCategoriesList = () => {
    setCategoryStatus(CategoryStatus.IN_PROGRESS);
    AccountingService.getCategories(filters)
      .then(({ data }) => {
        setCategoryStatus(CategoryStatus.SUCCESS);
        setCategories(data || []);
      })
      .catch(() => {
        setCategoryStatus(CategoryStatus.ERROR);
      });
  };

  useEffect(() => {
    getCategoriesList();
  }, [filters]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilters({ ...filters, page: 1, query: event.target.value });
  };

  const debounceSearchQueryChange = debounce(handleSearch, 500);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      onClick={(e) => {
        e.cancelable = true;
        e.stopPropagation();
      }}
      PaperProps={{
        style: {
          backgroundColor: '#F3F5F8',
          minHeight: 600,
        },
      }}
      fullWidth
    >
      <div className="relative h-full w-full">
        <div className="bg-[#F3F5F8] px-8 fixed ">
          <div className=" p-4 bg-[#F3F5F8] relative w-[525px]">
            <div className="py-6 flex justify-between items-center">
              <h1 className="font-bold"> {t('Select category')} </h1>

              <button onClick={onClose} className="cursor-pointer">
                <Close />
              </button>
            </div>

            <div className="w-full">
              <Input
                onChange={debounceSearchQueryChange}
                placeholder={t('SEARCH_BY_CODE_OR_LABEL_NAME')}
                containerCls={'w-full'}
              />
            </div>
          </div>
        </div>

        {categoryStatus === CategoryStatus.IN_PROGRESS ? (
          <div className="h-[400px] w-full flex items-center justify-center">
            <LoadingSpinner width="40" strokeWidth="1" strokeColor="blue" />
          </div>
        ) : (
          <table className="w-full mt-4 text-left text-gray-500 mt-28 mt-[170px]">
            <thead className="text-gray-700 border-b">
              <tr>
                {/* En-tête du tableau */}
                {COLUMNS?.map((column) => (
                  <th
                    scope="col"
                    className="px-6 py-2 !text-xs !font-bold text-[#8D98AF]"
                    key={column.id}
                  >
                    {t(column.label)}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="h-full min-h-[400px]">
              {categories?.categories?.length ? (
                categories?.categories?.map(
                  (row) =>
                    row?.displayable && (
                      <tr
                        onClick={() => action(row)}
                        className="bg-white h-[40px] border-bottom text-xs font-bold cursor-pointer hover:bg-slate-100 ease-in-out duration-200"
                        key={row?.id}
                      >
                        <td className="color-black px-4 max-h-[50px]">
                          {row?.code}
                        </td>
                        <td> {row?.label}</td>
                      </tr>
                    )
                )
              ) : (
                <tr className="relative w-full items-center justify-center">
                  <td className="absolute w-full flex flex-col items-center py-4">
                    <img src={empty} alt="empty" />
                    <div className="text-center w-full h-full flex flex-col gap-2">
                      <span className="color-gray text-[25px] font-bold">
                        {t('There are no category')}
                      </span>

                      <p>
                        {t(
                          'you can add a new category by clicking the button above'
                        )}
                      </p>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
    </Dialog>
  );
};

const COLUMNS = [
  { id: 1, label: 'Number' },
  { id: 2, label: 'Name' },
];

export default CategoryModal;
