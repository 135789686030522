import { type FC, useEffect, useState } from 'react';

import {
  ContentCut,
  KeyboardBackspaceRounded,
  Shortcut,
} from '@mui/icons-material';
import { PDFDocument } from 'pdf-lib';
import { Document, Page, pdfjs } from 'react-pdf';
import { useLocation, useNavigate } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/keyboard';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Keyboard, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { LoadingSpinner } from '@/components/global';
import Button from '@/components/global/Button';
import { useAppDispatch, useAppSelector } from '@/hooks';
import useSize, { useAppTranslation } from '@/hooks/app-hooks';
import { appToast } from '@/lib/ToastContainers';
import { OperationsService, SaasService, type SplitMetaData } from '@/services';
import {
  setCreditCounter,
  setKpiValidationReceipt,
  setKpiValidationTransaction,
} from '@/store/actions/action-creators';

import SplittingShortcutsModal from './SplittingShortcutsModal';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
enum Loading {
  INITIAL,
  IN_PROGRESS,
  SUCCESS,
  ERROR,
}
enum RequestStatus {
  IS_SUCCESS = 'IS_SUCCESS',
  IS_ERROR = 'IS_ERROR',
  IS_LOADING = 'IS_LOADING',
}

const DocumentSplittingPage: FC = () => {
  const location = useLocation();
  const state = location.state;

  const { t } = useAppTranslation();
  const navigate = useNavigate();

  const [pages, setPages] = useState<string[]>([]);
  const [splitState, setSplitState] = useState<boolean[]>([]);
  const [uploadReceiptsState, setUploadReceiptsState] = useState<Loading>(
    Loading.INITIAL
  );
  const pdfBytes = state?.pdfBytes;
  const metaData: SplitMetaData = state?.metaData;
  const [numberOfInvoices, setNumberOfInvoices] = useState<number>(
    metaData?.nb_invoices || 0
  );
  const [numberOfPagesPerReceipt, setNumberOfPagesPerReceipt] =
    useState<number>(1);
  const [isShortcutModal, setIsShortcutsModal] = useState(false);

  const { windowWidth } = useSize();
  const fiscalYear = useAppSelector((store) => store.global.fiscalYear);
  const dispatch = useAppDispatch();

  const handleShortcutModal = () => setIsShortcutsModal(!isShortcutModal);

  const getPDFPageDisplaySize = () => {
    if (windowWidth > 1500) {
      return 305;
    } else if (windowWidth > 1280) {
      return 260;
    } else if (windowWidth > 1200) {
      return 305;
    } else if (windowWidth > 920) {
      return 260;
    } else {
      return 300;
    }
  };
  const [pageSize, setPageSize] = useState<number>(getPDFPageDisplaySize());

  useEffect(() => {
    const splitPDFIntoPages = async () => {
      const pdfDoc = await PDFDocument.load(pdfBytes || '');
      const totalPages = pdfDoc.getPageCount();
      const pageArray: string[] = [];

      for (let pageNumber = 0; pageNumber < totalPages; pageNumber++) {
        const newPdfDoc = await PDFDocument.create();
        const [newPage] = await newPdfDoc.copyPages(pdfDoc, [pageNumber]);
        newPdfDoc.addPage(newPage);

        const newPageBytes = await newPdfDoc.save();
        const newPageBlob = new Blob([newPageBytes], {
          type: 'application/pdf',
        });
        const newPageUrl = URL.createObjectURL(newPageBlob);

        pageArray.push(newPageUrl);
      }

      setPages(pageArray);
    };

    splitPDFIntoPages();
    const splitStateArray = new Array(pages.length).fill(false);
    metaData &&
      metaData.invoices?.forEach((invoiceDelimiter) => {
        const { end_page } = invoiceDelimiter;
        splitStateArray[end_page] = true;
      });
    setSplitState(splitStateArray);
  }, [pdfBytes]);

  const updateSplitState = (index: number) => {
    const newSplitState = [...splitState];
    newSplitState[index] = !newSplitState[index];
    setSplitState(newSplitState);
    if (newSplitState[index] === true) {
      setNumberOfInvoices(numberOfInvoices + 1);
    } else {
      setNumberOfInvoices(numberOfInvoices - 1);
    }
  };

  useEffect(() => {
    setPageSize(getPDFPageDisplaySize());
  }, [windowWidth]);

  const updateMetaDataCustomAndSubmit = () => {
    //handle the case where the latest page is not marked true
    const verifiedSplitState = [...splitState];
    verifiedSplitState[verifiedSplitState.length - 1] = true;
    setSplitState(verifiedSplitState);

    const newSplitMetaData: SplitMetaData = { invoices: [], nb_invoices: 0 };
    splitState.forEach((page, index) => {
      if (page) {
        //start page is the latest end page + 1 of invoices array if no element is there than it's 0
        const start_page =
          newSplitMetaData.nb_invoices > 0
            ? newSplitMetaData.invoices[newSplitMetaData.nb_invoices - 1]
                .end_page + 1
            : 0;

        const end_page = index;
        newSplitMetaData.invoices.push({ start_page, end_page });
        newSplitMetaData.nb_invoices++;
      }
    });
    handleSubmit(newSplitMetaData);
  };

  const updateMetaDataFromPatternAndSubmit = () => {
    const newSplitMetaData: SplitMetaData = { invoices: [], nb_invoices: 0 };
    for (let i = 0; i < pages.length; i += numberOfPagesPerReceipt) {
      const start_page = i;

      let end_page = i + numberOfPagesPerReceipt - 1;
      if (i + numberOfPagesPerReceipt > pages.length)
        end_page = pages.length - 1;
      newSplitMetaData.invoices.push({ start_page, end_page });
      newSplitMetaData.nb_invoices++;
    }

    handleSubmit(newSplitMetaData);
  };

  const handleSubmit = async (newSplitMetaData: SplitMetaData) => {
    const pdfDoc = await PDFDocument.load(pdfBytes || '');
    const newPdfFiles: File[] = [];
    const invoiceDelimiters = newSplitMetaData.invoices;
    for (let { start_page, end_page } of invoiceDelimiters) {
      const newPdfDoc = await PDFDocument.create();
      for (let i = start_page; i <= end_page; i++) {
        const [copiedPage] = await newPdfDoc.copyPages(pdfDoc, [i]);
        newPdfDoc.addPage(copiedPage);
      }
      const newPdfBytes = await newPdfDoc.save();
      const newBlob = new Blob([newPdfBytes], { type: 'application/pdf' });
      const newFile = new File(
        [newBlob],
        `document_${start_page}_to_${end_page}.pdf`,
        { type: 'application/pdf' }
      );
      newPdfFiles.push(newFile);
    }
    uploadReceipts(newPdfFiles);
  };

  const uploadReceipts = (files: any) => {
    setUploadReceiptsState(Loading.IN_PROGRESS);
    const FILE_ID = appToast.loading(t('UPLOADING_FILES'));
    if (files && files.length > 0) {
      const pendingFiles = [] as unknown as any[];

      for (const element of files) {
        const file = element;
        const updatedFile = {
          name: file.name,
          type: file.type === 'application/pdf' ? 'document' : 'image',
          size: (file.size / 1024).toFixed(2) + ' KB',
          progress: 100,
          isLoading: RequestStatus.IS_LOADING,
        };

        pendingFiles.push(updatedFile); // Add to pending files
      }

      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        let formData = new FormData();
        formData.append('file', file);
        formData.append('fiscalYear', fiscalYear);

        const updatedFilesCopy = [...pendingFiles];

        OperationsService.createReceipt(formData)
          .then(() => {
            updatedFilesCopy[i].isLoading = RequestStatus.IS_SUCCESS;
            i == files.length - 1 && setUploadReceiptsState(Loading.SUCCESS);
            FILE_ID && appToast.dismiss(FILE_ID);
            appToast.success(t('FILE_UPLOADED_SUCCESSFULLY'));

            OperationsService.getKpiValidation().then((res) => {
              dispatch(
                setKpiValidationReceipt(res.data?.validatedReceiptNumber)
              );
              dispatch(
                setKpiValidationTransaction(
                  res.data?.validatedTransactionNumber
                )
              );
              SaasService.getCredits().then(({ data }) => {
                dispatch(setCreditCounter(data));

                navigate('/dashboard/receipts/');
              });
            });
          })
          .catch(() => {
            updatedFilesCopy[i].isLoading = RequestStatus.IS_ERROR;
            i == files.length - 1 && setUploadReceiptsState(Loading.ERROR);
            FILE_ID && appToast.dismiss(FILE_ID);
            appToast.error(t('Something went wrong'));
          });
      }
    }
  };

  return (
    <section className="mt-[75px] px-6 flex flex-col gap-4 border-1 border-solid rounded-md bg-red w-full h-[calc(100vh-81px)] overflow-y-scroll">
      <SplittingShortcutsModal
        isOpen={isShortcutModal}
        onClose={handleShortcutModal}
        setNumberOfPagesPerReceipt={setNumberOfPagesPerReceipt}
        selectedNumber={numberOfPagesPerReceipt}
        setNumberOfInvoices={setNumberOfInvoices}
        numberOfPages={pages.length}
        validate={updateMetaDataFromPatternAndSubmit}
      />
      <div>
        <div className="flex justify-between items-center h-[60px] mb-2">
          <div className="flex gap-2 items-center">
            <Button
              icon={
                <KeyboardBackspaceRounded
                  htmlColor="white"
                  fontSize="small"
                  className="!h-6 !w-6 ml-1 "
                />
              }
              label={t('BACK_TO_DASHBOARD')}
              onClick={() => navigate('/dashboard/receipts')}
            />
          </div>

          <div className="flex gap-4">
            <Button
              type="submit"
              name="shortcut"
              form="bill-data"
              onClick={() => {
                handleShortcutModal();
              }}
              label={t('Shortcuts')}
              icon={<Shortcut fontSize="small" htmlColor="white" />}
              iconPosition="left"
              sensitivity="Secondary"
              disabled={uploadReceiptsState === Loading.IN_PROGRESS}
            />
            <Button
              icon={
                uploadReceiptsState === Loading.IN_PROGRESS ? (
                  <LoadingSpinner
                    width="20"
                    strokeWidth="3"
                    strokeColor="#ffffff"
                  />
                ) : null
              }
              type="submit"
              name="split_and_validate"
              label={
                t('Validate') +
                ' (' +
                numberOfInvoices +
                ' ' +
                t('receipts') +
                ')'
              }
              disabled={false}
              isLoading={false}
              onClick={updateMetaDataCustomAndSubmit}
            />
          </div>
        </div>

        <Swiper
          keyboard={{
            enabled: true,
          }}
          navigation={true}
          modules={[Keyboard, Navigation]}
          spaceBetween={45}
          slidesPerView={4}
          createElements
          style={{
            //@ts-ignore
            '--swiper-navigation-color': '#3B71FE',
            '--swiper-navigation-size': '30px',
          }}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            '480': {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            '920': {
              slidesPerView: 3,
              spaceBetween: 40,
            },
            '1280': {
              slidesPerView: 4,
              spaceBetween: 50,
            },
          }}
        >
          {pages.map((pageUrl, index) => (
            <SwiperSlide key={index}>
              <div className="flex items-center -25">
                <div className="flex flex-row gap-2">
                  <div className="hover:bg-[#bbccf8] p-2">
                    <Document file={pageUrl}>
                      <Page
                        pageNumber={1}
                        renderAnnotationLayer={false}
                        renderTextLayer={false}
                        width={pageSize}
                        loading={
                          <LoadingSpinner
                            width="20"
                            strokeWidth="3"
                            strokeColor="#bbccf8"
                          />
                        }
                      />
                    </Document>

                    <div className="text-center mt-2 text-xs text-[#8D98AF]">
                      {index + 1}/{pages.length}
                    </div>
                  </div>
                  {index !== pages.length - 1 && (
                    <div className="flex items-center flex-col place-content-center">
                      <div
                        className={`h-1/6 w-px border-l-2 ${
                          splitState[index] === true
                            ? 'border-solid border-[#3B71FE]'
                            : 'border-dashed border-[#bbccf8]'
                        }`}
                      ></div>
                      <button
                        className={`cursor-pointer hover:bg-[#bbccf8] rounded p-1 rounded-full flex items-center ${
                          splitState[index] === true ? 'bg-[#3B71FE]' : ''
                        }`}
                        onClick={() => updateSplitState(index)}
                      >
                        {splitState[index] === true ? (
                          <ContentCut htmlColor="#fff" fontSize="small" />
                        ) : (
                          <ContentCut htmlColor="#3B71FE" fontSize="small" />
                        )}
                      </button>
                      <div
                        className={`h-1/6 w-px border-l-2 ${
                          splitState[index] === true
                            ? 'border-solid border-[#3B71FE]'
                            : 'border-dashed border-[#bbccf8]'
                        }`}
                      ></div>
                    </div>
                  )}
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};
export default DocumentSplittingPage;
