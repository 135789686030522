import * as yup from 'yup';

export type ProfileType = {
  id: string;
  name: string;
  surname: string;
  email: string;
  password: string;
  phoneNumber: string;
  avatar: string | undefined;
};

export type ProfileFormType = {
  id?: string | null;
  name?: string | null;
  surname?: string | null;
  email?: string | null;
  password?: string | null;
  phoneNumber?: string | null;
  avatar?: File;
};

export const validateUpdateFormPassword = (val: string | undefined) =>
  !val ||
  val.trim().length === 0 ||
  (val.trim().length >= 8 && val.trim().length <= 32);

export const profileFormSchema = yup.object().shape({
  name: yup.string().required(),
  surname: yup.string().required(),
});

export type PasswordFormType = {
  password?: string | null;
  oldPassword?: string | null;
  confirmPassword?: string | null;
};

export const passwordFormSchema = yup.object().shape({
  oldPassword: yup
    .string()
    .test(
      'len',
      'Must be empty or 8-32 characters length',
      validateUpdateFormPassword
    ),
  password: yup
    .string()
    .test(
      'len',
      'Must be empty or 8-32 characters length',
      validateUpdateFormPassword
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Password must match'),
});
