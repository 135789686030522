import { type FC, useEffect, useState } from 'react';

import { HighlightOff } from '@mui/icons-material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { Tooltip } from '@mui/material';
import { useForm } from 'react-hook-form';

import { Button, Input } from '@/components/global';
import { useAppTranslation } from '@/hooks';
import { appToast } from '@/lib/ToastContainers';
import { operationsErrors } from '@/lib/errors';
import { SaasService } from '@/services';
import DeclarationService from '@/services/DeclarationService';

import type { DeclarationSubmit } from '../Declaration';

interface ConfirmationModalProps {
  declarationSubmit: DeclarationSubmit | null;
  setIsDeclarationSubmitUpdated: Function;
  isDeclarationSubmitUpdated: boolean;
  setDeclarationSubmit: Function;
  setSelectedAttachment: Function;
  setAttachments: Function;
  attachments: { id: string; filename: string }[];
}

/**
 *
 * @param {boolean} isOpen display and hide the modal
 * @param {Function} onClose responsible function to close the modal
 * @param {Function} action primary action
 * @param {string} title Title of the modal
 * @param {string} message message of the modal
 * @param {string} sensitivity modal sensitivity
 * @param {boolean} isLoading if the action require time to complete
 * @param {boolean} disabled disable the action
 * @returns
 */
const UpdateDeclarationDetails: FC<ConfirmationModalProps> = ({
  declarationSubmit,
  setIsDeclarationSubmitUpdated,
  isDeclarationSubmitUpdated,
  setDeclarationSubmit,
  setSelectedAttachment,
  setAttachments,
  attachments,
}) => {
  const { t } = useAppTranslation();

  const [declaredWhen, setDeclaredWhen] = useState<string | undefined>();
  const [payedWhen, setPayedWhen] = useState<string | undefined>();
  const [amount, setAmount] = useState<number>(0);
  const [note, setNote] = useState<string>('');
  const [declaredByFullName, setDeclaredByFullName] = useState('');
  const [payedByFullName, setPayedByFullName] = useState('');

  const [isAmountUpdated, setIsAmountUpdated] = useState<boolean>(false);
  const [isDeclaredWhenUpdated, setIsDeclaredWhenUpdated] =
    useState<boolean>(false);
  const [isPayedWhenUpdated, setIsPayedWhenUpdated] = useState<boolean>(false);
  const [isNoteUpdated, setIsNoteUpdated] = useState<boolean>(false);
  const [paimentError, setPaimentError] = useState<boolean>(false);
  const { register, handleSubmit } = useForm({
    defaultValues: {
      amount: declarationSubmit?.amount || '',
      note: declarationSubmit?.note || '',
    },
    mode: 'all',
  });
  const onSubmitAmount = (data: { amount: string | number; note: string }) => {
    setIsAmountUpdated(true);
    DeclarationService.updateDeclaration(declarationSubmit?.id || '', {
      amount: +data.amount,
      note: note,
    })
      .then((res) => {
        setAmount(res.data.amount);
        setNote(res.data.note);
        setIsAmountUpdated(false);
        setIsDeclarationSubmitUpdated(!isDeclarationSubmitUpdated);
        setDeclarationSubmit(res?.data);
      })
      .catch((e) => {
        appToast.error(t(e.response.data.code));
      });
  };

  const onSubmitNote = (data: { amount: string | number; note: string }) => {
    setIsNoteUpdated(true);
    DeclarationService.updateDeclaration(declarationSubmit?.id || '', {
      amount: +amount,
      note: data.note,
    })
      .then((res) => {
        setAmount(res.data.amount);
        setDeclarationSubmit(res?.data);
        setIsNoteUpdated(false);
      })
      .catch((e) => {
        appToast.error(t(e.response.data.code));
      });
  };
  const declare = (data: string) => {
    DeclarationService.declare(declarationSubmit?.id || '', {
      date: new Date(data),
    })
      .then((res) => {
        setDeclaredWhen(res.data?.declaredWhen);
        setDeclarationSubmit(res?.data);
        setIsDeclarationSubmitUpdated(!isDeclarationSubmitUpdated);
      })
      .catch((e) => {
        appToast.error(t(e.response.data.code));
      });
  };

  const undeclare = () => {
    setIsDeclaredWhenUpdated(true);
    DeclarationService.undeclare(declarationSubmit?.id || '')
      .then((res) => {
        setDeclaredWhen('');
        setPayedWhen('');
        setDeclarationSubmit(res?.data);
        setIsDeclaredWhenUpdated(false);
        setIsDeclarationSubmitUpdated(!isDeclarationSubmitUpdated);
      })
      .catch((e) => {
        appToast.error(t(e.response.data.code));
      });
  };

  const pay = (data: string) => {
    setPaimentError(false);
    DeclarationService.pay(declarationSubmit?.id || '', {
      date: new Date(data),
    })
      .then((res) => {
        setPayedWhen(res.data?.payedWhen);
        setDeclarationSubmit(res?.data);
        setIsDeclarationSubmitUpdated(!isDeclarationSubmitUpdated);
      })
      .catch((error) => {
        const errorResponse = error?.response?.data;
        if (
          errorResponse?.code === 'DECLARATION_DATE_MUST_BE_BEFORE_PAY_DATE'
        ) {
          setPaimentError(true);
        } else if (
          errorResponse?.code === operationsErrors.FiscalYear.YEAR_IS_LOCKED
        ) {
          appToast.error(t(operationsErrors.FiscalYear.YEAR_IS_LOCKED));
        }
      });
  };

  const unpay = () => {
    setPaimentError(false);
    setIsPayedWhenUpdated(true);
    DeclarationService.unpay(declarationSubmit?.id || '')
      .then((res) => {
        {
          setPayedWhen('');
          setDeclarationSubmit(res?.data);
          setIsPayedWhenUpdated(false);
          setIsDeclarationSubmitUpdated(!isDeclarationSubmitUpdated);
        }
      })
      .catch((e) => {
        appToast.error(t(e.response.data.code));
      });
  };

  const uploadAttachment = (file: any) => {
    DeclarationService.uploadAttachment(
      declarationSubmit?.id || '',
      file.target.files?.[0]
    )
      .then((res) => {
        setDeclarationSubmit(res?.data);
        setAttachments(res.data.attachments);
      })
      .catch((error) => {
        if (error?.response?.data?.code === 'UNSUPPORTED_FILE_TYPE')
          appToast.error(t('UNSUPPORTED_FILE_TYPE'));
        file.current.value = null;
        if (
          error?.response?.data?.code ===
          operationsErrors.FiscalYear.YEAR_IS_LOCKED
        )
          appToast.error(t(operationsErrors.FiscalYear.YEAR_IS_LOCKED));
      });
  };

  const deleteAttachment = (attachmentId: string) => {
    DeclarationService.deleteAttachment(
      declarationSubmit?.id || '',
      attachmentId
    )
      .then(() => {
        setAttachments(attachments.filter((att) => att.id !== attachmentId));
        setSelectedAttachment(null);
      })
      .catch((e) => {
        appToast.error(t(e.response.data.code));
      });
  };

  useEffect(() => {
    setDeclaredWhen(declarationSubmit?.declaredWhen);
    setPayedWhen(declarationSubmit?.payedWhen);
  }, [amount]);

  useEffect(() => {
    if (declarationSubmit?.amount) {
      setAmount(declarationSubmit.amount);
    }

    if (declarationSubmit?.declaredBy) {
      SaasService.getUserById(declarationSubmit?.declaredBy).then((res) => {
        const userInfo = res?.data?.users?.[0];
        setDeclaredByFullName(userInfo.name + ' ' + userInfo.surname);
      });
    }

    if (declarationSubmit?.payedBy) {
      SaasService.getUserById(declarationSubmit?.payedBy).then((res) => {
        const userInfo = res?.data?.users?.[0];
        setPayedByFullName(userInfo.name + ' ' + userInfo.surname);
      });
    }
  }, [declarationSubmit]);

  return (
    <>
      <div className="flex flex-col justify-between items-center">
        <form onSubmit={handleSubmit(onSubmitAmount)}>
          <div className="flex justify-between items-center">
            {declarationSubmit?.template?.isPayable && (
              <>
                <span className="text-sm w-14">{t('Amount')}</span>
                <div className="w-1/2">
                  <Input
                    type="number"
                    refs={register('amount')}
                    placeholder={t('Search')}
                    cls="bg-first color-black"
                    errorCls="text-red-500"
                    min={1}
                  />
                </div>
                <Button
                  cls="w-full max-w-[60px] hover:bg-blue-700 transition-colors duration-200 h-10 text-xs disabled:opacity-40"
                  label={t('Save')}
                  iconPosition="right"
                  labelCls="mr-1"
                  type="submit"
                  isLoading={isAmountUpdated}
                />
              </>
            )}
          </div>
        </form>

        <div className="pt-4 w-full flex justify-between items-center">
          <span className="text-sm w-14">{t('Declaration')}</span>
          <div className="w-1/2  mb-1">
            <Input
              type="date"
              value={declaredWhen}
              placeholder={'Search'}
              cls="bg-first color-black"
              errorCls="text-red-500"
              onChange={(d) => declare(d.target.value)}
              disabled={declarationSubmit?.template?.isPayable && !amount}
            />
          </div>
          <Button
            cls="w-full max-w-[60px] bg-red-600 hover:bg-red-700 transition-colors duration-200 h-10 text-xs disabled:opacity-40"
            label={t('Cancel')}
            iconPosition="right"
            labelCls="mr-1.5"
            disabled={!declaredWhen}
            onClick={undeclare}
            isLoading={isDeclaredWhenUpdated}
          />
        </div>

        {payedByFullName && (
          <div>
            <span className="text-xs underline italic">
              {`${t('Declared by')} ${declaredByFullName}`}
            </span>
          </div>
        )}
      </div>
      {declarationSubmit?.template?.isPayable && (
        <div className="mb-4">
          <div className="pt-4 w-full flex justify-between items-center">
            <span className="text-sm w-14">{t('Paiment')}</span>
            <div className="w-1/2 mb-1">
              <Input
                type="date"
                placeholder={'Search'}
                cls="bg-first color-black"
                errorCls="text-red-500"
                value={payedWhen}
                onChange={(d) => pay(d.target.value)}
                disabled={!declaredWhen}
              />
            </div>
            <Button
              cls="w-full max-w-[60px] bg-red-600 hover:bg-red-700 transition-colors duration-200 h-10 text-xs disabled:opacity-40"
              label="Cancel"
              iconPosition="right"
              labelCls="mr-1.5"
              disabled={!payedWhen}
              onClick={unpay}
              isLoading={isPayedWhenUpdated}
            />
          </div>
          {payedByFullName && (
            <div className="flex justify-center items-center">
              <span className="text-xs underline italic">
                {`${t('Payed by')} ${payedByFullName}`}
              </span>
            </div>
          )}

          {paimentError && (
            <p className="text-xs underline italic">
              {t('DECLARATION_DATE_MUST_BE_BEFORE_PAY_DATE')}
            </p>
          )}
        </div>
      )}
      <hr />
      <form onSubmit={handleSubmit(onSubmitNote)}>
        <div className="my-4">
          <span>{t('Notes')}</span>
          <div className="mt-2 flex flex-row justify-around items-center">
            <Input
              type="text"
              placeholder={t('Notes')}
              cls="bg-first color-black"
              errorCls="text-red-500"
              min={1}
              refs={register('note')}
            />
            <Button
              cls="w-full max-w-[60px] hover:bg-blue-700 transition-colors duration-200 h-10 text-xs ml-2"
              label={t('Save')}
              iconPosition="right"
              labelCls="mr-1"
              type="submit"
              isLoading={isNoteUpdated}
            />
          </div>
        </div>
      </form>
      <hr />

      <div className="max-h-[200px] overflow-x-hidden overflow-y-auto pt-4">
        <div className="w-full flex justify-between">
          <span className="text-sm">{t('Attachments')}</span>
          <span>
            <label htmlFor="file-input">
              <CloudUploadIcon className="cursor-pointer hover:scale-110" />
            </label>
            <input
              type="file"
              id="file-input"
              className="hidden"
              onChange={uploadAttachment}
            />
          </span>
        </div>
        {attachments?.length ? (
          attachments?.map((attachmentUrl: any, index: number) => {
            return (
              <div
                key={index}
                className="flex justify-between items-center h-10"
              >
                <div className="flex flex-row items-center w-64 cursor-pointer">
                  <RemoveRedEyeIcon></RemoveRedEyeIcon>

                  <Tooltip placement="top" title={attachmentUrl.fileName}>
                    <span
                      className="text-sm ml-2  truncate"
                      onClick={() => setSelectedAttachment(attachmentUrl)}
                    >
                      {attachmentUrl.filename}
                    </span>
                  </Tooltip>
                </div>
                <button onClick={() => deleteAttachment(attachmentUrl.id)}>
                  <HighlightOff className="text-[#d9534f] cursor-pointer hover:scale-110" />
                </button>
              </div>
            );
          })
        ) : (
          <div>
            <span className="text-xs">{t('No Uploaded Files')}</span>
          </div>
        )}
      </div>
    </>
  );
};

export default UpdateDeclarationDetails;
