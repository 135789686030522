import type { CategoryFormSubmit } from '@/pages/dashboard/Accounting/AccountingPlan/AddCategoryModal/AddCategoryModal';
import { accountingAPI } from '@/services/apis';

export default class AccountingService {
  static getCategories(filters: any, signal?: AbortSignal) {
    const queryParameters: string[] = ['order_by=code'];
    let url = '/v1/categories';

    if (filters.query) {
      queryParameters.push(`query=${filters.query}`);
    }

    if (filters.page) {
      queryParameters.push(`page=${filters.page}`);
    }

    if (filters.code) {
      queryParameters.push(`code=${filters.code}`);
    }

    if (filters.pageSize) {
      queryParameters.push(`page_size=${filters.pageSize}`);
    }

    if (queryParameters.length > 0) {
      url += '?' + queryParameters.join('&');
    }

    return accountingAPI.get(url, {
      signal,
    });
  }

  static createCategory(
    data: CategoryFormSubmit,
    fromBilling: boolean,
    signal?: AbortSignal
  ) {
    return accountingAPI.post(
      `/v1/categories?fromBilling=${fromBilling}`,
      data,
      {
        signal,
      }
    );
  }

  static getAllJournal(signal?: AbortSignal) {
    return accountingAPI.get('/v1/journals', {
      signal,
    });
  }

  static updateCategory(
    data: CategoryFormSubmit,
    labelId?: string,
    signal?: AbortSignal
  ) {
    return accountingAPI.patch(`/v1/categories/${labelId}`, data, {
      signal,
    });
  }

  static deleteCategory(labelId: string, signal?: AbortSignal) {
    return accountingAPI.delete(`/v1/categories/${labelId}`, {
      signal,
    });
  }

  static importAccountingPlan(
    file: File,
    erase: boolean,
    signal?: AbortSignal
  ) {
    const formData = new FormData();
    formData.append('file', file);

    return accountingAPI.post('/v1/categories/import', formData, {
      signal,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      params: {
        erase: erase ? '1' : '0',
      },
    });
  }
}
