enum ActionTypesEnum {
  SET_IS_PRELOAD_STATE = 'SET_IS_PRELOAD_STATE',
  SET_CONNECTED_USER = 'SET_CONNECTED_USER',

  SET_COMPANY_INFO = 'SET_COMPANY_INFO',
  SET_IS_LOADING_COMPANY_INFO = 'SET_IS_LOADING_COMPANY_INFO',
  SET_COMPANY_SUBSCRIPTION = 'SET_COMPANY_SUBSCRIPTION',

  SET_NOTIFICATIONS = 'SET_NOTIFICATIONS',

  SET_KPI_VALIDATION_RECEIPT = 'SET_KPI_VALIDATION_RECEIPT',
  SET_KPI_VALIDATION_TRANSACTION = 'SET_KPI_VALIDATION_TRANSACTION',

  SET_PLAN_INFO = 'SET_PLAN_INFO',

  SET_CREDIT_COUNTER = 'SET_CREDIT_COUNTER',
  SET_UNREAD_CHATS_COUNTER = 'SET_UNREAD_CHATS_COUNTER',

  SET_FISCAl_YEAR = 'SET_FISCAl_YEAR',
}

export default ActionTypesEnum;
