import { type FC, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Close } from '@mui/icons-material';
import { Dialog } from '@mui/material';
import { useForm } from 'react-hook-form';
import type { SubmitHandler } from 'react-hook-form';

import { Button, Input } from '@/components/global';
import { useAppTranslation } from '@/hooks';
import { appToast } from '@/lib/ToastContainers';
import { addCategoryForm, updateCategoryForm } from '@/lib/forms';
import { AccountingService } from '@/services';

import type { Category } from '../AccountingPlan';

export interface CategoryFormSubmit {
  label: string;
  code: string;
  parentId?: string;
  friendlyLabel?: string;
}

interface AddCategoryModalProps {
  isOpen: boolean;
  onClose: () => void;
  action?: (data?: any) => void;
  selectedCategory?: Category;
  isUpdate?: boolean;
  suggestedLabel?: string;
  suggestedCode?: string;
  title?: string;
}

const AddCategoryModal: FC<AddCategoryModalProps> = ({
  isOpen,
  onClose,
  action,
  selectedCategory,
  isUpdate,
  suggestedLabel = '',
  suggestedCode = '',
  title = '',
}) => {
  const { t } = useAppTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<CategoryFormSubmit>({
    defaultValues: {
      code: suggestedCode ?? selectedCategory?.code,
      ...selectedCategory,
    },
    mode: 'all',
    resolver: yupResolver(isUpdate ? updateCategoryForm : addCategoryForm),
  });

  const resetValues = () => {
    reset({
      code: '',
      label: '',
    });
  };

  useEffect(() => {
    !isUpdate &&
      reset({
        label: suggestedLabel,
        code: suggestedCode ?? selectedCategory?.code,
      });
  }, [isUpdate]);

  const onSubmit: SubmitHandler<CategoryFormSubmit> = (data) => {
    setIsLoading(true);
    data.parentId = selectedCategory?.id;
    const requestData = {
      label: data.label,
      code: data.code,
      friendlyLabel: isUpdate ? data.friendlyLabel : data.label,
      parentId: data.parentId,
    };

    isUpdate
      ? AccountingService.updateCategory(requestData, selectedCategory?.id)
          .then(({ data }) => {
            onClose();
            resetValues();
            action && action(data);
            appToast.success(t('Category created successfully'));
            setIsLoading(false);
          })
          .catch((error) => {
            const errorCode = error?.response?.data?.code;
            appToast.error(t(errorCode));
            setIsLoading(false);
          })
      : AccountingService.createCategory(
          requestData,
          requestData.code.substr(0, 3) == '411' ? false : true
        )
          .then(({ data }) => {
            onClose();
            resetValues();
            action && action(data);
            appToast.success(t('Category created successfully'));
            setIsLoading(false);
          })
          .catch((error) => {
            const errorCode = error?.response?.data?.code;
            appToast.error(t(errorCode));
            setIsLoading(false);
          });
  };

  const getTitles = () => {
    if (title) return title;
    else {
      if (isUpdate) return t('UPDATE_CATEGORY');
      else return t('ADD_A_NEW_CATEGORY');
    }
  };

  return (
    <Dialog
      onClose={() => {
        onClose();
        resetValues();
      }}
      open={isOpen}
    >
      <div className="px-9 py-5 flex flex-col gap-4 max-w-[500px] rounded-xl border-2 border-[#DEE2E6]">
        <button
          onClick={() => {
            onClose();
            resetValues();
          }}
          className="flex justify-end cursor-pointer"
        >
          <Close />
        </button>

        <h3 className="text-xl font-semibold text-center">{getTitles()}</h3>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-2">
            <Input
              id="label"
              refs={register('label')}
              placeholder="Label"
              errorMsg={errors.label && 'Please enter label'}
              disabled={isUpdate}
              cls="disabled:opacity-50"
            />

            <Input
              refs={register('code')}
              type="number"
              placeholder="code"
              errorMsg={errors.code && 'Please enter a valid code'}
              defaultValue={selectedCategory?.code}
              disabled={isUpdate}
              cls="disabled:opacity-50"
            />
            {isUpdate && (
              <Input
                refs={register('friendlyLabel')}
                placeholder="friendlyLabel"
                errorMsg={errors.friendlyLabel && 'Please enter friendlyLabel'}
              />
            )}

            <Button
              isLoading={isLoading}
              cls="mt-4 disabled:opacity-50"
              disabled={!isValid}
              label={t(isUpdate ? t('Update') : t('CONFIRM'))}
            />
          </div>
        </form>
      </div>
    </Dialog>
  );
};

export default AddCategoryModal;
