import React, { type FC, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Close } from '@mui/icons-material';
import { Dialog } from '@mui/material';
import { useForm } from 'react-hook-form';
import type { SubmitHandler } from 'react-hook-form';

import { Button, Input } from '@/components/global';
import { useAppTranslation } from '@/hooks';
import { appToast } from '@/lib/ToastContainers';
import { buyCreditFormSchema } from '@/lib/forms';
import { SaasService } from '@/services';
import { formatBalance } from '@/utils/funcs';

interface AddCreditModalTypes {
  isOpen: boolean;
  onClose: () => void;
  setCounter: (credit: number) => void;
}

interface DataToSubmit {
  credit: number;
}

const AddCreditModal: FC<AddCreditModalTypes> = ({
  isOpen,
  onClose,
  setCounter,
}) => {
  const [credit, setCredit] = useState(10);
  const { t } = useAppTranslation();

  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      credit: 10,
    },
    resolver: yupResolver(buyCreditFormSchema),
    mode: 'all',
  });

  const onSubmit: SubmitHandler<DataToSubmit> = (data) => {
    SaasService.addCredits(data.credit)
      .then(({ data }) => {
        setCounter(data);
        appToast.success(
          'Thank you for your purchase, your payment has been successfully processed.'
        );
        onClose();
      })
      .catch(() => {
        appToast.error('Something went wrong');
      });
  };

  return (
    <Dialog fullWidth maxWidth="xs" onClose={onClose} open={isOpen}>
      <div className="px-9 py-5 flex flex-col gap-4 max-w-[500px] rounded-xl border-2 border-[#DEE2E6]">
        <div
          onClick={() => {
            onClose();
          }}
          className="flex justify-end cursor-pointer"
        >
          <Close />
        </div>

        <div>
          <h2 className="font-bold mb-4 text-center">{t('Add credit')}</h2>
        </div>

        <div>
          <table className="w-full ">
            <tr>
              <th className="border border-solid border-slate-300 bg-slate-100 text-left p-2 font-medium w-1/2	">
                {t('Credit price')}
              </th>
              <th className="border border-solid border-slate-300 text-left p-2 w-1/2	bg-slate-100 font-medium">
                3 TND
              </th>
            </tr>
            <tr>
              <td className="border border-solid border-slate-300 bg-slate-100 p-2 w-1/2	">
                {t('Amount')}
              </td>
              <td className="border border-solid border-slate-300 p-2 font-bold w-1/2	">
                {formatBalance(credit < 0 ? 0 : credit * 3)}
              </td>
            </tr>
            <tr>
              <td className="border border-solid border-slate-300 bg-slate-100 p-2 w-1/2	">
                {t('TVA')} (19%)
              </td>
              <td className="border border-solid border-slate-300 p-2 font-bold w-1/2	">
                {formatBalance(credit < 0 ? 0 : (credit * 3 * 19) / 100)}
              </td>
            </tr>
            <tr>
              <td className="border border-solid border-slate-300 bg-slate-100 p-2 w-1/2	">
                {t('Total')}
              </td>
              <td className="border border-solid border-slate-300 p-2 font-bold w-1/2	">
                {formatBalance(
                  credit < 0 ? 0 : credit * 3 + (credit * 3 * 19) / 100
                )}
              </td>
            </tr>
          </table>
        </div>

        <div>
          <form
            className="flex flex-col gap-4 mt-1"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Input
              refs={register('credit')}
              type="number"
              placeholder="Add credit ex. 10"
              errorMsg={errors.credit && 'Please enter a valid credit number'}
              onChange={(e) => setCredit(parseInt(e.target.value))}
              min={1}
            />

            <div className="flex justify-end items-center w-full gap-4">
              <Button
                label={t('Close')}
                type="button"
                onClick={onClose}
                cls={`h-12 px-4 py-2 rounded-xl bg-[#b6b6b6] text-white flex items-center justify-center disabled:opacity-60 hover:opacity-80 duration-200`}
              />
              <Button
                label={t('Purchase')}
                type="submit"
                cls="hover:opacity-80 duration-200 disabled:opacity-50"
                disabled={!isValid}
              />
            </div>
          </form>
        </div>
      </div>
    </Dialog>
  );
};

export default AddCreditModal;
