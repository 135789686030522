import type { FC, ReactElement } from 'react';
import React from 'react';

import { Tooltip } from '@mui/material';

interface TooltipProps {
  /**
   * @default ""
   */
  message: string;

  /**
   * @default top
   */
  position?: 'top' | 'bottom' | 'left' | 'right' | 'bottom-end';

  children: ReactElement<any, any>;
}

/**
 * @param {top | bottom | left | right} position The position of the tooltip
 * @param {string} message The message to display
 * @example <Tooltip position={"bottom"} message="This is a Tooltip" />
 */
const TooltipGlobal: FC<TooltipProps> = ({
  message,
  position = 'top',
  children,
  ...props
}) => {
  return (
    <Tooltip arrow title={message} placement={position} {...props}>
      {children}
    </Tooltip>
  );
};

export default TooltipGlobal;
