import { useEffect, useState } from 'react';

import { Add } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useAppSelector, useAppTranslation } from '@/hooks';
import { SaasService } from '@/services';
import { setCreditCounter } from '@/store/actions/action-creators';

import AddCreditModal from './AddCreditModal';

const PageCounter = () => {
  const dispatch = useDispatch();
  const credits = useAppSelector((store) => store.global.credits);
  const navigate = useNavigate();

  const connectedUser = useAppSelector((store) => store.global.connectedUser);
  const company = useAppSelector((store) => store.global.company);
  const planInfo = useAppSelector((store) => store.global.planInfo);

  const { t } = useAppTranslation();

  const [isAddCreditModal, setIsAddCreditModal] = useState(false);

  const handleCreditModal = () => setIsAddCreditModal(!isAddCreditModal);

  useEffect(() => {
    SaasService.getCredits().then(({ data }) => {
      dispatch(setCreditCounter(data));
    });
  }, []);

  const setCounter = (counter: number) => {
    dispatch(setCreditCounter(counter));
  };

  return (
    <>
      {!!planInfo?.features?.OCR && (
        <>
          <button
            disabled={
              !(
                company?.createdBy === connectedUser?.id &&
                !!planInfo?.features?.OCR !== false
              )
            }
            //onClick={handleCreditModal}
            onClick={() => navigate('/settings/add-pages')}
            className="flex items-center w-fit gap-3 py-1 px-2 justify-between bg-[#d6e1fd] rounded-full h-[37px]"
          >
            <span className="text-[14px] font-bold text-[#0075E7] ml-2 whitespace-nowrap">
              {`${credits} Pages`}
            </span>
            {company?.createdBy === connectedUser?.id &&
              !!planInfo?.features?.OCR !== false && (
                <Tooltip title={t('BUY_PAGES')}>
                  <div className="flex items-center justify-center bg-[#0075E7] rounded-full h-7 w-8 cursor-pointer hover:bg-[#618dfc] duration-100">
                    <Add htmlColor="white" fontSize="small" />
                  </div>
                </Tooltip>
              )}
          </button>

          <AddCreditModal
            isOpen={isAddCreditModal}
            onClose={handleCreditModal}
            setCounter={setCounter}
          />
        </>
      )}
    </>
  );
};

export default PageCounter;
