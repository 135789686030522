import * as yup from 'yup';

export type CompanyInfosFormType = {
  name: string;
  legalStatus?: string;
  address: string;
  taxId: string;
  logo: File | string;
  companyName: string;
  registerNum?: string;
  isMoralPerson?: boolean;
  companyType?: string;
};

export type CompanyInfosSubmittedType = CompanyInfosFormType & {
  nreData: any;
};

export type CompanyFormType = {
  name?: string | null;
  legalStatus?: string | null;
  taxId?: string | null;
  address?: string | null;
  isMoralPerson?: boolean | null;
  logo?: File;
};

export const CompanyInfosFormSchema = yup.object().shape({
  address: yup
    .string()
    .required()
    .matches(/^[^\u0600-\u06FF]+$/, 'Address cannot contain Arabic letters')
    .trim(),
  legalStatus: yup
    .string()
    .when('isMoralPerson', {
      is: true,
      then: yup.string().required(),
    })
    .when('isMoralPerson', {
      is: false,
      then: yup.string().notRequired(),
    }),
  logo: yup.mixed(),
  name: yup.string().required(),
  taxId: yup.string().required(),
  isMoralPerson: yup.boolean().default(true),
});

export const companyFormSchema = yup.object().shape({
  address: yup.string().required(),
  legalStatus: yup.string(),
  name: yup.string().required(),
  taxId: yup.string().required(),
});

export const personFormSchema = yup.object().shape({
  address: yup.string().required(),
  name: yup.string().required(),
  taxId: yup.string().required(),
});

export type InviteUserFormType = {
  email?: string | null;
  role?: string | null;
  companyId?: string | null;
};

export const InviteUserFormSchema = yup.object().shape({
  email: yup.string().required(),
  role: yup.string().required(),
});
