import { type FC, useState } from 'react';

import { Check, Close } from '@mui/icons-material';
import { Dialog } from '@mui/material';

import { Button } from '@/components/global';
import { useAppTranslation } from '@/hooks';

interface SplittingShortcutsModalProps {
  isOpen: boolean;
  onClose: () => void;
  setNumberOfPagesPerReceipt: (newNumber: number) => void;
  setNumberOfInvoices: (newNumber: number) => void;
  numberOfPages: number;
  selectedNumber?: number;
  validate: () => void;
}

const splittingOptions = [
  {
    title: 'ONE_PAGE_PER_RECEIPT',
    value: 1,
  },
  {
    title: 'TWO_PAGES_PER_RECEIPT',
    value: 2,
  },
  {
    title: 'THREE_PAGES_PER_RECEIPT',
    value: 3,
  },
];
const SplittingShortcutsModal: FC<SplittingShortcutsModalProps> = ({
  isOpen,
  onClose,
  setNumberOfPagesPerReceipt,
  setNumberOfInvoices,
  numberOfPages,
  validate,
}) => {
  const { t } = useAppTranslation();

  const [selectedNumber, setSelectedNumber] = useState<number>(0);
  const [isOptionSelected, setOptionSelected] = useState<boolean>(false);

  const handleClick = (value: number) => {
    if (!isOptionSelected) setOptionSelected(true);
    setSelectedNumber(value);
    setNumberOfPagesPerReceipt(value);
  };

  const handleValidate = () => {
    setNumberOfInvoices(Math.ceil(numberOfPages / selectedNumber));
    onClose();
    validate();
  };
  return (
    <Dialog
      onClose={() => {
        onClose();
      }}
      open={isOpen}
    >
      <div className="px-4 pb-6 pt-4 flex flex-col gap-4 max-w-[500px] rounded-xl border-2 border-[#DEE2E6]">
        <button
          onClick={() => {
            onClose();
          }}
          className="flex justify-end cursor-pointer"
        >
          <Close />
        </button>

        <h3 className="text-xl font-semibold text-center">
          {t('SPLITTING_SHORTCUTS')}
        </h3>
        <p className="text-center px-3 text-[#54595e99]">
          {t('SPLITTING_SHORTCUTS_INFO')}
        </p>
        <div className="flex flex-wrap gap-1 justify-center	">
          {splittingOptions.map((option, index) => (
            <div key={index}>
              <Button
                icon={
                  selectedNumber && selectedNumber === option.value ? (
                    <Check htmlColor="#6b7280" fontSize="small" />
                  ) : null
                }
                label={t(option.title)}
                sensitivity="Faided"
                onClick={() => handleClick(option.value)}
              />
            </div>
          ))}
        </div>
        <Button
          type="submit"
          name="split_and_validate"
          label={
            t('Validate') +
            `${
              isOptionSelected === true
                ? ' (' +
                  Math.ceil(numberOfPages / selectedNumber) +
                  ' ' +
                  t('receipts') +
                  ')'
                : ''
            }`
          }
          disabled={!isOptionSelected}
          isLoading={false}
          onClick={handleValidate}
        />
      </div>
    </Dialog>
  );
};
export default SplittingShortcutsModal;
