import React from 'react';
import type { FC } from 'react';

import { Navigate } from 'react-router-dom';

import { useAppSelector } from '@/hooks';

interface OcrManagementGuardProps {
  children: React.ReactNode;
}

const OcrManagementGuard: FC<OcrManagementGuardProps> = ({ children }) => {
  const connectedUser = useAppSelector((store) => store.global.connectedUser);
  const company = useAppSelector((store) => store.global.company);
  const planInfo = useAppSelector((store) => store.global.planInfo);

  const canAccessOcrManagement =
    company?.createdBy === connectedUser?.id &&
    !!planInfo?.features?.OCR !== false;

  return canAccessOcrManagement ? (
    <>{children}</>
  ) : (
    <Navigate to="/forbidden" replace />
  );
};

export default OcrManagementGuard;
