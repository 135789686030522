/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import type { FC } from 'react';
import { useEffect, useRef, useState } from 'react';

import { Icon } from '@fluentui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Add,
  ArrowBack,
  Delete,
  Edit,
  FileUpload,
  Help,
  KeyboardBackspaceRounded,
  NoEncryptionGmailerrorred,
  NorthRounded,
  SouthRounded,
} from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import {
  getFileTypeIconProps,
  initializeFileTypeIcons,
} from '@uifabric/file-type-icons';
import moment from 'moment';
import { useFieldArray, useForm } from 'react-hook-form';
import { Modal, ModalGateway } from 'react-images';
import InfiniteScroll from 'react-infinite-scroller';
import { RotatingLines } from 'react-loader-spinner';
import { Carousel } from 'react-responsive-carousel';
import { useNavigate, useParams } from 'react-router-dom';

import {
  Badge,
  Banner,
  Button,
  ConfirmationModal,
  DiscussionPopover,
  Input,
  LoadingSpinner,
  RSelect,
  Separator,
} from '@/components/global';
import { useAppDispatch, useAppSelector, useAppTranslation } from '@/hooks';
import { appToast } from '@/lib/ToastContainers';
import { operationsErrors } from '@/lib/errors';
import type { Bank, BankAccountType } from '@/lib/forms';
import {
  PaymentActionFormSchema,
  ReceiptFormUpdateSchema,
} from '@/lib/forms/receipts-forms';
import { ObjectPdf } from '@/pages/dashboard/common';
import type {
  Chat,
  Currency,
  FiscalYear,
  PaymentAction,
  Receipt,
} from '@/services';
import {
  AccountingService,
  BankingService,
  OperationsService,
  SettingsService,
} from '@/services';
import ChatService from '@/services/ChatService';
import DocService from '@/services/DocService';
import {
  setKpiValidationReceipt,
  setKpiValidationTransaction,
} from '@/store/actions/action-creators';
import { formatBalance, formatBytes, getCategoryName } from '@/utils/funcs';

import type { Category } from '../../Accounting/AccountingPlan/AccountingPlan';
import { AddCategoryModal } from '../../Accounting/AccountingPlan/AddCategoryModal/index';
import { CategoryModal } from '../../Transactions/CategoryModal';
import { InvoiceType, type PaymentMethod, StatusEnum } from '../enums';
import { ImageReceipt } from './index';

interface ReceiptProps {}

interface VentilationType {
  amount: number;
  originalAmount?: number;
  category?: string;
  currency?: string;
}

interface VentilationData {
  ventilationList: VentilationType[];
}

interface Attachment {
  created: string;
  folder: boolean;
  locked: boolean;
  name: string;
  size: 48657;
  type: string;
  userName: string;
  path: string;
}

const ReceiptPage: FC<ReceiptProps> = () => {
  const { id, index, page_number } = useParams();
  initializeFileTypeIcons();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const tableRef = useRef<HTMLTableElement>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [receipt, setReceipt] = useState<Receipt>(null as unknown as Receipt);
  const [receiptId, setReceiptId] = useState(id);
  const [paymentActions, setPaymentActions] = useState<PaymentAction[]>([]);
  const [currencies, setCurrencies] = useState<Currency[]>([]);
  const fiscalYearStored = useAppSelector((store) => store.global.fiscalYear);
  const [fiscalYearUsed, setFiscalYearUsed] = useState<FiscalYear>();

  const connectedUser = useAppSelector((store) => store.global.connectedUser);
  const connectedCompany = useAppSelector((store) => store.global.company);
  const [pageNumber, setPageNumber] = useState(1);
  const [categoriesList, setCategoriesList] = useState<Category[]>([]);
  const [categoriesPurchaseList, setCategoriesPurchaseList] =
    useState<Category[]>();
  const [categoriesSaleList, setCategoriesSaleList] = useState<Category[]>();
  const [receipts, setReceipts] = useState<Receipt[]>([]);
  const [selectedReceiptIndex, setSelectedReceiptIndex] = useState(
    parseInt(index ?? '0')
  );
  const [isRotatingLines, setIsRotatingLines] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const { t } = useAppTranslation();
  const columns = [t('Receipt'), t('Amount'), t('Status')];
  const [isCategoryModal, setIsCategoryModal] = useState(false);
  const [isAddCategoryModal, setIsAddCategoryModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [invoiceType, setInvoiceType] = useState<InvoiceType | null>(
    InvoiceType.PURCHASE
  );
  const fiscalYear = useAppSelector((store) => store.global.fiscalYear);
  const [chatData, setChatData] = useState<Chat>(null as unknown as Chat);
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] =
    useState<boolean>(false);

  const pageSize = 20;
  const toggleModal = () =>
    setIsModalOpen((isModalOpen: boolean) => !isModalOpen);
  const [banksAccounts, setBanksAccounts] = useState<BankAccountType[]>([]);
  const [banks, setBanks] = useState<Bank[]>([]);
  const [cashCategories, setCashCategories] = useState<Category[]>([]);
  const [ccaCategories, setCcaCategories] = useState<Category[]>([]);
  const [ppCategories, setPpCategories] = useState<Category[]>([]);
  const paymentChannel = ['CASH', 'CCA', 'BANK', 'PP'];
  const [paymentSets, setPaymentSets] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [gapAmount, setGapAmount] = useState(0);
  const [selectedReceipt, setSelectedReceipt] = useState(receipt);
  const [ventilationIndex, setVentilationIndex] = useState<number | undefined>(
    0
  );
  const [selectedVEntilation, setSelectedVEntilation] = useState(
    null as unknown as number
  );
  const [isAddVentilation, setIsAddVentilation] = useState(false);
  const [isValidation, setIsValidation] = useState(false);
  const [isDeleteReceiptModal, setIsDeleteReceiptModal] = useState(false);
  const [receiptAttachments, setReceiptAttachments] = useState<Attachment[]>(
    []
  );
  const [reloadPDF, setReloadPDF] = useState(false);
  const [selectedAttachment, setSelectedAttachment] = useState<Attachment>(
    {} as Attachment
  );
  const [selectedAttachmentURL, setSelectedAttachmentURL] =
    useState<string>('');
  const [isAttachmentModal, setIsAttachmentModal] = useState<boolean>(false);
  const [isGetAttachmentLoading, setIsGetAttachmentLoading] = useState(false);

  const [isDeleteAttachmentModal, setIsDeleteAttachmentModal] =
    useState<boolean>(false);
  const [attachmentToDelete, setAttachmentToDelete] = useState('');

  const {
    control,
    register,
    setValue,
    getValues,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      supplierName: receipt?.supplierName,
      totalAmount: receipt?.totalAmount,
      originalAmount: receipt?.originalAmount,
      originalTotalTaxAmount: receipt?.originalTotalTaxAmount,
      invoiceDate: receipt?.invoiceDate,
      invoiceType: receipt?.invoiceType ?? InvoiceType.PURCHASE,
      paymentActions: receipt?.paymentActions,
      categoryCode: receipt?.categoryCode,
      currency: receipt?.currency,
      currencyTnd:
        currencies.filter((res) => res?.code === 'TND')[0]?.code ?? null,
    },
    mode: 'all',
    resolver: yupResolver(ReceiptFormUpdateSchema),
  });

  const {
    register: registerVentilation,
    control: controlVentilation,
    setError: setErrorVentilation,
    setValue: setValueVentilation,
    getValues: getValuesVentilation,
    clearErrors: clearErrorsVentilation,
    watch: watchVentilation,
  } = useForm<VentilationData>({
    defaultValues: {
      ventilationList: [
        {
          category: receipt?.categoryCode,
          amount: receipt?.netAmount,
          currency: 'TND',
        },
      ],
    },
    mode: 'all',
  });

  const { fields, append, remove, update } = useFieldArray({
    name: 'ventilationList',
    control: controlVentilation,
  });

  const {
    control: controlPayment,
    register: registerPayment,
    setValue: setValuePayment,
    watch: watchPayment,
    handleSubmit: handleSubmitPayment,
    reset: resetPayment,
    formState: { errors: errorsPayment },
  } = useForm({
    defaultValues: {
      paymentChannel: '',
      source: '',
      amount: 0,
      date: '',
    },
    mode: 'all',
    resolver: yupResolver(PaymentActionFormSchema),
  });

  const transactionChannel = watchPayment('paymentChannel');

  const handleIsCategoryModal = (idx?: number) => {
    setIsCategoryModal(!isCategoryModal);
    setVentilationIndex(idx);
  };

  const handleSelectCategory = (category: Category) => {
    const ctg = getValuesVentilation(
      `ventilationList.${ventilationIndex ?? 0}`
    );

    update(ventilationIndex ?? 0, { ...ctg, category: category.code });

    setIsCategoryModal(false);
  };

  useEffect(() => {
    setValueVentilation(
      'ventilationList',
      receipt?.categoryVentilation
        ? receipt.categoryVentilation.sort((a, b) => {
            if (!a.category) return -1;
            if (!b.category) return 1;
            return 0;
          })
        : [
            {
              category: receipt?.categoryCode,
              amount: Number(((receipt?.netAmount ?? 0) * 1).toFixed(3)),
              currency: 'TND',
              originalAmount: Number(
                ((receipt?.originalAmount ?? 0) * 1).toFixed(3)
              ),
            },
          ]
    );

    setIsGetAttachmentLoading(true);
    OperationsService.getReceiptAttachments(
      receipt?.id ? receipt?.id : receipt?.id == null && id ? id : ''
    )
      .then(({ data }) => {
        setIsGetAttachmentLoading(false);
        setReceiptAttachments(data ?? []);
      })
      .catch(() => {
        setIsGetAttachmentLoading(false);
      });

    setIsUpdate(false);
  }, [receipt?.id]);

  const getGapAmount = () => {
    const totalAmount = fields
      .map((el, idx) => {
        if (
          (getValuesVentilation(`ventilationList.${idx}.amount`) ?? 0) * 1 <
          0
        ) {
          setErrorVentilation(`ventilationList.${idx}.amount`, {
            message: 'Number should be positive',
          });
        }
        return getValuesVentilation(`ventilationList.${idx}.amount`) * 1;
      })
      .reduce((a, b) => a + b, 0);

    const totalGapAmount = parseFloat(
      ((selectedReceipt?.totalAmount ?? 0) - totalAmount).toFixed(3)
    );

    setGapAmount(totalGapAmount);
  };

  useEffect(() => {
    getGapAmount();
  }, [
    JSON.stringify(watchVentilation('ventilationList')),
    receipt,
    selectedReceipt?.totalAmount,
  ]);

  useEffect(() => {
    const fiscalYears: FiscalYear[] = JSON.parse(
      localStorage.getItem('fiscalYears') ?? ''
    );
    if (fiscalYears && fiscalYearStored) {
      setFiscalYearUsed(
        fiscalYears.find((year) => year.fiscalYear === Number(fiscalYearStored))
      );
    }
    setIsRotatingLines(true);

    OperationsService.getReceiptsWithPagination(
      Number(fiscalYear),
      pageNumber,
      pageSize * Number(page_number),
      StatusEnum.ALL,
      null
    ).then((receipt) => {
      setReceipts(receipt.data?.receipts);
      setTotalPages(receipt.data.totalNumberOfPages);
      setIsRotatingLines(false);
    });
  }, [pageSize]);

  useEffect(() => {
    setIsRotatingLines(true);

    OperationsService.getReceiptsWithPagination(
      Number(fiscalYear),
      pageNumber,
      pageSize * Number(page_number),
      StatusEnum.ALL,
      null
    ).then((response) => {
      setReceipts(response.data?.receipts);
      setTotalPages(response.data.totalNumberOfPages);
      setIsRotatingLines(false);
    });
  }, [fiscalYear]);

  useEffect(() => {
    fetchCategories(invoiceType);
  }, [invoiceType, receipt]);

  const handleScroll = (pageNumber: number) => {
    setPageNumber(pageNumber + 1);

    OperationsService.getReceiptsWithPagination(
      Number(fiscalYear),
      pageNumber,
      pageSize * Number(page_number),
      StatusEnum.ALL,
      null
    ).then((receipt) => {
      const newReceiptsList = receipts.concat(receipt.data?.receipts);

      setReceipts(newReceiptsList);
      setTotalPages(receipt.data.totalNumberOfPages);
    });
  };

  const fetchReceipt = (signal?: AbortSignal) => {
    OperationsService.getReceipt(receiptId ?? '', signal).then((res) => {
      setReceipt(res.data);
      setReloadPDF((prev) => !prev);
      setSelectedReceipt(res.data);
      reset({ ...res.data });
      setPaymentActions(res.data.paymentActions);
      setValue(
        'currencyTnd',
        currencies.filter((res) => res?.code === 'TND')[0]?.code
      );
      setInvoiceType(res?.data?.invoiceType ?? InvoiceType.PURCHASE);
      const invoiceTypeDeclared = res.data.invoiceType ?? InvoiceType.PURCHASE;
      if (invoiceTypeDeclared) {
        fetchCategories(invoiceTypeDeclared as InvoiceType);
      }
    });
    deletePaymentSet();
    ChatService.getChat(receiptId, 'RECEIPT', signal).then((response) => {
      if (response.data[0]) {
        setChatData(response.data[0]);
      } else {
        setChatData(null as unknown as Chat);
      }
    });
  };

  const onSubmitPayment = async (data: any) => {
    setIsLoading(true);
    setPaymentSets(false);
    const paymentAction: PaymentAction = {
      paymentChannel: data.paymentChannel,
      paymentActionSource: data.source,
      paymentDate: moment(data.date).format('YYYY-MM-DD'),
      amount: data.amount,
      associatedTransactionId: null,
    };

    OperationsService.addPaymentActionToReceipt(paymentAction, receiptId).then(
      (response) => {
        setPaymentActions(response.data?.paymentActions);
        setIsLoading(false);
      }
    );
  };

  const getCashCategoriesFriendlyLabelById = (
    categoryId: string | undefined
  ): string | undefined => {
    const foundCategory = cashCategories.find(
      (category) => category.id === categoryId
    );
    return foundCategory ? foundCategory.friendlyLabel : undefined;
  };

  const getCcaCategoriesFriendlyLabelById = (
    categoryId: string | undefined
  ): string | undefined => {
    const foundCategory = ccaCategories.find(
      (category) => category.id === categoryId
    );
    return foundCategory ? foundCategory.friendlyLabel : undefined;
  };

  const getBankCategoriesFriendlyLabelById = (
    bankId: string | undefined
  ): string | undefined => {
    const foundCategory = banksAccounts.find(
      (bankAccount) => bankAccount.id === bankId
    );
    const foundBankName = banks.find(
      (bank) => bank.id === foundCategory?.bankId
    );
    return foundCategory
      ? `${foundCategory.label} ${foundBankName?.name}`
      : undefined;
  };

  const getppCategoriesFriendlyLabelById = (
    categoryId: string | undefined
  ): string | undefined => {
    const foundCategory = ppCategories.find(
      (category) => category.id === categoryId
    );
    return foundCategory ? foundCategory.friendlyLabel : undefined;
  };

  const getSourceName = (
    type: PaymentMethod | undefined,
    id: string | undefined
  ): string | undefined => {
    if (type === 'CCA') {
      return getCcaCategoriesFriendlyLabelById(id);
    } else if (type === 'CASH') {
      return getCashCategoriesFriendlyLabelById(id);
    } else if (type === 'BANK') {
      return getBankCategoriesFriendlyLabelById(id);
    } else if (type === 'PP') {
      return getppCategoriesFriendlyLabelById(id);
    }
  };

  const onSubmit = (data: any) => {
    setIsLoading(true);

    const updatedReceipt: Receipt = {
      ...receipt,
      supplierName: receipt?.supplierName,
      invoiceDate: data?.invoiceDate,
      invoiceType: data.invoiceType,
      totalAmount: data?.totalAmount,
      paymentActions: data.paymentActions,
      categoryCode: data.categoryCode,
      companyId: receipt?.companyId,
      currency: receipt.currency,
      id: receipt?.id,
    };

    OperationsService.updateReceipt(updatedReceipt, true, true, false)
      .then((res) => {
        const isAccountant = !!connectedUser.roles?.includes('ACCOUNTANT');
        const isValidated = receipt?.fileStatus === StatusEnum.VALIDATED;

        setIsLoading(false);
        appToast.success(
          !isAccountant && !isValidated
            ? t('Receipt updated successfully')
            : t('The receipt is validated')
        );
        setReceipt(res?.data);
        const updatedReceipts = [...receipts];
        const updatedReceiptIndex = updatedReceipts.findIndex(
          (r) => r.id === res?.data?.id
        );

        if (updatedReceiptIndex !== -1) {
          updatedReceipts[updatedReceiptIndex] = res?.data;
          setReceipts(updatedReceipts);
        }
        OperationsService.getKpiValidation().then((res) => {
          dispatch(setKpiValidationReceipt(res.data?.validatedReceiptNumber));
          dispatch(
            setKpiValidationTransaction(res.data?.validatedTransactionNumber)
          );
        });
        setSelectedReceipt(res.data);
        getGapAmount();
        setIsUpdate(false);
      })
      .catch((error) => {
        setIsLoading(false);
        appToast.error(t(error.response.data.code));
      });
  };

  const validateReceipt = (data: any) => {
    setIsLoading(true);

    const formattedVentilationList = getValuesVentilation(`ventilationList`);

    const totalAmount = formattedVentilationList
      .map((el) => el.amount * 1)
      .reduce((a, b) => a + b);

    const totalGlobalAmount = parseFloat(totalAmount.toFixed(3));

    const isCategoryValid = formattedVentilationList
      .map((el) => el.category)
      .includes((null as unknown as string) || null || '');

    const isNegativeNumber = formattedVentilationList.map((el) => {
      return Number(el.amount) <= 0;
    });

    if (
      parseFloat(selectedReceipt?.totalAmount?.toFixed(3) || '0') !==
      totalGlobalAmount
    ) {
      appToast.error(
        'The sum of the amount should be the same as the transaction amount'
      );
      setIsLoading(false);
    } else if (isCategoryValid) {
      appToast.error(t('Category required'));
      setIsLoading(false);
    } else if (isNegativeNumber.includes(true)) {
      appToast.error(t('NEGATIVE_NUMBER'));
      setIsLoading(false);
    } else {
      const updatedReceipt: Receipt = {
        ...receipt,
        supplierName: receipt?.supplierName,
        invoiceDate: data?.invoiceDate,
        invoiceType: data.invoiceType,
        totalAmount: data?.totalAmount,
        paymentActions: data.paymentActions,
        categoryCode: data.categoryCode,
        companyId: receipt?.companyId,
        currency: receipt.currency,
        id: receipt?.id,
        categoryVentilation: formattedVentilationList,
        ventilationEditedByUser: true,
      };

      OperationsService.updateReceipt(updatedReceipt, false, true, true)
        .then((res) => {
          const isAccountant = !!connectedUser.roles?.includes('ACCOUNTANT');
          const isValidated = receipt?.fileStatus === StatusEnum.VALIDATED;

          setIsLoading(false);
          appToast.success(
            !isAccountant && !isValidated
              ? t('Receipt updated successfully')
              : t('The receipt is validated')
          );
          setReceipt(res.data);
          setReloadPDF((prev) => !prev);

          const updatedReceipts = [...receipts];
          const updatedReceiptIndex = updatedReceipts.findIndex(
            (r) => r.id === res?.data?.id
          );

          if (updatedReceiptIndex !== -1) {
            updatedReceipts[updatedReceiptIndex] = res?.data;
            setReceipts(updatedReceipts);
          }
          OperationsService.getKpiValidation().then((res) => {
            dispatch(setKpiValidationReceipt(res.data?.validatedReceiptNumber));
            dispatch(
              setKpiValidationTransaction(res.data?.validatedTransactionNumber)
            );
          });
          setSelectedReceipt(res.data);
          getGapAmount();
          setIsUpdate(false);
        })
        .catch((error) => {
          setIsLoading(false);
          appToast.error(t(error.response.data.code));
        });
    }
  };

  const uploadAttachment = (file: File) => {
    setIsGetAttachmentLoading(true);
    const formData = new FormData();

    formData.append('file', file);
    formData.append('fileName', file.name);

    OperationsService.uploadReceiptAttachments(id ?? '', formData)
      .then(({ data }) => {
        setIsGetAttachmentLoading(false);
        setReceiptAttachments([...receiptAttachments, ...data]);
      })
      .catch(() => {
        setIsGetAttachmentLoading(false);
      });
  };

  const getAttachmentPreview = (selectedFile: Attachment) => {
    let formattedPath = selectedFile.path.split('/').slice(0, -1).join('/');

    DocService.downloadFile(formattedPath, selectedFile.name)
      .then(({ data }) => {
        const url = URL.createObjectURL(data);

        setSelectedAttachmentURL(url);
      })
      .catch(() => {});
  };

  const handleAttachmentModal = () => {
    setIsAttachmentModal(!isAttachmentModal);
  };

  const deleteAttachment = () => {
    setIsGetAttachmentLoading(true);
    DocService.deleteFile(attachmentToDelete)
      .then(() => {
        setReceiptAttachments(
          receiptAttachments.filter((el) => el.path !== attachmentToDelete)
        );
        setIsGetAttachmentLoading(false);
        appToast.success(t('ATTACHMENT_SUCCESSFULLY_DELETED'));
      })
      .catch(() => {
        setIsGetAttachmentLoading(false);
        appToast.success(t('something went wrong while deleting file'));
      });
  };

  const updateReceiptData = () => {
    setIsLoading(true);

    const formattedVentilationList = getValuesVentilation(`ventilationList`);

    const receiptData: Receipt = {
      ...receipt,
      categoryVentilation: formattedVentilationList,
    };

    OperationsService.updateReceipt(receiptData, true, true, false)
      .then(({ data }) => {
        appToast.success(t('Receipt updated successfully'));
        setReceipt(data);

        setSelectedReceipt(data);

        const updatedReceipts = [...receipts];
        const updatedReceiptIndex = updatedReceipts.findIndex(
          (r) => r.id === data?.id
        );

        if (updatedReceiptIndex !== -1) {
          updatedReceipts[updatedReceiptIndex] = data;
          setReceipts(updatedReceipts);
        }

        getGapAmount();
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        appToast.error(t(error.response.data.code));
      });
  };

  const deleteSelectedReceipt = () => {
    setIsLoading(true);

    OperationsService.deleteReceipt(id ?? '')
      .then(() => {
        setReceipts((prevReceipts) =>
          prevReceipts.filter((res) => {
            return res.id !== id;
          })
        );

        const newReceiptId = receipts[(Number(index) ?? 0) + 1].id;

        setReceiptId(newReceiptId);

        navigate(
          `/dashboard/receipts/${fiscalYear}/${newReceiptId}/idx/${index}/page/${pageNumber}`
        );

        appToast.success(t('RECEIPT_DELETED_SUCCESSFULLY'));
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const handleClickInvalidate = () => {
    OperationsService.invalidateReceipt(receipt?.id as string).then(() => {
      setConfirmationModalIsOpen(false);
      appToast.success(t('The validation is canceled'));
      setReceipt((prevReceipt) => {
        if (prevReceipt) {
          return {
            ...prevReceipt,
            fileStatus: prevReceipt.originalFileStatus,
          };
        }
        return prevReceipt;
      });
      setReloadPDF((prev) => !prev);

      fetchReceipt();
      // Update the receipts array based on the receipt id
      setReceipts((prevReceipts) => {
        if (prevReceipts) {
          return prevReceipts.map((prevReceipt) =>
            prevReceipt.id === receipt?.id
              ? {
                  ...prevReceipt,
                  fileStatus: prevReceipt.originalFileStatus,
                }
              : prevReceipt
          );
        }
        return prevReceipts;
      });
      fetchReceipt();
    });
  };

  useEffect(() => {
    fetchCategories(invoiceType);
  }, [invoiceType, isCategoryModal]);

  useEffect(() => {
    const controller = new AbortController();

    fetchReceipt(controller.signal);

    return () => controller.abort();
  }, [receiptId, currencies]);

  const fetchCategories = (type: InvoiceType | null) => {
    let addedCategory;

    if (type === InvoiceType.PURCHASE) {
      if (
        receipt?.categoryCode &&
        !categoriesPurchaseList?.find((el) => el.code === receipt?.categoryCode)
      ) {
        addedCategory = categoriesSaleList?.find(
          (el) => el.code === receipt?.categoryCode
        );
      }
      if (categoriesPurchaseList)
        setCategoriesList([
          {
            code: '',
            label: 'Select...',
            parentId: '',
            displayable: false,
            id: '',
            friendlyLabel: 'Select...',
            standard: false,
          },
          ...(addedCategory ? [addedCategory] : []),
          ...categoriesPurchaseList,
        ]);
    } else if (type === InvoiceType.SALE) {
      if (
        receipt?.categoryCode &&
        !categoriesSaleList?.find((el) => el.code === receipt?.categoryCode)
      ) {
        addedCategory = categoriesPurchaseList?.find(
          (el) => el.code === receipt?.categoryCode
        );
      }
      if (categoriesSaleList)
        setCategoriesList([
          {
            code: '',
            label: 'Select...',
            parentId: '',
            displayable: false,
            id: '',
            friendlyLabel: 'Select...',
            standard: false,
          },
          ...(addedCategory ? [addedCategory] : []),
          ...categoriesSaleList,
        ]);
    }
  };

  const getFileExtension = (filename: string | undefined): string => {
    const extension = filename?.split('.').pop()?.toLowerCase();
    return extension ?? '';
  };

  // Add an event listener for keydown on the window
  const handleKeys = (e: KeyboardEvent) => {
    if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
      e.preventDefault();
      tableRef?.current?.focus();
    }
  };

  useEffect(() => {
    const controller = new AbortController();

    Promise.all([
      SettingsService.getCurrencies(controller.signal),
      AccountingService.getCategories(
        {
          code: 401,
          pageSize: 99999,
        },
        controller.signal
      ),
      AccountingService.getCategories(
        {
          code: 411,
          pageSize: 99999,
        },
        controller.signal
      ),
      BankingService.getBankAccounts(controller.signal),
      SettingsService.getBanks(controller.signal),
      AccountingService.getCategories(
        {
          code: 54,
        },
        controller.signal
      ),
      AccountingService.getCategories(
        {
          code: 442,
        },
        controller.signal
      ),
      AccountingService.getCategories(
        {
          code: 108,
        },
        controller.signal
      ),
    ]).then(
      ([
        currenciesResponse,
        purchaseResponse,
        saleResponse,
        bankAccountsResponse,
        banksResponse,
        cashCategoryResponse,
        ccaCategoryResponse,
        ppCategoryResponse,
      ]) => {
        setCurrencies(currenciesResponse.data);
        setCategoriesPurchaseList(purchaseResponse.data.categories);
        setCategoriesSaleList(saleResponse.data.categories);
        setBanksAccounts(bankAccountsResponse.data);
        setBanks(banksResponse.data);
        setCashCategories(cashCategoryResponse.data.categories);
        setCcaCategories(ccaCategoryResponse.data.categories);
        setPpCategories(ppCategoryResponse.data.categories);
      }
    );

    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    const controller = new AbortController();

    Promise.all([
      AccountingService.getCategories(
        {
          code: 401,
          pageSize: 99999,
        },
        controller.signal
      ),
      AccountingService.getCategories(
        {
          code: 411,
          pageSize: 99999,
        },
        controller.signal
      ),
      AccountingService.getCategories(
        {
          code: 54,
        },
        controller.signal
      ),
      AccountingService.getCategories(
        {
          code: 442,
        },
        controller.signal
      ),
      AccountingService.getCategories(
        {
          code: 108,
        },
        controller.signal
      ),
    ]).then(
      ([
        purchaseResponse,
        saleResponse,
        cashCategoryResponse,
        ccaCategoryResponse,
        ppCategoryResponse,
      ]) => {
        setCategoriesPurchaseList(purchaseResponse.data.categories);
        setCategoriesSaleList(saleResponse.data.categories);
        setCashCategories(cashCategoryResponse.data.categories);
        setCcaCategories(ccaCategoryResponse.data.categories);
        setPpCategories(ppCategoryResponse.data.categories);
      }
    );

    return () => {
      controller.abort();
    };
  }, [isAddCategoryModal]);

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.focus();
    }

    setSelectedReceiptIndex(parseInt(index || '0'));

    window.addEventListener('keydown', handleKeys);

    return () => window.removeEventListener('keydown', handleKeys);
  }, []);

  const [options, setOptions] = useState([{ label: '', value: '' }]);
  useEffect(() => {
    let updatedOptions = [{ value: '', label: '' }];

    if (
      transactionChannel === 'CCA' &&
      ccaCategories &&
      ccaCategories.length > 0
    ) {
      updatedOptions = [
        { value: '', label: '' },
        ...ccaCategories.map((category) => ({
          value: category.id,
          label: `${category.friendlyLabel}`,
        })),
      ];
    } else if (
      transactionChannel === 'CASH' &&
      cashCategories &&
      cashCategories.length > 0
    ) {
      updatedOptions = [
        { value: '', label: '' },
        ...cashCategories.map((category) => ({
          value: category.id,
          label: `${category.friendlyLabel}`,
        })),
      ];
    } else if (
      transactionChannel === 'BANK' &&
      banksAccounts &&
      banksAccounts.length > 0 &&
      banks &&
      banks.length > 0
    ) {
      const bankList = banksAccounts.map((account: BankAccountType) => ({
        ...account,
        bankId: banks.find((bank) => bank.id === account.bankId)?.id,
        bankName: banks.find((bank) => bank.id === account.bankId)?.name,
      }));

      updatedOptions = [
        { value: '', label: '' },
        ...bankList.map((bank) => ({
          value: String(bank?.id),
          label: `${bank?.label} ${bank?.bankName}`,
        })),
      ];
    } else if (
      transactionChannel === 'PP' &&
      ppCategories &&
      ppCategories.length > 0 &&
      !connectedCompany?.legalStatus
    ) {
      updatedOptions = [
        { value: '', label: '' },
        ...ppCategories.map((category) => ({
          value: category.id,
          label: `${category.friendlyLabel}`,
        })),
      ];
    }

    setValuePayment('source', '');
    setOptions(updatedOptions);
  }, [transactionChannel]);

  const handleKeyDown = (e: any) => {
    switch (e.key) {
      case 'ArrowUp':
        e.preventDefault();
        handleArrowUp();
        break;
      case 'ArrowDown':
        e.preventDefault();
        handleArrowDown();
        break;

      default:
        e.preventDefault();
        break;
    }
  };

  const handleArrowUp = () => {
    if (selectedReceiptIndex > 0) {
      setSelectedReceiptIndex((prevIndex) => prevIndex - 1);
      setReceiptIdFromIndex(selectedReceiptIndex - 1);
    } else {
      setReceiptIdFromIndex(receipts.length - 1);
      setSelectedReceiptIndex(() => receipts.length - 1);
    }
  };

  const handleArrowDown = () => {
    if (selectedReceiptIndex < receipts.length - 1) {
      setSelectedReceiptIndex((prevIndex) => prevIndex + 1);
      setReceiptIdFromIndex(selectedReceiptIndex + 1);
    } else {
      setReceiptIdFromIndex(0);
      setSelectedReceiptIndex(() => 0);
    }
  };

  const setReceiptIdFromIndex = (index: number) => {
    if (receipts[index]) {
      setReceiptId(receipts[index].id);
    }
  };

  const getSuggestedCode = () => {
    const prefix = invoiceType === InvoiceType.PURCHASE ? '4010' : '4110';
    const codesSuggestions = categoriesList?.filter(
      (el) => el?.code?.substring(0, 4) === prefix
    );

    if (!codesSuggestions || codesSuggestions.length === 0)
      return invoiceType === InvoiceType.PURCHASE ? '401' : '411';

    const codes = codesSuggestions?.map((el) => parseInt(el.code));
    const code = codes?.sort((a, b) => b - a)[0];

    return code
      ? `${code + 1}`
      : invoiceType === InvoiceType.PURCHASE
      ? '401'
      : '411';
  };

  const getCategoriesList = (data: Category) => {
    let newCategoriesList = categoriesList;
    newCategoriesList?.push(data);

    const stateAccountingPlan = JSON.parse(
      localStorage.getItem('accountingPlan') ?? ''
    );

    stateAccountingPlan[data.code] = data;

    localStorage.setItem('accountingPlan', JSON.stringify(stateAccountingPlan));

    setValue('categoryCode', data.code);

    setCategoriesList(newCategoriesList);
  };

  function getActionSubmit() {
    const isAccountant = !!connectedUser.roles?.includes('ACCOUNTANT');
    const isValidated = receipt?.fileStatus === StatusEnum.VALIDATED;

    if (isAccountant && !isValidated) {
      return t('Validate');
    } else if (isValidated && isAccountant) {
      return t('Invalidate');
    } else {
      return t('SAVE_CHANGES');
    }
  }

  const addPaymentSet = () => {
    setValuePayment('paymentChannel', '');
    setValuePayment('source', '');
    if (receipt.totalAmount) {
      const sumPaymentActions = paymentActions
        ?.map((el) => el.amount)
        .reduce((a, b) => {
          if (!a) a = 0;
          if (!b) b = 0;
          return a + b;
        }, 0);
      if (sumPaymentActions) {
        setValuePayment(
          'amount',
          parseFloat((receipt?.totalAmount - sumPaymentActions).toFixed(3))
        );

        if (sumPaymentActions !== receipt?.totalAmount) setPaymentSets(true);
      } else {
        setValuePayment('amount', receipt?.totalAmount);
        setPaymentSets(true);
      }
    } else {
      setValuePayment('amount', 0);
      setPaymentSets(true);
    }

    if (receipt?.invoiceDate) {
      const formatedDate = moment(receipt?.invoiceDate).format('YYYY-MM-DD');
      setValuePayment('date', formatedDate);
    } else setValuePayment('date', moment().format('YYYY-MM-DD'));
  };
  const deletePaymentSet = () => {
    setPaymentSets(false);
    resetPayment();
  };

  const addVentilation = () => {
    setIsAddVentilation(true);

    append({
      category: '',
      amount: 0,
      currency: 'TND',
      originalAmount: 0,
    });

    setSelectedVEntilation(fields.length);
  };

  const updateReceipt = () => {
    setIsUpdate(true);
  };

  const cancelUpdateReceipt = () => {
    setIsUpdate(false);
  };

  const deletePaymentAction = (
    index: number,
    transactionId: string | null | undefined
  ) => {
    setIsLoading(true);
    const updatedPaymentActions = paymentActions;
    updatedPaymentActions?.splice(index, 1);
    OperationsService.deletePaymentActionToReceipt(
      updatedPaymentActions,
      transactionId,
      receiptId
    )
      .then((response) => {
        setIsLoading(false);
        setPaymentActions(response.data.paymentActions);
      })
      .catch((error) => {
        if (
          error?.response?.data?.code ===
          operationsErrors.FiscalYear.YEAR_IS_LOCKED
        )
          appToast.error(t(operationsErrors.FiscalYear.YEAR_IS_LOCKED));
      });
  };

  const getReceiptStatus = (status?: StatusEnum) => {
    const statusSensitivity = {
      NOT_ASSOCIATED: 'warning',
      PARTIALLY_ASSOCIATED: 'info',
      ASSOCIATED: 'positive',
    };

    return statusSensitivity[status as keyof typeof statusSensitivity];
  };

  function formatTotalAmount(row: Receipt) {
    if (row?.totalAmount) {
      if (row?.currency !== 'TND' && !row.converted) {
        return '--';
      } else {
        return formatBalance(row?.totalAmount, 'TND');
      }
    } else {
      return '--';
    }
  }

  return (
    <section className="mt-[81px] px-6 pt-6 flex flex-col gap-4 border-1 border-solid rounded-md bg-red w-full h-[calc(100vh-81px)] overflow-y-scroll">
      {confirmationModalIsOpen && (
        <ConfirmationModal
          isOpen={confirmationModalIsOpen}
          onClose={() => {
            setConfirmationModalIsOpen(false);
          }}
          action={() => {
            handleClickInvalidate();
          }}
          title={t('Are you sure to unlock this receipt ?')}
          message={t(
            `You are about to unlock this receipt, please confirm your action.`
          )}
          sensitivity="danger"
          actionName={t('Invalidate')}
        />
      )}

      {isDeleteReceiptModal && (
        <ConfirmationModal
          isOpen={isDeleteReceiptModal}
          onClose={() => {
            setIsDeleteReceiptModal(false);
          }}
          action={() => {
            deleteSelectedReceipt();
            setIsDeleteReceiptModal(false);
          }}
          title={t('Delete receipt')}
          message={t(`You're about to delete this receipt, are you sure ?`)}
          sensitivity="danger"
          actionName={t('Delete')}
        />
      )}

      {isDeleteAttachmentModal && (
        <ConfirmationModal
          isOpen={isDeleteAttachmentModal}
          onClose={() => {
            setIsDeleteAttachmentModal(false);
          }}
          action={() => {
            deleteAttachment();
            setIsDeleteAttachmentModal(false);
          }}
          title={t('DELETE_ATTACHMENT')}
          message={t(`YOURE_ABOUT_TO_DELETE_THIS_ATTACHMENT`)}
          sensitivity="danger"
          actionName={t('Delete')}
        />
      )}

      {isAddCategoryModal && (
        <AddCategoryModal
          isOpen={isAddCategoryModal}
          onClose={() => setIsAddCategoryModal(false)}
          action={getCategoriesList}
          suggestedLabel={receipt?.supplierName ?? ''}
          selectedCategory={
            categoriesList && categoriesList.length > 1
              ? categoriesList[1]
              : undefined
          }
          title={
            invoiceType === InvoiceType.PURCHASE
              ? t('ADD_NEW_SUPPLIER')
              : t('ADD_NEW_CLIENT')
          }
          suggestedCode={getSuggestedCode()}
          isUpdate={false}
        />
      )}

      <CategoryModal
        action={handleSelectCategory}
        onClose={handleIsCategoryModal}
        isOpen={isCategoryModal}
      />

      {/* @ts-expect-error */}
      <ModalGateway>
        {isAttachmentModal ? (
          <Modal onClose={handleAttachmentModal} allowFullscreen={false}>
            <Carousel>
              {/*  */}
              <></>
              <div>
                {selectedAttachmentURL &&
                getFileExtension(selectedAttachment?.name) === 'pdf' ? (
                  <ObjectPdf
                    clsContainer="flex flex-col"
                    url={selectedAttachmentURL ?? ''}
                    cls={'w-[150vh] h-[90vh]'}
                  />
                ) : (
                  <ImageReceipt
                    fullscreen={true}
                    url={selectedAttachmentURL ?? ''}
                    alt="receipt"
                    cls="max-h-[90vh] "
                    clsContainer="object-cover"
                  />
                )}
              </div>
            </Carousel>
          </Modal>
        ) : null}
      </ModalGateway>

      <div className="flex max-h-[calc(89vh)] flex-row w-full">
        <div className="rounded-tl-2xl bg-white pt-6 overflow-hidden pb-9 w-full">
          <div className="px-4 flex items-center justify-between mb-4">
            <button
              onClick={() => navigate(`/dashboard/receipts`)}
              className="flex items-center gap-2 cursor-pointer"
            >
              <KeyboardBackspaceRounded />
              <p className="text-[#171725] font-semibold text-xl  whitespace-nowrap overflow-ellipsis">
                {t('My receipt')}
              </p>
            </button>

            <Tooltip title="You can navigate using the arrow buttons">
              <div className="flex gap-1">
                <div className="border-2 border-solid border-[#7B7B8D] p-1 rounded-lg flex justify-center items-center h-fit">
                  <NorthRounded
                    style={{ height: 15, width: 15 }}
                    htmlColor="#7B7B8D"
                  />
                </div>
                <div className="border-2 border-solid border-[#005AB3] p-1 rounded-lg flex justify-center items-center h-fit">
                  <SouthRounded
                    style={{ height: 15, width: 15 }}
                    htmlColor="#005AB3"
                  />
                </div>
              </div>
            </Tooltip>
          </div>
          <div className="overflow-y-scroll max-h-full">
            {!isRotatingLines ? (
              <InfiniteScroll
                pageStart={1}
                loadMore={handleScroll}
                hasMore={totalPages > pageNumber}
                useWindow={false}
                loader={
                  <div
                    className="w-full flex items-center justify-center py-4"
                    key={0}
                  >
                    <LoadingSpinner strokeColor="#6a94ff" />
                  </div>
                }
              >
                <table
                  className="w-full text-left text-gray-500 focus:outline-none"
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                  tabIndex={selectedReceiptIndex}
                  onKeyDown={handleKeyDown}
                  ref={tableRef}
                >
                  <thead className="border-b">
                    <tr>
                      {columns.map((column, idx) => (
                        <td
                          className={`px-3 py-4 text-xs font-bold text-[#8D98AF] bg-[#F8FAFB] ${
                            column === t('Status') && 'flex justify-end pr-12'
                          }`}
                          key={idx}
                        >
                          {column}
                        </td>
                      ))}
                    </tr>
                  </thead>
                  {/* */}
                  {receipts.map(
                    (
                      {
                        fileName,
                        journalNameCounter,
                        id,
                        status,
                        originalAmount,
                        currency,
                        invoiceDate,
                        converted,
                      },
                      idx
                    ) => (
                      <tr
                        style={{
                          backgroundColor:
                            receiptId === id ? '#F4F9FE' : 'white',
                        }}
                        className={`border-bottom text-xs font-bold cursor-pointer`}
                        key={id}
                        onClick={() => {
                          setReceiptId(id);
                          navigate(
                            `/dashboard/receipts/${fiscalYear}/${id}/idx/${idx}/page/${pageNumber}`
                          );
                        }}
                      >
                        <td className="px-3 py-3">
                          <span className="font-bold text-black">
                            {journalNameCounter ?? '--'}
                          </span>
                          <p className="text-[10px] text-gray-400">
                            {fileName}
                          </p>
                        </td>

                        <td className="px-3 py-3">
                          <div className="max-w-[200px] truncate">
                            <div className="flex items-center gap-1">
                              {currency !== 'TND' && converted ? (
                                <Tooltip
                                  placement="top"
                                  title={`${t('Original amount')}: ${
                                    originalAmount
                                      ? formatBalance(originalAmount, currency)
                                      : '--'
                                  }`}
                                >
                                  <p className="text-gray-400 text-[14px]">
                                    <Help fontSize="inherit" />
                                  </p>
                                </Tooltip>
                              ) : currency !== 'TND' && !converted ? (
                                <Tooltip
                                  placement="top"
                                  title={`${t(
                                    'CURRENCY_NOT_CONVERTED'
                                  )} ${currency}`}
                                >
                                  <p className="text-gray-400 text-[14px]">
                                    <Help fontSize="inherit" />
                                  </p>
                                </Tooltip>
                              ) : null}

                              <span className="text-black">
                                {formatTotalAmount(receipts[idx])}
                              </span>
                            </div>
                            <div className="flex items-center gap-1">
                              <p className="text-[10px] text-gray-400">
                                {invoiceDate ? (
                                  moment(invoiceDate).year() != fiscalYear ? (
                                    <Tooltip
                                      placement="top"
                                      arrow
                                      title={t('DATE_CONFUSION')}
                                    >
                                      <span className="text-[#d9534f]">
                                        {moment(invoiceDate).format('LL')}
                                      </span>
                                    </Tooltip>
                                  ) : (
                                    <span>
                                      {moment(invoiceDate).format('LL')}
                                    </span>
                                  )
                                ) : (
                                  '--'
                                )}
                              </p>
                            </div>
                          </div>
                        </td>

                        <td className={'px-3 py-3 pr-6'}>
                          <div className="flex justify-end w-full">
                            <Badge
                              sensitivity={getReceiptStatus(status)}
                              label={
                                status === StatusEnum.NOT_ASSOCIATED
                                  ? t('NOT_PAID')
                                  : status === StatusEnum.ASSOCIATED
                                  ? t('PAID')
                                  : status ===
                                      StatusEnum.PARTIALLY_ASSOCIATED &&
                                    t('PARTIALLY_PAID')
                              }
                            />
                          </div>
                        </td>
                      </tr>
                    )
                  )}
                </table>
              </InfiniteScroll>
            ) : (
              <div className="flex flex-row justify-center mt-52">
                <RotatingLines visible={true} strokeColor="grey" width="30" />
              </div>
            )}
          </div>
        </div>

        <div className="flex w-full">
          <div className="bg-white min-w-[680px] scroll-mb-16">
            {/* @ts-expect-error */}
            <ModalGateway>
              {isModalOpen ? (
                <Modal onClose={toggleModal} allowFullscreen={false}>
                  <Carousel>
                    {/*  */}
                    <></>
                    <div>
                      <div className="mt-12 p-4">
                        <button
                          onClick={() => setIsModalOpen(!isModalOpen)}
                          type="button"
                          className="flex gap-2 items-center p-3 cursor-pointer text-white ease-in-out duration-300 hover:text-[#bbbbbb] hover:border-[#bbbbbb] border border-white rounded-lg"
                        >
                          <ArrowBack />
                          <span>{t('Go back to receipt')}</span>
                        </button>
                      </div>

                      {receipt?.fileUrl &&
                      getFileExtension(receipt?.fileName) === 'pdf' ? (
                        <ObjectPdf
                          clsContainer="flex flex-col"
                          url={receipt?.fileUrl ?? ''}
                          cls={'w-[150vh] h-[90vh]'}
                          reload={reloadPDF}
                        />
                      ) : (
                        <ImageReceipt
                          fullscreen={true}
                          url={receipt?.fileUrl ?? ''}
                          alt="receipt"
                          cls="w-[65vh] h-[80vh]"
                          clsContainer=" cursor-move"
                        />
                      )}
                    </div>
                  </Carousel>
                </Modal>
              ) : null}
            </ModalGateway>

            <div className="bg-[#F4F4F4] max-w-[680px] border-x-2 border-gray-200 h-full">
              {receipt?.fileUrl &&
              getFileExtension(receipt?.fileName) === 'pdf' ? (
                <ObjectPdf
                  clsContainer="flex flex-col w-full"
                  url={receipt?.fileUrl ?? ''}
                  cls={'w-full h-[89vh]'}
                  reload={reloadPDF}
                />
              ) : (
                <ImageReceipt
                  url={receipt?.fileUrl ?? ''}
                  alt="receipt"
                  cls={'h-[89vh] object-cover'}
                />
              )}
            </div>
          </div>

          <div className="h-full rounded-tr-2xl bg-white py-6 pr-0 min-w-[410px] overflow-y-scroll scrollbar-hide relative">
            <div className="flex flex-col gap-4 ">
              <Separator label={t('INVOICE_DETAILS')} />
              <div className="px-3">
                <form
                  className={`${!isUpdate && 'hidden'}`}
                  onSubmit={handleSubmit(
                    isValidation ? validateReceipt : onSubmit
                  )}
                  id="receipt-form"
                >
                  <div className="flex flex-col gap-2">
                    <div className="flex flex-row gap-2">
                      <div className="w-1/2 flex flex-row">
                        <Input
                          label={`${t('Amount')} (TND)`}
                          placeholder={t('amount')}
                          refs={register('totalAmount')}
                          containerCls="w-full"
                          errorCls="text-red-500"
                          type="number"
                          step="0.001"
                          disabled={
                            receipt?.fileStatus === StatusEnum.VALIDATED
                          }
                          errorMsg={
                            errors.totalAmount?.message &&
                            t('totalAmount is a required field')
                          }
                        />
                      </div>
                      <div className="w-1/2">
                        <Input
                          label={t('Date')}
                          placeholder={t('Date')}
                          refs={register('invoiceDate')}
                          type="date"
                          value={
                            watch('invoiceDate')
                              ? moment(watch('invoiceDate')).format(
                                  'YYYY-MM-DD'
                                )
                              : ''
                          }
                          errorMsg={
                            errors.invoiceDate?.message &&
                            t('invoiceDate is a required field')
                          }
                          containerCls="w-full"
                          errorCls="text-red-500"
                          disabled={
                            receipt?.fileStatus === StatusEnum.VALIDATED
                          }
                        />
                      </div>
                    </div>
                    <div className="flex flex-row gap-2">
                      <div className="w-1/2">
                        <RSelect
                          control={control}
                          id="invoiceType"
                          refs={register('invoiceType')}
                          label={t('Invoice Type')}
                          options={[
                            {
                              label: t('Purchase invoice'),
                              value: InvoiceType.PURCHASE,
                            },
                            {
                              label: t('Sales invoice'),
                              value: InvoiceType.SALE,
                            },
                          ]}
                          isDisabled={
                            receipt?.fileStatus === StatusEnum.VALIDATED
                          }
                          onInputChange={() => {
                            const INVOICE_TYPE = getValues('invoiceType');
                            setInvoiceType(INVOICE_TYPE);
                          }}
                        />
                      </div>
                      <div className="w-1/2">
                        <RSelect
                          control={control}
                          id="categoryCode"
                          refs={register('categoryCode')}
                          label={`${t('Supplier')}`}
                          isOptionDisabled={(option: any) => !option.value}
                          optionalButtonCls="text-xs"
                          optionalButton={
                            invoiceType === InvoiceType.SALE
                              ? t('ADD_A_New_CLIENT')
                              : t('ADD_NEW_SUPPLIER')
                          }
                          optionButtonFunc={() => {
                            setIsAddCategoryModal(true);
                          }}
                          isDisabled={
                            receipt?.fileStatus === StatusEnum.VALIDATED
                          }
                          options={categoriesList?.map((el) => ({
                            label: el.code
                              ? `${el.code} - ${el.label}`
                              : el.label,
                            value: el.code != '' ? el.code : null,
                          }))}
                          errorMsg={
                            errors.categoryCode && 'Please select a supplier'
                          }
                        />
                      </div>
                    </div>

                    <div className="flex flex-row justify-items-center gap-2 justify-end">
                      <Button
                        type="button"
                        sensitivity="Secondary"
                        label={t('Cancel')}
                        size="medium"
                        onClick={cancelUpdateReceipt}
                      />
                      <Button
                        type="submit"
                        sensitivity="Secondary"
                        label={t('Save')}
                        size="medium"
                        disabled={receipt?.fileStatus === StatusEnum.VALIDATED}
                        onClick={() => {
                          setIsValidation(false);
                        }}
                      />
                    </div>
                  </div>
                </form>
                {!isUpdate && (
                  <div className="flex flex-col gap-5">
                    <div className="w-full flex justify-between item-center">
                      <div>
                        <h2 className="font-bold">
                          {formatBalance(receipt?.totalAmount)}
                        </h2>
                        <p className="text-xs text-gray-400">
                          {t('VAT')}: {formatBalance(receipt?.totalTaxAmount)}
                        </p>
                      </div>
                      <div className="flex gap-2 items-start">
                        <button
                          disabled={
                            receipt?.fileStatus === StatusEnum.VALIDATED
                          }
                          onClick={() => {
                            setIsDeleteReceiptModal(true);
                          }}
                          className="p-[6px] rounded-full disabled:opacity-50 bg-red-100 text-[16px] h-[28px] w-[28px] flex justify-center items-center"
                        >
                          <Delete fontSize="inherit" htmlColor="#d9534f" />
                        </button>

                        <div className="h-[28px] w-[28px]">
                          <DiscussionPopover
                            loit={receipt?.id}
                            lot={'RECEIPT'}
                            connectedUser={connectedUser}
                            chat={chatData}
                            isTable
                          />
                        </div>

                        <button
                          onClick={updateReceipt}
                          className="p-[6px] rounded-full disabled:opacity-50 bg-gray-200 text-[16px] h-[28px] w-[28px] flex justify-center items-center"
                        >
                          <Edit fontSize="inherit" htmlColor="#585858" />
                        </button>
                      </div>
                    </div>
                    <div className="flex flex-col gap-4">
                      <div className="flex items-center gap-2 max-w-[376px]">
                        <p className="text-xs text-gray-400">
                          {t('Supplier')}:{' '}
                        </p>
                        <Tooltip title={receipt?.clientName} placement="top">
                          {errors.categoryCode?.message ? (
                            <p className="text-red-500 truncate">
                              {t('SUPPLIER_IS_REQUIRED')}
                            </p>
                          ) : (
                            <p className="text-xs font-bold truncate">
                              {`${receipt?.categoryCode || '--'} - ${
                                getCategoryName(receipt?.categoryCode ?? '') ||
                                '--'
                              }`}
                            </p>
                          )}
                        </Tooltip>
                      </div>
                      <div className="flex items-center gap-2">
                        <p className="text-xs text-gray-400">
                          {t('invoiceDate')}:
                        </p>
                        <p className="text-xs font-bold">
                          {errors.invoiceDate?.message ? (
                            <p className="text-red-500">
                              {t('invoiceDate is a required field')}
                            </p>
                          ) : receipt?.invoiceDate ? (
                            moment(watch('invoiceDate')).format('DD-MM-YYYY')
                          ) : (
                            '--'
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <Separator
                label={t('RECEIPT_VENTILATION')}
                showButton
                icon={<Add />}
                onClick={addVentilation}
                disabled={
                  receipt?.fileStatus === StatusEnum.VALIDATED ||
                  isAddVentilation
                }
                toolTipMsg={
                  receipt?.fileStatus == StatusEnum.VALIDATED &&
                  t('RECEIPT_ARE_VALIDATED')
                }
              />

              <div className="px-3">
                {!!gapAmount && (
                  <div className="mb-2.5">
                    <Banner
                      open={!!gapAmount}
                      title={t('GAP_AMOUNT')}
                      msg={`${t('There is a gap amount of')} ${formatBalance(
                        gapAmount
                      )}`}
                      sensitivity="Critical"
                      isButton={gapAmount > 0}
                      buttonLabel={t('Add a new ventilation')}
                      onClick={() => {
                        setIsAddVentilation(true);
                        setSelectedVEntilation(fields.length);
                        append({
                          category: '',
                          amount: gapAmount,
                          currency: 'TND',
                          originalAmount: 0,
                        });
                      }}
                    />
                  </div>
                )}

                <div className="flex flex-col gap-2.5">
                  {fields.map((el, idx) => (
                    <div
                      key={idx}
                      className="border border-[#D5DBE1] p-2.5 rounded-md max-w-[376px]"
                    >
                      {selectedVEntilation === idx ? (
                        <div
                          onClick={() => {
                            setSelectedVEntilation(idx);
                          }}
                          className="flex flex-col gap-2"
                        >
                          <div className="flex flex-row gap-2 items-end">
                            <div className="w-1/2 max-w-[300px]">
                              <Tooltip
                                title={
                                  !getValuesVentilation(
                                    `ventilationList.${idx}.category`
                                  )
                                    ? t('Select category')
                                    : `
                                ${getValuesVentilation(
                                  `ventilationList.${idx}.category`
                                )} - ${
                                        getCategoryName(
                                          getValuesVentilation(
                                            `ventilationList.${idx}.category`
                                          ) || ''
                                        ) || t('Select category')
                                      }
                              `
                                }
                                placement="top"
                              >
                                <button
                                  onClick={(e) => {
                                    handleIsCategoryModal(idx);
                                    clearErrorsVentilation('ventilationList');
                                    e.cancelable = true;
                                    e.stopPropagation();
                                  }}
                                  type="button"
                                  disabled={
                                    receipt?.fileStatus === StatusEnum.VALIDATED
                                  }
                                  className="text-blue-500 underline text-sm underline-offset-4 text-left max-w-[90%] truncate mb-1"
                                >
                                  {getCategoryName(
                                    getValuesVentilation(
                                      `ventilationList.${idx}.category`
                                    ) || ''
                                  ) || t('Select category')}
                                </button>
                              </Tooltip>
                            </div>

                            <div className="w-1/2">
                              <Input
                                label={t('Amount HT')}
                                placeholder={t('amount')}
                                refs={registerVentilation(
                                  `ventilationList.${idx}.amount`
                                )}
                                containerCls="w-full"
                                errorCls="text-red-500"
                                type="number"
                                disabled={
                                  receipt?.fileStatus == StatusEnum.VALIDATED
                                }
                              />
                            </div>
                          </div>

                          <div className="flex flex-row justify-items-center gap-2 justify-end">
                            {!isAddVentilation && (
                              <Button
                                type="button"
                                sensitivity="Destructive"
                                label={t('Delete')}
                                size="medium"
                                disabled={
                                  receipt?.fileStatus ===
                                    StatusEnum.VALIDATED || fields.length === 1
                                }
                                onClick={(e) => {
                                  remove(idx);
                                  setSelectedVEntilation(
                                    null as unknown as number
                                  );
                                  updateReceiptData();
                                  e.cancelable = true;
                                  e.stopPropagation();
                                }}
                              />
                            )}
                            <Button
                              type="button"
                              sensitivity="Secondary"
                              label={t('Cancel')}
                              size="medium"
                              onClick={(e) => {
                                setSelectedVEntilation(
                                  null as unknown as number
                                );

                                isAddVentilation && remove(idx);
                                setIsAddVentilation(false);
                                e.cancelable = true;
                                e.stopPropagation();
                              }}
                            />
                            <Button
                              type="submit"
                              sensitivity="Secondary"
                              label={isAddVentilation ? t('Add') : t('Save')}
                              size="medium"
                              disabled={
                                receipt?.fileStatus === StatusEnum.VALIDATED ||
                                Number(
                                  getValuesVentilation(
                                    `ventilationList.${idx}.amount`
                                  )
                                ) === 0 ||
                                !getValuesVentilation(
                                  `ventilationList.${idx}.category`
                                ) ||
                                getValuesVentilation(
                                  `ventilationList.${idx}.amount`
                                ) < 0
                              }
                              onClick={(e) => {
                                update(idx, {
                                  category: getValuesVentilation(
                                    `ventilationList.${idx}.category`
                                  ),
                                  amount: getValuesVentilation(
                                    `ventilationList.${idx}.amount`
                                  ),
                                });

                                setSelectedVEntilation(
                                  null as unknown as number
                                );

                                updateReceiptData();
                                setIsAddVentilation(false);
                                e.cancelable = true;
                                e.stopPropagation();
                              }}
                            />
                          </div>
                        </div>
                      ) : (
                        <div
                          onClick={() => {
                            setSelectedVEntilation(idx);
                            setIsAddVentilation(false);
                          }}
                          className="flex items-start justify-between cursor-pointer"
                        >
                          <Tooltip
                            title={`
                                ${
                                  getValuesVentilation(
                                    `ventilationList.${idx}.category`
                                  ) || '0'
                                } - ${
                              getCategoryName(
                                getValuesVentilation(
                                  `ventilationList.${idx}.category`
                                ) || ''
                              ) || t('Select category')
                            }
                              `}
                            placement="top"
                          >
                            <button
                              type="button"
                              disabled={
                                receipt?.fileStatus === StatusEnum.VALIDATED
                              }
                              className="text-blue-500 underline text-sm underline-offset-4 text-left max-w-[60%]"
                            >
                              {getCategoryName(el?.category || '') ||
                                t('Select category')}
                            </button>
                          </Tooltip>
                          <div className="flex flex-col gap-[1px] items-start">
                            <span className="text-sm font-bold">
                              {formatBalance(el.amount, el.currency)}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>

              <Separator
                label={t('PAYMENT_FLOW')}
                showButton
                icon={<Add />}
                onClick={addPaymentSet}
                disabled={receipt?.fileStatus === StatusEnum.VALIDATED}
                toolTipMsg={
                  receipt?.fileStatus === StatusEnum.VALIDATED &&
                  t('RECEIPT_ARE_VALIDATED')
                }
              />

              <div className="px-3">
                {paymentSets && (
                  <form
                    onSubmit={handleSubmitPayment(onSubmitPayment)}
                    id="payment-form"
                    className="pb-2.5"
                  >
                    <div
                      key={index}
                      className="flex flex-col gap-2 border border-[#D5DBE1] p-2.5 rounded-md"
                    >
                      <div className="flex flex-row gap-2">
                        <div className="w-1/2">
                          <RSelect
                            refs={registerPayment('paymentChannel')}
                            control={controlPayment}
                            label={t('Payment Method')}
                            options={paymentChannel
                              .map((elm) => {
                                if (
                                  elm === 'PP' &&
                                  connectedCompany?.legalStatus
                                ) {
                                  return null;
                                }
                                return {
                                  value: elm,
                                  label: elm,
                                };
                              })
                              .filter(Boolean)}
                            placeholder={t('ex. BANK')}
                            isDisabled={
                              receipt?.fileStatus == StatusEnum.VALIDATED
                            }
                          />
                        </div>

                        <div className="w-1/2">
                          <RSelect
                            refs={registerPayment('source')}
                            control={controlPayment}
                            label={t('Source')}
                            options={options}
                            isDisabled={
                              receipt?.fileStatus == StatusEnum.VALIDATED
                            }
                          />
                        </div>
                      </div>
                      <div className="flex flex-row gap-2">
                        <div className="w-1/2 flex flex-row">
                          <Input
                            refs={registerPayment('amount')}
                            type="number"
                            placeholder={'type ... '}
                            containerCls="w-full"
                            errorCls="text-red-500"
                            label={t('Amount')}
                            step="0.001"
                            errorMsg={
                              errorsPayment.amount?.message &&
                              t(errorsPayment.amount?.message)
                            }
                          />
                        </div>

                        <div className="w-1/2">
                          <Input
                            type="date"
                            label={t('Date')}
                            refs={registerPayment('date')}
                            placeholder={t('Search')}
                            containerCls="w-full"
                            errorCls="text-red-500"
                          />
                        </div>
                      </div>
                      <div className="flex flex-row justify-items-center gap-2 justify-end">
                        <Button
                          type="button"
                          sensitivity="Secondary"
                          label={t('Cancel')}
                          size="medium"
                          onClick={() => {
                            deletePaymentSet();
                          }}
                        />
                        <Button
                          type="submit"
                          sensitivity="Secondary"
                          label={t('Save')}
                          size="medium"
                        />
                      </div>
                    </div>
                  </form>
                )}

                {!paymentActions?.length ? (
                  <p className="text-xs w-full text-center text-gray-400">
                    {t('EMPTY_PAYMENT')}.
                  </p>
                ) : (
                  <div className="flex flex-col gap-2.5">
                    {paymentActions?.map((el, idx) => (
                      <div
                        className="flex flex-col gap-2 p-2.5 rounded-md border border-[#D5DBE1]"
                        key={idx}
                      >
                        <div className="flex justify-between items-center">
                          <span className="text-[14px] font-bold text-[#3B71FE]">
                            {formatBalance(el?.amount)}
                          </span>
                          <button
                            onClick={() => {
                              setPaymentActions((prevPaymentActions) => {
                                const updatedPaymentActions = [
                                  ...prevPaymentActions,
                                ];
                                updatedPaymentActions?.splice(idx, 1);
                                return updatedPaymentActions;
                              });
                              deletePaymentAction(
                                idx,
                                el.associatedTransactionId
                              );
                            }}
                            disabled={
                              (fiscalYearUsed && fiscalYearUsed.locked) ||
                              receipt?.fileStatus === StatusEnum.VALIDATED
                            }
                            className="p-[6px] rounded-full disabled:opacity-50 bg-red-100 text-[16px] h-[28px] w-[28px] flex justify-center items-center"
                          >
                            <Delete fontSize="inherit" htmlColor="#d9534f" />
                          </button>
                        </div>
                        <div className="flex justify-between items-center">
                          <p className="text-xs text-[#777E90]">
                            {moment
                              .utc(el.paymentDate)
                              .local()
                              .format('DD MMM YYYY')}
                          </p>
                          <div className="flex">
                            <p className="text-xs text-[#777E90]">
                              {el?.paymentChannel}
                              {' - '}
                              {getSourceName(
                                el.paymentChannel,
                                el.paymentActionSource
                              ) || t('UNKOWN_SOURCE')}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <Separator
                label={`${t('ATTACHMENTS')} ${
                  receiptAttachments?.length ?? 0
                }/5`}
                showButton
                onClick={() => {}}
                customButton={
                  <div>
                    <label
                      className={`flex justify-center items-center h-[24px] w-[28px] rounded border border-[#D5DBE1] bg-[#EBEEF1] ${
                        receiptAttachments?.length === 4
                          ? 'opacity-50 cursor-default'
                          : 'cursor-pointer'
                      }`}
                      htmlFor="upload-attachment"
                    >
                      <FileUpload />
                    </label>

                    <input
                      className="hidden"
                      id="upload-attachment"
                      disabled={receiptAttachments?.length === 5}
                      type="file"
                      onChange={(val) =>
                        val.target.files?.length &&
                        uploadAttachment(val.target.files?.[0])
                      }
                    />
                  </div>
                }
              />

              {isGetAttachmentLoading ? (
                <div className="p-4 flex justify-center items-center">
                  <LoadingSpinner
                    width="25"
                    strokeWidth="2"
                    strokeColor="#62626e"
                  />
                </div>
              ) : (
                <div className="px-3 flex flex-col gap-2">
                  {receiptAttachments.length ? (
                    receiptAttachments?.map((el, idx) => (
                      <div
                        className="border border-[#D5DBE1] p-2.5 rounded-md cursor-pointer max-w-[376px]"
                        onClick={() => {
                          setSelectedAttachment(el);
                          getAttachmentPreview(el);
                          handleAttachmentModal();
                        }}
                        key={idx}
                      >
                        <div className="w-full flex justify-between items-start">
                          <div className="flex gap-2 items-start">
                            <Icon
                              {...getFileTypeIconProps({
                                extension: el.name.split('.').pop() ?? '',
                                size: 24,
                                imageFileType: 'svg',
                              })}
                            />
                            <div className="flex flex-col w-full">
                              <span className="text-sm font-bold max-w-[250px] truncate">
                                {el.name}
                              </span>
                              <p className="text-gray-400 text-xs">
                                {formatBytes(el.size)}
                              </p>
                            </div>
                          </div>

                          <div className="flex items-center gap-1">
                            <button
                              onClick={(e) => {
                                setIsDeleteAttachmentModal(true);
                                setAttachmentToDelete(el.path);
                                e.cancelable = true;
                                e.stopPropagation();
                              }}
                              className="p-[6px] rounded-full disabled:opacity-50 bg-red-100 text-[15px] h-[25px] w-[25px] flex justify-center items-center"
                            >
                              <Delete fontSize="inherit" htmlColor="#d9534f" />
                            </button>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="text-center flex flex-col gap-1">
                      <p className="text-xs w-full text-center text-gray-400">
                        {t('No_Files')}.
                      </p>

                      <p className="text-xs w-full text-center text-gray-400">
                        {t('UPLOAD_FILE')}
                      </p>
                    </div>
                  )}
                </div>
              )}
            </div>

            {getActionSubmit() !== t('SAVE_CHANGES') && (
              <div className="absolute bottom-10 right-0 bottom-16  bg-white w-full">
                <div className="flex w-full justify-end bg-white py-3 px-2 absolute">
                  {getActionSubmit() === t('Validate') && (
                    <Button
                      form="receipt-form"
                      iconPosition="right"
                      isLoading={isLoading}
                      disabled={
                        isLoading || (fiscalYearUsed && fiscalYearUsed.locked)
                      }
                      type="submit"
                      label={getActionSubmit()}
                      onClick={() => {
                        setIsValidation(true);
                      }}
                    />
                  )}

                  {getActionSubmit() === t('Invalidate') && (
                    <Button
                      sensitivity="Primary"
                      label={getActionSubmit()}
                      size="medium"
                      disabled={
                        isLoading || (fiscalYearUsed && fiscalYearUsed.locked)
                      }
                      isLoading={isLoading}
                      onClick={() => {
                        setIsValidation(false);
                        setConfirmationModalIsOpen(true);
                      }}
                      icon={
                        <NoEncryptionGmailerrorred
                          fontSize="inherit"
                          htmlColor="white"
                        />
                      }
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ReceiptPage;
