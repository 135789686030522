import { type FC, useEffect, useState } from 'react';

import {
  ArrowBack,
  CancelOutlined,
  EditOutlined,
  SaveOutlined,
  ZoomOutMapRounded,
} from '@mui/icons-material';
import AttachmentIcon from '@mui/icons-material/Attachment';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Dialog, Popover } from '@mui/material';

import { Tooltip } from '@/components/global';
import { useAppTranslation } from '@/hooks';
import { appToast } from '@/lib/ToastContainers';
import { ImageReceipt } from '@/pages/dashboard/Receipts/Receipt';
import { ObjectPdf } from '@/pages/dashboard/common';
import { SaasService } from '@/services';
import DeclarationService from '@/services/DeclarationService';
import { convertToShortDate, formatBalance } from '@/utils/funcs';

import type {
  Company,
  DeclarationSubmit,
  DeclarationTemplate,
} from '../Declaration';
import {
  DeclarationStatusEnum,
  getFileExtension,
  isLate,
} from '../common/utils';
import UpdateDeclarationDetails from './UpdateDeclarationDetails';

const BASE_URL =
  window.ENV[process.env.NODE_ENV ?? 'development'].apiDeclarations;

interface ConfirmationModalProps {
  anchorEl: any;
  setAnchorEl: any;
  declarationSubmit: DeclarationSubmit | null;
  declarationTemplate: DeclarationTemplate | null;
  company: Company | null;
  setIsDeclarationSubmitUpdated: Function;
  isDeclarationSubmitUpdated: boolean;
  setDeclarationSubmit: Function;
}

/**
 *
 * @param {any} anchorEl display and hide the modal
 * @param {Function} setAnchorEl responsible function to close the modal
 * @param {DeclarationSubmit} declarationSubmit declarationSubmit submit
 * @param {DeclarationTemplate} declarationTemplate declarationSubmit template
 * @param {string} company company details
 * @returns
 */
const DeclarationDetails: FC<ConfirmationModalProps> = ({
  anchorEl = null,
  setAnchorEl,
  declarationSubmit,
  declarationTemplate,
  company,
  setIsDeclarationSubmitUpdated,
  isDeclarationSubmitUpdated,
  setDeclarationSubmit,
}) => {
  const { t } = useAppTranslation();
  const open = Boolean(anchorEl);

  const [isEditable, setIsEditable] = useState(false);
  const [declaredByFullName, setDeclaredByFullName] = useState('');
  const [payedByFullName, setPayedByFullName] = useState('');
  const [selectedAttachment, setSelectedAttachment] = useState<{
    id: string;
    filename: string;
  } | null>(null);
  const [isAttachmentPreviewModalOpen, setIsAttachmentPreviewModalOpen] =
    useState<boolean>(false);
  const [attachments, setAttachments] = useState<
    {
      id: string;
      filename: string;
    }[]
  >([]);

  const handleClose = () => {
    setIsEditable(true);
    setAnchorEl(false);
    setSelectedAttachment(null);
    setAttachments([]);
  };

  const updateDeclaration = () => {
    setIsEditable(!isEditable);
  };

  const cancelDeclaration = () => {
    if (!declarationSubmit?.id) return;
    DeclarationService.cancelDeclaration(declarationSubmit?.id)
      .then(() => {
        handleClose();
      })
      .catch((e) => {
        appToast.error(t(e.response.data.code));
      });
  };

  const constructAttachmentUrl = (
    declarationId: string | undefined,
    attachmentId: string | undefined
  ) => {
    return `${BASE_URL}/v1/submits/${declarationId}/attachments/${attachmentId}`;
  };

  useEffect(() => {
    if (declarationSubmit?.declaredBy) {
      SaasService.getUserById(declarationSubmit?.declaredBy).then((res) => {
        const userInfo = res?.data?.users?.[0];
        setDeclaredByFullName(userInfo.name + ' ' + userInfo.surname);
      });
    }

    if (declarationSubmit?.payedBy) {
      SaasService.getUserById(declarationSubmit?.payedBy).then((res) => {
        const userInfo = res?.data?.users?.[0];
        setPayedByFullName(userInfo.name + ' ' + userInfo.surname);
      });
    }

    setAttachments(declarationSubmit?.attachments ?? []);
  }, [declarationSubmit, isDeclarationSubmitUpdated]);

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <div className="flex flex-row bg-[#f6f9ff] overflow-y-hidden">
        <div className="  min-w-[350px] max-w-[350px]">
          <div className="p-4 border-r border-l   flex flex-col gap-4">
            <div className="flex items-center justify-between ">
              <div className="w-full">
                <div className="flex justify-end  mb-4">
                  {isEditable && (
                    <div>
                      <button
                        onClick={updateDeclaration}
                        className="cursor-pointer hover:opacity-70 duration-300"
                      >
                        <EditOutlined
                          htmlColor="#585858"
                          style={{ height: 20 }}
                        />
                      </button>
                      <button
                        onClick={cancelDeclaration}
                        className="cursor-pointer hover:opacity-70 duration-300"
                      >
                        <DeleteOutlineIcon
                          htmlColor="#585858"
                          style={{ height: 20 }}
                        />
                      </button>
                    </div>
                  )}

                  {!isEditable && (
                    <div>
                      <button
                        onClick={updateDeclaration}
                        className="cursor-pointer hover:opacity-70 duration-300"
                      >
                        <SaveOutlined
                          htmlColor="#585858"
                          style={{ height: 20 }}
                        />
                      </button>
                      <button
                        onClick={handleClose}
                        className="cursor-pointer hover:opacity-70 duration-300"
                      >
                        <CancelOutlined
                          htmlColor="#585858"
                          style={{ height: 20 }}
                        />
                      </button>
                    </div>
                  )}
                </div>
                <div>
                  <div className="text-sm flex justify-between items-center  ">
                    <Tooltip
                      message={declarationTemplate?.name ?? ''}
                      position="top"
                    >
                      <span className="max-w-[230px] truncate">
                        {declarationTemplate?.name}
                      </span>
                    </Tooltip>
                    <span>{declarationTemplate?.dueDate}</span>
                  </div>
                  <div className="text-sm flex w-full justify-start">
                    <Tooltip message={company?.name ?? ''} position="bottom">
                      <span className="max-w-[230px] truncate">
                        {company?.name}
                      </span>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            {isEditable ? (
              <>
                <div className="flex justify-between items-center">
                  <div className="flex flex-row items-center justify-start flex-wrap">
                    {declarationSubmit?.amount &&
                    declarationSubmit.template.isPayable ? (
                      <div
                        className={`${
                          declarationSubmit?.status ===
                          DeclarationStatusEnum.PAYED
                            ? 'bg-[#2B9F93]'
                            : declarationSubmit?.status ===
                              DeclarationStatusEnum.DECLARED
                            ? 'bg-[#ef9b0f]'
                            : 'bg-[#3B719F]'
                        } 
                      w-fit  px-3 mx-1 rounded-full h-6 text-white text-xs font-bold text-center py-1`}
                      >
                        {formatBalance(declarationSubmit?.amount, 'TND')}
                      </div>
                    ) : (
                      <></>
                    )}

                    {declarationSubmit?.status ===
                      DeclarationStatusEnum.PAYED && (
                      <Tooltip
                        position="top"
                        message={`Payed by ${payedByFullName}`}
                      >
                        <div className="h-6 bg-[#2B9F93] my-1 mx-1 py-1 px-3 rounded-full text-white text-xs font-bold text-center flex gap-1">
                          {isLate(
                            declarationSubmit?.payedWhen,
                            declarationSubmit.template.id
                          ) && (
                            <div className="p-2 bg-[#df4759] rounded-full border border-white"></div>
                          )}
                          {t('PAYED')} |
                          {convertToShortDate(declarationSubmit?.payedWhen)}
                        </div>
                      </Tooltip>
                    )}

                    {(declarationSubmit?.status ===
                      DeclarationStatusEnum.DECLARED ||
                      declarationSubmit?.status ===
                        DeclarationStatusEnum.PAYED) && (
                      <Tooltip
                        position="top"
                        message={`${t('Declared by')} ${declaredByFullName}`}
                      >
                        <div className="h-6 bg-[#ef9b0f] my-1 mx-1 py-1 px-3 rounded-full text-white text-xs font-bold text-center flex gap-1">
                          {isLate(
                            declarationSubmit?.declaredWhen,
                            declarationSubmit.template.id
                          ) && (
                            <div className="p-2 bg-[#df4759] rounded-full border border-white"></div>
                          )}
                          {t('DECLARED')} |
                          {convertToShortDate(declarationSubmit?.declaredWhen)}
                        </div>
                      </Tooltip>
                    )}
                  </div>
                </div>
                <div></div>
                <hr />
                <div className="max-w-[350px] ">
                  <span> {t('Notes')}</span>
                  <p className="text-xs max-w-[350px]  flex flex-wrap">
                    {declarationSubmit?.note ?? t('No notes')}
                  </p>
                </div>
                <hr />
                <div className=" overflow-auto">
                  <span>{t('Attachments')}</span>

                  {attachments.length ? (
                    attachments?.map((attachment: any, index: number) => {
                      return (
                        <div
                          key={index}
                          className="flex justify-between items-center h-10 "
                        >
                          <div className="flex flex-row items-center w-64  cursor-pointer">
                            <AttachmentIcon></AttachmentIcon>
                            <Tooltip
                              position="top"
                              message={attachment.filename}
                            >
                              <span
                                onClick={() =>
                                  setSelectedAttachment(attachment)
                                }
                                className="text-sm ml-2  truncate cursor-pointer"
                              >
                                {attachment.filename}
                              </span>
                            </Tooltip>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div>
                      <span className="text-xs">{t('No Uploaded Files')}</span>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <div>
                <UpdateDeclarationDetails
                  declarationSubmit={declarationSubmit}
                  setIsDeclarationSubmitUpdated={setIsDeclarationSubmitUpdated}
                  isDeclarationSubmitUpdated={isDeclarationSubmitUpdated}
                  setDeclarationSubmit={setDeclarationSubmit}
                  setSelectedAttachment={setSelectedAttachment}
                  attachments={attachments}
                  setAttachments={setAttachments}
                />
              </div>
            )}
          </div>
        </div>
        <Dialog
          onClose={() => {
            setIsAttachmentPreviewModalOpen(!isAttachmentPreviewModalOpen);
          }}
          open={isAttachmentPreviewModalOpen}
          fullScreen
        >
          <div className="w-full">
            <div className="p-8">
              <button
                onClick={() =>
                  setIsAttachmentPreviewModalOpen(!isAttachmentPreviewModalOpen)
                }
                type="button"
                className="flex gap-2 items-center p-2 cursor-pointer text-[#7F7F7F] ease-in-out duration-300 hover:text-[#5c5c5c]"
              >
                <ArrowBack />
                <span>{t('Go back to declarations')}</span>
              </button>
            </div>
            {getFileExtension(selectedAttachment?.filename) === 'pdf' ? (
              <ObjectPdf
                clsContainer="flex flex-col w-full"
                url={constructAttachmentUrl(
                  declarationSubmit?.id,
                  selectedAttachment?.id
                )}
                cls={'w-full h-[85vh]'}
              />
            ) : (
              <ImageReceipt
                fullscreen={true}
                url={constructAttachmentUrl(
                  declarationSubmit?.id,
                  selectedAttachment?.id
                )}
                alt="receipt"
                cls="w-full h-[80vh]"
                clsContainer="w-full"
              />
            )}
          </div>
        </Dialog>
        {selectedAttachment && (
          <div className=" min-w-[350px] max-w-[350px] ">
            <div
              className=" my-3 text-[#7B7B8D] font-semibold text-lg flex gap-2 justify-center items-center cursor-pointer hover:opacity-80 transition-opacity duration-300"
              onClick={() => {
                setIsAttachmentPreviewModalOpen(!isAttachmentPreviewModalOpen);
              }}
            >
              <ZoomOutMapRounded />
              <p className="my-1"> {t('View in fullscreen')}</p>
            </div>
            <div className=" h-full">
              <ObjectPdf
                clsContainer="flex flex-col w-full"
                url={constructAttachmentUrl(
                  declarationSubmit?.id,
                  selectedAttachment?.id
                )}
                cls={'w-full h-[50vh]'}
              />
            </div>
          </div>
        )}
      </div>
    </Popover>
  );
};

export default DeclarationDetails;
