import type { FC } from 'react';
import { useEffect, useRef, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { LockOutlined } from '@mui/icons-material';
import { Alert } from '@mui/material';
// eslint-disable-next-line import/no-named-as-default
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { Logo, resetPassword } from '@/assets/images';
import { Button, Input } from '@/components/global';
import { useAppTranslation } from '@/hooks';
import { useUpdatePassword } from '@/hooks/reset-password-hooks';
import { appToast } from '@/lib/ToastContainers';
import type { UpdatePasswordFormType } from '@/lib/forms';
import { updatePasswordFormSchema } from '@/lib/forms';
import { AuthService } from '@/services';
import { SITE_KEY } from '@/utils/constants';

enum ResetPasswordErrorCode {
  INVALID_TOKEN = 'INVALID_TOKEN',
  ALREADY_USED_TOKEN = 'ALREADY_USED_TOKEN',
  EXPIRED_TOKEN = 'EXPIRED_TOKEN',
}

interface UpdatePasswordProps {}

const UpdatePassword: FC<UpdatePasswordProps> = () => {
  const { t } = useAppTranslation();
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const navigate = useNavigate();
  const { resetToken } = useParams();
  const { mutate, isLoading } = useUpdatePassword();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<UpdatePasswordFormType>({
    resolver: yupResolver(updatePasswordFormSchema),
    mode: 'all',
  });

  const [isResetTokenValid, setIsResetTokenValid] = useState(false);
  const [verificationIsLoading, setVerificationIsLoading] = useState(true);

  const onSubmit = async (data: UpdatePasswordFormType) => {
    const recaptchaToken =
      recaptchaRef.current && (await recaptchaRef.current.executeAsync());

    mutate(
      { ...data, recaptchaToken, resetToken: resetToken! },
      {
        onSuccess: () => {
          navigate('/auth/sign-in');
        },
        onError: (error: any) => {
          recaptchaRef.current?.reset();
          const errorCode = error?.response?.data?.code;

          if (errorCode == ResetPasswordErrorCode.EXPIRED_TOKEN)
            appToast.error(t('The reset password link has expired.'));
          else if (errorCode == ResetPasswordErrorCode.ALREADY_USED_TOKEN)
            appToast.error(t('The reset password link is already used.'));
          else if (errorCode == ResetPasswordErrorCode.INVALID_TOKEN)
            appToast.error(t('The reset password link is invalid.'));
          else appToast.error(t('Error occured.'));
        },
      }
    );
  };

  useEffect(() => {
    setVerificationIsLoading(true);

    const getRecaptcha: any = async () => {
      return (
        recaptchaRef.current && (await recaptchaRef.current.executeAsync())
      );
    };

    getRecaptcha().then((recaptchaToken: string | null) =>
      AuthService.verifyResetToken({ resetToken: resetToken! }, recaptchaToken)
        .then(() => {
          setIsResetTokenValid(true);
          setVerificationIsLoading(false);
        })
        .catch(() => {
          recaptchaRef.current?.reset();
          setIsResetTokenValid(false);
          setVerificationIsLoading(false);
        })
    );
  }, []);

  return (
    <div className="bg-first min-w-screen min-h-screen h-full w-full lg:gap-2 xl:flex flex-row transition-all duration-1000">
      {/* ------------------------------------ Left section -----------------------------------*/}

      <div className="hidden bg-sec xl:w-3/6 xl:flex xl:flex-col xl:items-center xl:justify-center xl:min-h-screen xl:h-full ">
        <div className="flex w-full lg:w-full lg:flex xl:w-3/5 xl:flex-col">
          <img
            alt="reset-password"
            src={resetPassword}
            width={500}
            height={500}
          ></img>
          <p className="color-black max-w-[600px] xl:ml-auto xl:mr-auto">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.
          </p>
        </div>
      </div>

      {/* ------------------------------------ Right section -----------------------------------*/}
      {!verificationIsLoading && (
        <>
          <div className="w-3/4 max-w-[900px] min-w-screen min-h-screen ml-auto mr-auto h-full flex-col flex items-center justify-center sm:w-3/6 transition-all duration-1000">
            <div className="w-full h-full sm:w-full md:w-2/3 lg:w-3/6 xl:w-3/6">
              <div className="flex justify-center items-center">
                <Logo />
              </div>
              {isResetTokenValid ? (
                <div>
                  <div className="flex justify-center">
                    <h2 className="transition-all text-center mt-6 mb-3 color-black xl:mt-12">
                      {t('Reset your password')}
                    </h2>
                  </div>

                  <div>
                    <p className="color-grey text-center pb-[24px] mb-8">
                      {t('Enter a new password for your account.')}
                    </p>

                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="w-full  gap-y-[10px]  flex flex-col lg:gap-y-[10px] xl:gap-y-[25px] mt-12">
                        <Input
                          cls="rounded-lg border-input placeholder-gray focus:outline-none"
                          icon={
                            <LockOutlined className="icons-input-size text-[#9E9DA8]" />
                          }
                          placeholder={t('Password')}
                          type="password"
                          refs={register('password')}
                          errorCls="rounded-lg focus:outline-none"
                          errorMsg={
                            errors.password && t('The password must be valid.')
                          }
                        />

                        <Input
                          cls="rounded-lg border-input placeholder-gray focus:outline-0"
                          icon={
                            <LockOutlined className="icons-input-size text-[#9E9DA8]" />
                          }
                          placeholder={t('Confirm password')}
                          errorCls="rounded-lg"
                          type="password"
                          refs={register('confirmedPassword')}
                          errorMsg={
                            errors.confirmedPassword &&
                            t('Passwords do not match.')
                          }
                        />
                      </div>

                      <Button
                        label={t('Submit Reset')}
                        cls="bg-blue mt-6 pt-3 pb-3 rounded-md w-full disabled:opacity-40"
                        labelCls="text-white font-medium"
                        isLoading={isLoading}
                        disabled={!isValid || isLoading}
                      />
                    </form>
                  </div>
                </div>
              ) : (
                <Alert
                  severity="error"
                  className="my-8"
                  sx={{ borderRadius: '14px' }}
                >
                  <p className="text-lg">
                    {t('Reset password link is invalid or has expired')}
                  </p>
                  <Link to="/auth/reset-password">
                    <Button
                      label={t('Request a new reset email')}
                      applyDefaultCls={false}
                      applyDefaultLabelCls={false}
                      cls="bg-blue mt-6 py-3 rounded-md w-full"
                      labelCls="text-white"
                    />
                  </Link>
                </Alert>
              )}
            </div>
          </div>
          <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={SITE_KEY} />
        </>
      )}
    </div>
  );
};

export default UpdatePassword;
