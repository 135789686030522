import type { CompanyFormType } from '@/lib/forms';
import type { CompanyInfo } from '@/pages/settings/Enterprise/Enterprise';

import { saasAPI } from './apis';

interface UserCompaniesParams {
  page?: number;
  pageSize?: number;
}

export default class CompanyService {
  static createCompany(data: FormData, signal?: AbortSignal) {
    return saasAPI.post(`/v1/companies`, data, {
      signal,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  static getCompanyInfos(signal?: AbortSignal) {
    return saasAPI.get<CompanyInfo>(`/v1/companies/me`, {
      signal,
    });
  }

  static updateCompanyInfos(
    companyInfo: CompanyFormType,
    signal?: AbortSignal
  ) {
    return saasAPI.put<CompanyFormType>(`/v1/companies/me`, companyInfo, {
      headers: { 'Content-type': 'multipart/form-data' },
      signal,
    });
  }

  static uploadCompanyInvoiceFooter(
    companyId: string,
    data: FormData,
    signal?: AbortSignal
  ) {
    return saasAPI.post(`/v1/companies/${companyId}/footer-invoice`, data, {
      signal,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  static async getCompanySubscription(signal?: AbortSignal) {
    return saasAPI.get(`/v1/subscriptions/me`, {
      signal,
    });
  }

  static async updatePlan(planId: String, signal?: AbortSignal) {
    return saasAPI.put(`/v1/companies/me/planId/${planId}`, {
      signal,
    });
  }

  static getUserCompanies(signal?: AbortSignal) {
    return saasAPI.get(`/v1/users/me/companies`, {
      signal,
    });
  }

  static getUserCompaniesPaginated(
    params: UserCompaniesParams,
    signal?: AbortSignal
  ) {
    return saasAPI.get(`/v1/users/me/companies/paginated`, {
      signal,
      params,
    });
  }

  static switchCompany(companyId: string, signal?: AbortSignal) {
    return saasAPI.post(`/v1/companies/${companyId}/switch`, {
      signal,
    });
  }
}
