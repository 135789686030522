import type { FC } from 'react';
import { useEffect, useState } from 'react';

import { Add, Download, Remove, ZoomOut } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { RotatingLines } from 'react-loader-spinner';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

import { empty } from '@/assets/images/dashboard';
import { Button, getCls } from '@/components/global';
import { useAppTranslation } from '@/hooks';
import { ImageService } from '@/services';

const DEFAULT_CLS = 'h-auto w-auto';
const DEFAULT_CLS_CONTAINER = 'h-auto w-auto flex items-center justify-center';

enum ImageStatus {
  INITIAL = 'INITIAL',
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

interface ImageProps {
  /**
   * @default ""
   */
  url: string;

  /**
   * @default image
   */
  alt: string;

  /**
   * @default ""
   */
  cls?: string;

  /**
   * @default ""
   */
  clsContainer?: string;

  /**
   * @default true
   */
  applyDefaultCls?: boolean;

  /**
   * @default true
   */
  applyDefaultClsContainer?: boolean;
  /**
   * @default false
   */
  fullscreen?: boolean;
  /**
   * @default false
   */
  downloadable?: boolean;
  /**
   * @default () => {}
   */
  actionOnDownload?: () => void;
}

/**
 *
 * @param  {string} url image url
 * @param {string} cls image className for custom styling
 * @param {string} alt image alt
 * @param {number} fullscreen image fullscreen
 * @param {boolean} downloadable image downloadable
 * @param {() => void} actionOnDownload action on download
 * @example <Image url="image.png" cls="image-style" alt="avatar" downloadable={true} actionOnDownload={()=> { /*some code*\ }} fullscreen={true}/>
 *
 */
const ImageReceipt: FC<ImageProps> = ({
  url,
  cls = '',
  clsContainer = '',
  alt = 'image',
  applyDefaultCls = true,
  applyDefaultClsContainer = true,
  fullscreen = false,
  downloadable = false,
  actionOnDownload = () => {},
}) => {
  const { t } = useAppTranslation();
  const [imageStatus, setImageStatus] = useState(ImageStatus.INITIAL);
  const [image, setImage] = useState('');

  cls = getCls(applyDefaultCls, DEFAULT_CLS, cls);
  clsContainer = getCls(
    applyDefaultClsContainer,
    DEFAULT_CLS_CONTAINER,
    clsContainer
  );

  const getImageResource = () => {
    setImageStatus(ImageStatus.IN_PROGRESS);
    ImageService.getImage(url)
      .then((res) => {
        const imageURL = URL.createObjectURL(res.data);

        setImage(imageURL);

        setImageStatus(ImageStatus.SUCCESS);
      })
      .catch(() => {
        setImageStatus(ImageStatus.ERROR);
      });
  };

  const renderSwitch = () => {
    switch (imageStatus) {
      case 'ERROR':
        return (
          <div className="w-full h-full flex items-center justify-center flex-col py-16">
            <img alt="Empty" src={empty} />
            <div className="text-center w-full h-[20vh] flex flex-col gap-2">
              <span className="text-slate-800 text-[25px] font-bold">
                {t('No image available')}
              </span>

              <div className="flex items-center justify-center">
                <p className="text-slate-500 max-w-[500px]">
                  {t('error msg image')}
                </p>
              </div>
            </div>
          </div>
        );

      case 'IN_PROGRESS':
        return (
          <div className="w-full min-w-[800px] h-[60vh] flex items-center justify-center flex-col py-16">
            <RotatingLines visible={true} strokeColor="grey" width="30" />
          </div>
        );

      case 'SUCCESS':
        return fullscreen ? (
          <TransformWrapper initialScale={1}>
            {({ zoomIn, zoomOut, resetTransform }) => (
              <div>
                <TransformComponent>
                  <div>
                    <img src={image} className={cls} alt={alt} />;
                  </div>
                </TransformComponent>
                <div className="relative w-full">
                  <div className="flex justify-around absolute bottom-10 right-0 m-4 items-center w-[20vh] justify-center bg-opacity-50 bg-black rounded-2xl p-2">
                    <div className="w-8 h-8 flex items-center justify-center rounded cursor-pointer ">
                      <IconButton onClick={() => zoomOut()}>
                        <Remove
                          fontSize="large"
                          className="text-gray-200 hover:text-white transform scale-100 hover:scale-110 transition-transform duration-300"
                        />
                      </IconButton>
                    </div>
                    <div className="w-8 h-8 flex items-center justify-center rounded cursor-pointer ">
                      <IconButton onClick={() => resetTransform()}>
                        <ZoomOut
                          fontSize="large"
                          className="text-gray-200 hover:text-white transform scale-100 hover:scale-110 transition-transform duration-300"
                        />
                      </IconButton>
                    </div>
                    <div className="w-8 h-8 flex items-center justify-center rounded cursor-pointer">
                      <IconButton onClick={() => zoomIn()}>
                        <Add
                          fontSize="large"
                          className="text-gray-200 hover:text-white transform scale-100 hover:scale-110 transition-transform duration-300"
                        />
                      </IconButton>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </TransformWrapper>
        ) : (
          <img src={image} className={cls} alt={alt} />
        );

      default:
        return <></>;
    }
  };

  useEffect(() => {
    getImageResource();
  }, [url]);

  return (
    <>
      {downloadable && (
        <div className="">
          <Button
            onClick={actionOnDownload}
            cls="bg-primary text-white absolute top-28 right-0 z-10 w-10 h-10 m-4 bg-opacity-50 bg-black"
            icon={<Download />}
          />
        </div>
      )}
      <div className={clsContainer}>{renderSwitch()}</div>
    </>
  );
};

export default ImageReceipt;
