import type { FC } from 'react';
import React from 'react';

import { Close } from '@mui/icons-material';
import { Dialog } from '@mui/material';

import { Button } from '@/components/global';
import { useAppTranslation } from '@/hooks';

const MODAL_TEMPLATE = {
  danger: {
    bgColor: '#d9534f',
  },
  info: {
    bgColor: '#5d5ff8',
  },
};

interface ConfirmationModalProps {
  /**
   * @default false
   */
  isOpen: boolean;

  /**
   * @default '''
   */
  onClose: Function;

  /**
   * @default '''
   */
  action?: Function;

  /**
   * @default '''
   */
  actionName?: string;

  /**
   * @default '''
   */
  title: string;

  /**
   * @default '''
   */
  message: string;

  /**
   * @default danger
   */
  sensitivity?: 'danger' | 'info';

  /**
   * @default false
   */
  isLoading?: boolean;

  /**
   * @default false
   */
  disabled?: boolean;
}

/**
 *
 * @param {boolean} isOpen display and hide the modal
 * @param {Function} onClose responsible function to close the modal
 * @param {Function} action primary action
 * @param {string} title Title of the modal
 * @param {string} message message of the modal
 * @param {string} sensitivity modal sensitivity
 * @param {boolean} isLoading if the action require time to complete
 * @param {boolean} disabled disable the action
 * @returns
 */
const ConfirmationModal: FC<ConfirmationModalProps> = ({
  isOpen = false,
  sensitivity = 'danger',
  disabled = false,
  isLoading = false,
  onClose,
  action,
  title,
  message,
  actionName,
}) => {
  const { t } = useAppTranslation();

  return (
    <Dialog
      open={isOpen}
      onClose={(e) => {
        onClose(e);
      }}
      PaperProps={{
        style: { borderRadius: 4 },
      }}
    >
      <div
        className="p-4 flex flex-col gap-4 max-w-[500px] border-1 border-[#DEE2E6]"
        onClick={(e) => {
          e.cancelable = true;
          e.stopPropagation();
        }}
      >
        <div
          onClick={(e) => {
            onClose(e);
          }}
          className="flex justify-end cursor-pointer"
        >
          <Close fontSize="small" />
        </div>

        <div className="flex flex-col gap-4 mb-3">
          <h3 className="font-semibold text-xl">{title}</h3>
          <p className="text-[#989b9e]">{message}</p>
        </div>

        <div className="flex w-full justify-end gap-3 mt-4">
          <Button
            onClick={(e) => {
              onClose(e);
            }}
            label={t('Close')}
            sensitivity="Faided"
          />
          {actionName && action && (
            <Button
              onClick={(e) => {
                action(e);
              }}
              label={actionName}
              isLoading={isLoading}
              disabled={disabled}
              style={{
                backgroundColor: MODAL_TEMPLATE[sensitivity].bgColor,
              }}
              sensitivity={
                sensitivity === 'danger' ? 'Destructive' : 'Secondary'
              }
            />
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default ConfirmationModal;
