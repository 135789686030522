import * as React from 'react';
import { useEffect, useState } from 'react';

import { Add, DomainOutlined, GroupOutlined } from '@mui/icons-material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BlockIcon from '@mui/icons-material/Block';
import CreateIcon from '@mui/icons-material/Create';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import { Box, FormControl, MenuItem, Select } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useNavigate } from 'react-router-dom';

import { companyAvatar } from '@/assets/images';
import {
  Button,
  ConfirmationModal,
  Image,
  Pagination,
  Tooltip,
} from '@/components/global';
import { useAppTranslation } from '@/hooks';
import { appToast } from '@/lib/ToastContainers';
import { CompanyService } from '@/services';
import DeclarationService from '@/services/DeclarationService';
import { formatBalance } from '@/utils/funcs';

import DeclarationDetails from './DeclarationDetails/DeclarationDetails';
import { StyledTableCell, StyledTableRow } from './common/styledTableElements';
import { DeclarationStatusEnum, isLate } from './common/utils';

export type DeclarationTemplate = {
  id: string;
  name: string;
  dueDate: string;
  year: number;
  isPayable: boolean;
};

export type Company = {
  name: string;
  logoUrl: string;
  taxId: string;
  id: string;
  legalStatus: string;
};

export type DeclarationSubmit = {
  id: string;
  companyId: string;
  createdBy: string;
  createdAt: string;
  amount: number;
  attachments: { id: string; filename: string }[];
  status: string;
  declaredBy: string;
  declaredAt: string;
  declaredWhen: string;
  payedBy: string;
  payedAt: string;
  payedWhen: string;
  template: DeclarationTemplate;
  transactionId: string;
  paymentType: string;
  note: string;
};

export type AccountantDeclarationsSubmit = {
  name: string;
  logoUrl: string;
  taxId: string;
  id: string;
  declarations: DeclarationSubmit[];
};

const PAGE_SIZE = 5;

const Declaration = () => {
  const navigate = useNavigate();
  const { t } = useAppTranslation();

  //Declaration table states
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [fiscalYear, setFiscalYear] = useState<number>(
    new Date().getFullYear()
  );
  const [company, setCompany] = useState<Company | null>(null);
  const [userCompanies, setUserCompanies] = useState<Company[]>([]);
  const [declarationTemplate, setDeclarationTemplate] =
    useState<DeclarationTemplate | null>(null);
  const [declarationsTemplatesList, setDeclarationsTemplatesList] = useState<
    DeclarationTemplate[]
  >([]);
  const [declarationSubmit, setDeclarationSubmit] =
    useState<DeclarationSubmit | null>(null);
  const [accountantDeclarationsList, setAccountantDeclarationsList] = useState<
    AccountantDeclarationsSubmit[]
  >([]);
  const [isDeclarationSubmitUpdated, setIsDeclarationSubmitUpdated] =
    useState<boolean>(false);

  const [totalPages, setTotalPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [isUncancelModalOpen, setIsUncancelModalOpen] =
    useState<boolean>(false);

  //Declaration table functions

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    declarationTemplate: DeclarationTemplate,
    company: Company,
    declaration: any,
    isSubmitExists: boolean
  ) => {
    if (!isSubmitExists) {
      createDeclaration(company.id, declarationTemplate.id);
    } else {
      setDeclarationSubmit(declaration);
    }

    setAnchorEl(event.currentTarget);
    setDeclarationTemplate(declarationTemplate);
    setCompany(company);
  };

  const getDeclarationTemplatesByYear = () => {
    DeclarationService.getDeclarationsTemplatesByYear(fiscalYear).then(
      (res) => {
        setDeclarationsTemplatesList(res.data);
      }
    );
  };

  const getDeclarationSubmitsList = () => {
    DeclarationService.getAccountantsCompaniesDeclarations(fiscalYear).then(
      (res) => {
        if (res) setAccountantDeclarationsList(res?.data);
      }
    );
  };

  const createDeclaration = (companyId: string, templateId: string) => {
    return DeclarationService.createDeclaration(companyId, templateId)
      .then((res) => {
        setDeclarationSubmit(res?.data);
      })
      .catch((e) => {
        appToast.error(t(e.response.data.code));
      });
  };

  const getDeclarationSubmitByCompanyIdAndTemplateId = (
    companyId: string,
    templateId: string
  ) => {
    return accountantDeclarationsList
      ?.find((declarationSubmit) => declarationSubmit.id === companyId)
      ?.declarations.find(
        (declaration) => declaration.template.id === templateId
      );
  };

  const getCompaniesListPaginated = (page: number) => {
    CompanyService.getUserCompaniesPaginated({
      page,
      pageSize: PAGE_SIZE,
    }).then(({ data }) => {
      setUserCompanies(data.companies);
      setTotalPages(
        data.totalCompaniesNumber % PAGE_SIZE === 0
          ? data.totalCompaniesNumber / PAGE_SIZE
          : data.totalCompaniesNumber / PAGE_SIZE + 1
      );
    });
  };

  const unCancelDeclaration = (submitId: string) => {
    DeclarationService.unCancelDeclaration(submitId).then(() => {
      setIsUncancelModalOpen(!isUncancelModalOpen);
      setIsDeclarationSubmitUpdated(!isDeclarationSubmitUpdated);
    });
  };

  //Declaration table async handling
  useEffect(() => {
    getDeclarationTemplatesByYear();
    getDeclarationSubmitsList();
    getCompaniesListPaginated(pageNumber);
  }, [anchorEl, isDeclarationSubmitUpdated, fiscalYear, pageNumber]);

  return (
    <section
      className="mt-[81px] p-6 flex flex-col gap-4 border-1 border-solid rounded-md bg-red w-full h-[calc(100vh-81px)] 
            overflow-y-scroll"
    >
      <ConfirmationModal
        title={t('Uncancel declaration')}
        message={`${t(
          'You are in the process of uncanceling this declaration'
        )} : ${declarationSubmit?.template?.name ?? ''} ${
          t('for the entreprise ') + company?.name
        } `}
        isOpen={isUncancelModalOpen}
        action={() => {
          unCancelDeclaration(declarationSubmit?.id ?? '');
        }}
        actionName={t('Uncancel')}
        onClose={() => {
          setIsUncancelModalOpen(!isUncancelModalOpen);
        }}
        sensitivity="info"
      />

      <DeclarationDetails
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        declarationSubmit={declarationSubmit}
        setDeclarationSubmit={setDeclarationSubmit}
        declarationTemplate={declarationTemplate}
        company={company}
        setIsDeclarationSubmitUpdated={setIsDeclarationSubmitUpdated}
        isDeclarationSubmitUpdated={isDeclarationSubmitUpdated}
      ></DeclarationDetails>
      <div className="flex justify-between items-center !h-[60px] mt-[6px] mb-3">
        <h1 className="text-2xl font-bold">{t('Declarations')}</h1>
        <div className="flex justify-between items-center !h-[60px] mt-[6px]">
          <Button
            label={t('Add a new company')}
            icon={<Add htmlColor="white" className="-translate-y-[1px] ml-1" />}
            iconPosition="left"
            onClick={() => navigate('/wizard/company-info')}
          />
        </div>
      </div>
      <div className="bg-first px-12 py-10 rounded-3xl flex flex-col  mb-4">
        <div className="w-full flex justify-end mb-5">
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={fiscalYear}
                sx={{
                  borderRadius: 5,
                  height: 45,
                }}
                onChange={(e) => setFiscalYear(e.target.value as number)}
              >
                <MenuItem value={2023}>2023</MenuItem>
                <MenuItem value={2024}>2024</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </div>
        <div className="flex justify-end mb-5">
          <div className="flex justify-end mb-4 h-8">
            <div className="bg-[#3B719F] w-fit mx-1 py-2 px-4 rounded-full text-white text-xs font-bold">
              {t('DRAFT')}
            </div>
            <div className="bg-[#ef9b0f] w-fit py-2 px-4 mx-1  rounded-full text-white text-xs font-bold">
              {t('DECLARED')}
            </div>
            <div className="bg-[#ef9b0f] w-fit py-2 px-4 mx-1  rounded-full text-white text-xs font-bold flex gap-1 items-center">
              <div className="p-2 bg-[#df4759] rounded-full border border-white"></div>
              {t('DECLARED_OUTDATED')}
            </div>
            <div className="bg-[#2B9F93] w-fit py-2 px-4 mx-1  rounded-full text-white text-xs font-bold">
              {t('PAYED')}
            </div>
            <div className="bg-[#2B9F93] w-fit py-2 px-4 mx-1  rounded-full text-white text-xs font-bold flex gap-1 items-center">
              <div className="p-2 bg-[#df4759] rounded-full border border-white"></div>
              {t('PAYED_OUTDATED')}
            </div>
          </div>
        </div>

        <div className="max-w-[1700px] max-h-[500px] overflow-y-scroll  pb-1">
          <TableContainer component={Paper} style={{ overflowX: 'initial' }}>
            <Table
              sx={{ maxWidth: 500, maxHeight: 440 }}
              aria-label="simple table"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <div className="flex justify-around sticky left-0 z-50">
                    <TableCell className="w-[320px] max-w-xs" align="center">
                      <div className="flex flex-col items-center justify-center">
                        <span className="text-sm font-bold text-[#8D98AF]">
                          {t('Company')}
                        </span>
                        <span>&nbsp;</span>
                      </div>
                    </TableCell>
                    <TableCell align="right">
                      <span className="text-sm font-bold text-[#8D98AF]">
                        {t('Type')}
                      </span>
                    </TableCell>
                  </div>

                  {!!declarationsTemplatesList?.length &&
                    declarationsTemplatesList?.map((dc, index) => {
                      return (
                        <TableCell
                          key={index}
                          component="th"
                          scope="row"
                          align="center"
                        >
                          <div className="flex flex-col items-center justify-center">
                            <span className="font-bold text-sm text-[#8D98AF]">
                              <div className="flex flex-row max-w-[200px]">
                                <Tooltip message={dc.name} position="top">
                                  <span className="truncate">{dc.name}</span>
                                </Tooltip>
                                {dc.isPayable ? (
                                  <Tooltip
                                    message={t('must be payed')}
                                    position="top"
                                  >
                                    <AttachMoneyIcon
                                      className="hover:scale-110"
                                      style={{ height: 20 }}
                                    />
                                  </Tooltip>
                                ) : (
                                  <Tooltip
                                    message={t('not payed')}
                                    position="top"
                                  >
                                    <MoneyOffIcon
                                      className="hover:scale-110"
                                      style={{ height: 20 }}
                                    />
                                  </Tooltip>
                                )}
                              </div>
                            </span>
                            <Tooltip message={t('due date')} position="top">
                              <span className="text-xs font-slim text-sm text-[#8D98AF]">
                                {dc.dueDate}
                              </span>
                            </Tooltip>
                          </div>
                        </TableCell>
                      );
                    })}
                </TableRow>
              </TableHead>
              <TableBody>
                {!!userCompanies?.length &&
                  userCompanies?.map((company, index) => (
                    <StyledTableRow key={index}>
                      <div className="  sticky left-0 z-50 flex justify-center">
                        <StyledTableCell
                          component="th"
                          scope="row"
                          className={`${
                            !(index % 2) ? 'bg-[#F5F5F5]' : 'bg-white'
                          }  truncate font-bold sticky left-0 z-50 w-[320px] max-w-xs`}
                          align="center"
                        >
                          <div className="flex flex-row items-center gap-1">
                            {company.logoUrl ? (
                              <Image
                                cls="w-[40px] object-cover"
                                url={company.logoUrl}
                                alt="company logo"
                              />
                            ) : (
                              <img
                                className="w-[40px] object-cover"
                                alt="company logo"
                                src={companyAvatar}
                              />
                            )}
                            <Tooltip message={company.name} position="top">
                              <span className="font-bold font-bold text-sm text-[#8D98AF]">
                                {company.name}
                              </span>
                            </Tooltip>
                          </div>
                        </StyledTableCell>
                        <StyledTableCell
                          component="th"
                          scope="row"
                          className={`${
                            !(index % 2) ? 'bg-[#F5F5F5]' : 'bg-white'
                          }   truncate font-bold`}
                        >
                          <div className="mt-2">
                            {company?.legalStatus ? (
                              <Tooltip
                                message={t('Legal entity')}
                                position="bottom"
                              >
                                <DomainOutlined className="h-2" />
                              </Tooltip>
                            ) : (
                              <Tooltip
                                message={t('Individuals')}
                                position="bottom"
                              >
                                <GroupOutlined />
                              </Tooltip>
                            )}
                          </div>
                        </StyledTableCell>
                      </div>
                      {!!declarationsTemplatesList?.length &&
                        declarationsTemplatesList?.map(
                          (declarationTemplate) => (
                            <StyledTableCell
                              key={declarationTemplate.id}
                              component="th"
                              scope="row"
                              align="justify"
                            >
                              {getDeclarationSubmitByCompanyIdAndTemplateId(
                                company.id,
                                declarationTemplate.id
                              )?.id ? (
                                <button
                                  className="flex justify-center items-center w-full"
                                  onClick={(e) =>
                                    handleClick(
                                      e,
                                      declarationTemplate,
                                      company,
                                      getDeclarationSubmitByCompanyIdAndTemplateId(
                                        company.id,
                                        declarationTemplate.id
                                      ),
                                      true
                                    )
                                  }
                                >
                                  {getDeclarationSubmitByCompanyIdAndTemplateId(
                                    company.id,
                                    declarationTemplate.id
                                  )?.status ===
                                    DeclarationStatusEnum.DECLARED && (
                                    <div className="bg-[#ef9b0f] py-2 px-4 rounded-full w-fit min-w-[50px]  text-white text-xs text-center font-bold flex gap-1">
                                      {isLate(
                                        getDeclarationSubmitByCompanyIdAndTemplateId(
                                          company.id,
                                          declarationTemplate.id
                                        )?.declaredWhen,
                                        declarationTemplate.dueDate
                                      ) && (
                                        <div className="p-2 bg-[#df4759] rounded-full border border-white"></div>
                                      )}
                                      {getDeclarationSubmitByCompanyIdAndTemplateId(
                                        company.id,
                                        declarationTemplate.id
                                      )?.template?.isPayable
                                        ? formatBalance(
                                            getDeclarationSubmitByCompanyIdAndTemplateId(
                                              company.id,
                                              declarationTemplate.id
                                            )?.amount
                                          )
                                        : t('DECLARED')}
                                    </div>
                                  )}
                                  {getDeclarationSubmitByCompanyIdAndTemplateId(
                                    company.id,
                                    declarationTemplate.id
                                  )?.status === DeclarationStatusEnum.PAYED && (
                                    <div className="bg-[#2B9F93] w-fit min-w-[50px]  py-2 px-4 rounded-full text-white text-xs font-bold flex gap-1">
                                      {isLate(
                                        getDeclarationSubmitByCompanyIdAndTemplateId(
                                          company.id,
                                          declarationTemplate.id
                                        )?.payedWhen,
                                        declarationTemplate.dueDate
                                      ) && (
                                        <div className="p-2 bg-[#df4759] rounded-full border border-white"></div>
                                      )}
                                      {getDeclarationSubmitByCompanyIdAndTemplateId(
                                        company.id,
                                        declarationTemplate.id
                                      )?.template?.isPayable &&
                                        formatBalance(
                                          getDeclarationSubmitByCompanyIdAndTemplateId(
                                            company.id,
                                            declarationTemplate.id
                                          )?.amount
                                        )}
                                    </div>
                                  )}

                                  {(!getDeclarationSubmitByCompanyIdAndTemplateId(
                                    company.id,
                                    declarationTemplate.id
                                  )?.status ||
                                    getDeclarationSubmitByCompanyIdAndTemplateId(
                                      company.id,
                                      declarationTemplate.id
                                    )?.status ===
                                      DeclarationStatusEnum.INITIAL) && (
                                    <div>
                                      {getDeclarationSubmitByCompanyIdAndTemplateId(
                                        company.id,
                                        declarationTemplate.id
                                      )?.amount ? (
                                        <div className="bg-[#3B719F] py-2 px-4 rounded-full w-fit min-w-[50px]   text-white text-xs font-bold">
                                          {formatBalance(
                                            getDeclarationSubmitByCompanyIdAndTemplateId(
                                              company.id,
                                              declarationTemplate.id
                                            )?.amount
                                          )}
                                        </div>
                                      ) : (
                                        <CreateIcon className="text-[#6c757d] hover:scale-110" />
                                      )}
                                    </div>
                                  )}
                                </button>
                              ) : (
                                <button
                                  onClick={(e) =>
                                    handleClick(
                                      e,
                                      declarationTemplate,
                                      company,
                                      {},
                                      false
                                    )
                                  }
                                  className="flex justify-center items-center cursor-pointer bg-red w-full"
                                >
                                  <CreateIcon className="text-[#6c757d]  hover:scale-110" />
                                </button>
                              )}
                              <button
                                className="flex justify-center items-center w-full"
                                onClick={() => {
                                  setIsUncancelModalOpen(!isUncancelModalOpen);
                                  setCompany(company);
                                  setDeclarationSubmit(
                                    getDeclarationSubmitByCompanyIdAndTemplateId(
                                      company.id,
                                      declarationTemplate.id
                                    ) ?? null
                                  );
                                }}
                              >
                                {getDeclarationSubmitByCompanyIdAndTemplateId(
                                  company.id,
                                  declarationTemplate.id
                                )?.status ===
                                  DeclarationStatusEnum.CANCELLED && (
                                  <Tooltip
                                    message={t('declaration canceled')}
                                    position="top"
                                  >
                                    <div className="w-fit min-w-[50px]  py-2 px-4 rounded-full text-white text-xs font-bold flex gap-1 text-center">
                                      <BlockIcon className="text-[#df4759] hover:scale-110" />
                                    </div>
                                  </Tooltip>
                                )}
                              </button>
                            </StyledTableCell>
                          )
                        )}
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className="px-10">
          <Pagination
            currentPage={pageNumber + 1}
            totalPages={totalPages}
            isPreloadState={false}
            onPageChange={(page: number) => {
              setPageNumber(page - 1);
            }}
          />
        </div>
      </div>
    </section>
  );
};

export default Declaration;
