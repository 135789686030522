import type { PlanInfo } from '@/pages/settings/Subscription/Subscription';

import type { CompanySubscription, WebSocketNotification } from '.';
import _ from '../actions/action-types';

interface IState {
  isPreloadState: boolean;
  connectedUser: any;
  company: any;
  companySubscription: CompanySubscription | null;
  isCompanyLoading: boolean;
  notifications: WebSocketNotification[];
  validatedReceiptNumber: number | null;
  validatedTransactionNumber: number | null;
  unreadChatNumber: number | null;
  planInfo: PlanInfo;
  credits: number;
  fiscalYear: string | null;
}

const initialState: IState = {
  isPreloadState: true,
  connectedUser: null,
  company: null,
  companySubscription: null,
  isCompanyLoading: false,
  notifications: [],
  validatedReceiptNumber: null,
  validatedTransactionNumber: null,
  unreadChatNumber: null,
  planInfo: null as unknown as PlanInfo,
  credits: 0,
  fiscalYear:
    localStorage.getItem('fiscalYear') ?? new Date().getFullYear().toString(),
};

interface IAction {
  type: string;
  payload: any;
}

const globalReducer = (state: IState = initialState, action: IAction) => {
  const { type, payload } = action;

  switch (type) {
    //? User reducer
    case _.SET_IS_PRELOAD_STATE:
      return { ...state, isPreloadState: payload };

    case _.SET_CONNECTED_USER:
      return { ...state, connectedUser: payload };
    // ========================================================= //

    //? Company reducer
    case _.SET_COMPANY_INFO:
      return { ...state, company: payload };

    case _.SET_IS_LOADING_COMPANY_INFO:
      return { ...state, isCompanyLoading: payload };

    case _.SET_COMPANY_SUBSCRIPTION:
      return { ...state, companySubscription: payload };
    // ========================================================= //

    // ? notifications reducer
    case _.SET_NOTIFICATIONS:
      return { ...state, notifications: payload };
    // ========================================================

    // ? Sidebar reducer

    case _.SET_KPI_VALIDATION_RECEIPT:
      return { ...state, validatedReceiptNumber: payload };

    case _.SET_KPI_VALIDATION_TRANSACTION:
      return { ...state, validatedTransactionNumber: payload };

    case _.SET_UNREAD_CHATS_COUNTER:
      return { ...state, unreadChatNumber: payload };

    // ========================================================

    case _.SET_PLAN_INFO:
      return { ...state, planInfo: payload };

    // ========================================================

    case _.SET_CREDIT_COUNTER:
      return { ...state, credits: payload };

    // ========================================================

    case _.SET_FISCAl_YEAR:
      return { ...state, fiscalYear: payload };

    default:
      return state;
  }
};

export default globalReducer;
