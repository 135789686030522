import { useEffect, useMemo, useState } from 'react';

import { useAppSelector } from './store-hooks';

const SOCKET_URL =
  window.ENV[process.env.NODE_ENV || 'development'].wsNotificationUrl;

export const useWebSocket = (
  customParam?: string
): [WebSocket | null, boolean] => {
  const [socket, setSocket] = useState<WebSocket | null>(null);
  const [connected, setConnected] = useState(false);

  const connectedUser = useAppSelector((store) => store.global.connectedUser);
  const webSocketUrl = useMemo(() => {
    if (customParam == 'CHAT' && connectedUser?.companyId) {
      return `${SOCKET_URL}/${connectedUser.companyId}/mention/${connectedUser.id}`;
    } else if (connectedUser?.id && connectedUser?.companyId) {
      return `${SOCKET_URL}/${connectedUser.id}/${connectedUser.companyId}`;
    } else {
      return null;
    }
  }, [connectedUser, customParam]);

  useEffect(() => {
    if (webSocketUrl) {
      const socket = new WebSocket(webSocketUrl);
      setSocket(socket);

      socket.onopen = () => {
        setConnected(true);
      };

      socket.onclose = () => {
        setConnected(false);
      };

      return () => {
        socket.close();
      };
    }
  }, [webSocketUrl]);

  return [socket, connected];
};
