import { lazy } from 'react';

import { Navigate, Route } from 'react-router-dom';

import { AccountingLayout } from '@/components/layouts';
import { AuthGuard } from '@/lib/guards';
import Declaration from '@/pages/accounting/Declaration/Declaration';

import type { RouteType } from '.';

const Dashboard = lazy(() => {
  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(import('@/pages/accounting/Dashboard')), 500);
  });
});

const outletElements = [
  {
    element: <Navigate to="dashboard" />,
    href: '',
  },
  {
    element: <Dashboard />,
    href: 'dashboard',
  },
  {
    element: <Declaration />,
    href: 'declaration',
  },
];

const routes: RouteType[] = [
  {
    children: (
      <>
        {outletElements.map(({ element, href }) => (
          <Route key={href} element={element} path={href} />
        ))}
      </>
    ),
    element: (
      <AuthGuard>
        <AccountingLayout />
      </AuthGuard>
    ),
    path: 'accounting',
  },
];

export default routes;
