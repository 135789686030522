import { UveyLogo } from '..';

const Logo = () => {
  return (
    <div className="p-2">
      <img className="h-[18px] " src={UveyLogo} alt="Uvey logo" />
    </div>
  );
};

export default Logo;
