import * as yup from 'yup';

export const addCategoryForm = yup.object().shape({
  label: yup.string().required(),
  code: yup.string().required().max(8),
  id: yup.string().optional(),
  friendlyLabel: yup.string().optional(),
});
export const updateCategoryForm = yup.object().shape({
  label: yup.string().required(),
  code: yup.string().required().max(8),
  id: yup.string().optional(),
  friendlyLabel: yup.string().required(),
});

export const ventilationForm = yup.object().shape({
  category: yup.string().required(),
  amount: yup.number().required(),
  currency: yup.string(),
  vatAmount: yup.number(),
  vatRate: yup.number(),
});
