import { ChevronLeftRounded, ChevronRightRounded } from '@mui/icons-material';

import { useAppDispatch } from '@/hooks';
import { setIsPreloadState } from '@/store/actions/action-creators';

interface PaginationProps {
  /**
   * @default 1
   */
  currentPage: number;
  /**
   * @default 5
   */
  totalPages: number;
  /**
   * @param {number} page - The page number
   * @returns {void}
   */
  onPageChange: (page: number) => void;
  /**
   * @default true
   */
  isPreloadState: boolean;
}

/**
 *
 * @param  {number} currentPage pagination current page
 * @param {number} totalPages the total pages in the pagination component
 * @param {boolean} preloadState param to display the preload state of the full page
 */

const Pagination: React.FC<PaginationProps> = ({
  currentPage = 1,
  totalPages = 5,
  onPageChange,
  isPreloadState,
}) => {
  const dispatch = useAppDispatch();
  const handlePageChange = (page: number) => {
    if (currentPage !== 1 && currentPage !== totalPages) {
      isPreloadState && dispatch(setIsPreloadState(true));
    }

    if (page < 1 || page > totalPages) {
      return;
    }
    onPageChange(page);
  };

  return (
    <div className="flex justify-end gap-1 mt-6">
      <button
        className={`text-[#8D98AF] min-w-[42px] p-2 bg-sec rounded flex items-center justify-center ${
          currentPage === 1
            ? 'opacity-50 cursor-not-allowed'
            : 'hover:bg-gray-100 transition-colors duration-200 cursor-pointer'
        }`}
        onClick={() => handlePageChange(currentPage - 1)}
      >
        <ChevronLeftRounded />
      </button>
      {Array.from({ length: totalPages }, (_, i) => i + 1).map((pageNumber) => (
        <button
          key={pageNumber}
          className={`${
            currentPage === pageNumber
              ? 'text-white bg-[#0075E7]'
              : 'text-[#8D98AF] bg-sec hover:bg-gray-100 transition-colors duration-200 cursor-pointer'
          } min-w-[42px] text-sm font-bold p-2 rounded flex items-center justify-center`}
          onClick={() =>
            currentPage !== pageNumber && handlePageChange(pageNumber)
          }
        >
          {pageNumber}
        </button>
      ))}
      <button
        className={`text-[#8D98AF] min-w-[42px] p-2 bg-sec flex items-center justify-center rounded ${
          currentPage === totalPages
            ? 'opacity-50 cursor-not-allowed'
            : 'hover:bg-gray-100 transition-colors duration-200 cursor-pointer'
        }`}
        onClick={() => handlePageChange(currentPage + 1)}
      >
        <ChevronRightRounded />
      </button>
    </div>
  );
};

export default Pagination;
