import React, { type FC, type ReactNode } from 'react';

import { type IconPosition, getCls } from '..';

const DEFAULT_CLS =
  'bg-[#EBEEF1] border-[1px] border-[#D5DBE1] rounded-[4px] px-3 py-[2px] flex item-center justify-center w-fit';
const DEFAULT_LABEL_CLS = 'text-xs font-bold text-[#6A7383]';

const DEFAULT_STYLES = {
  default: {
    bg: 'bg-[#EBEEF1] border-[1px] border-[#D5DBE1] rounded-[4px] px-3 py-[2px] flex item-center justify-center w-fit',
    label: 'text-xs font-bold text-[#6A7383]',
  },
  info: {
    bg: 'bg-[#CFF5F6] border-[1px] border-[#A2E5EF] rounded-[4px] px-3 py-[2px] flex item-center justify-center w-fit',
    label: 'text-xs font-bold text-[#0055BC]',
  },
  positive: {
    bg: 'bg-[#D7F7C2] border-[1px] border-[#A6EB84] rounded-[4px] px-3 py-[2px] flex item-center justify-center w-fit',
    label: 'text-xs font-bold text-[#006908]',
  },
  warning: {
    bg: 'bg-[#FCEDB9] border-[1px] border-[#FCD579] rounded-[4px] px-3 py-[2px] flex item-center justify-center w-fit',
    label: 'text-xs font-bold text-[#A82C00]',
  },
  negative: {
    bg: 'bg-[#FFE7F2] border-[1px] border-[#FFCCDF] rounded-[4px] px-3 py-[2px] flex item-center justify-center w-fit',
    label: 'text-xs font-bold text-[#B3093C]',
  },
  urgent: {
    bg: 'bg-[#DF1B41] border-[1px] border-[#DF1B41] rounded-[4px] px-3 py-[2px] flex item-center justify-center w-fit',
    label: 'text-xs font-bold text-[#ffff]',
  },
};

interface BadgeProps {
  /**
   * @default 'default'
   */
  sensitivity?:
    | 'default'
    | 'info'
    | 'positive'
    | 'warning'
    | 'negative'
    | 'urgent'
    | string;

  /**
   * @default ''
   */
  cls?: string;

  /**
   * @default ''
   */
  label?: string | ReactNode /* | DefaultTFuncReturn */;

  /**
   * @default ''
   */
  labelCls?: string;

  /**
   * @default true
   */
  applyDefaultCls?: boolean;

  /**
   * @default true
   */
  applyDefaultLabelCls?: boolean;

  /**
   * @default null
   */
  icon?: ReactNode;

  /**
   * @default 'left'
   */
  iconPosition?: IconPosition;
}

/**
 * Global Badge Component
 * @param {string} sensitivity badge sensitivity
 * @param {string} label label text
 * @param {string} cls Badge class
 * @param {string} labelCls label class
 * @param {boolean} applyDefaultCls whether to apply the default Badge class
 * @param {boolean} applyDefaultLabelCls whether to apply the default label class
 * @param {ReactNode} icon icon component
 * @param {IconPosition} iconPosition icon position
 * @param {any[]} ...props list of props to pass to the native Badge element
 * @example <Badge sensitivity ="info" label="Info" icon={<InfoIcon />} cls="bg-gray-200" />
 */

const Badge: FC<BadgeProps> = ({
  sensitivity = null,
  cls = '',
  label = '',
  labelCls = '',
  applyDefaultCls = true,
  applyDefaultLabelCls = true,
  icon = null,
  iconPosition = 'left',
  ...props
}) => {
  cls = sensitivity
    ? DEFAULT_STYLES[sensitivity as keyof typeof DEFAULT_STYLES].bg
    : getCls(applyDefaultCls, DEFAULT_CLS, cls);

  labelCls = sensitivity
    ? DEFAULT_STYLES[sensitivity as keyof typeof DEFAULT_STYLES].label
    : getCls(applyDefaultLabelCls, DEFAULT_LABEL_CLS, labelCls);

  return (
    <div {...props} className={cls}>
      {iconPosition === 'left' && icon}
      <p className={labelCls}>{label}</p>
      {iconPosition === 'left' && icon}
    </div>
  );
};

export default Badge;
