import type { FC } from 'react';

import {
  DashboardOutlined,
  ExitToAppOutlined,
  Percent,
  SettingsOutlined,
} from '@mui/icons-material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useNavigate } from 'react-router-dom';

import { userAvatar } from '@/assets/images';
import { Image } from '@/components/global';
import {
  useAppComponentVisible,
  useAppDispatch,
  useAppSelector,
  useAppTranslation,
} from '@/hooks';
import {
  setCompanySubscription,
  setConnectedUser,
} from '@/store/actions/action-creators';
import { cls } from '@/utils/funcs';

import styles from '../Dashboard/Dashboard.module.scss';

interface ProfileProps {}

const Profile: FC<ProfileProps> = () => {
  const { t } = useAppTranslation();
  const {
    ref: menuRef,
    isVisible: isMenuOpen,
    setIsVisible: setIsMenuOpen,
  } = useAppComponentVisible(false);

  const connectedUser = useAppSelector((store) => store.global.connectedUser);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const runAndHideMenu = (callback: any) => {
    callback();
    setIsMenuOpen(false);
  };

  const handleSignOut = () => {
    dispatch(setConnectedUser(null));
    dispatch(setCompanySubscription(null));
    localStorage.removeItem('access-token');
    document.cookie =
      'refresh_token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    navigate('/');
  };

  const menuItems = [
    {
      type: 'item',
      icon: <DashboardOutlined className="text-xs" />,
      label: t('Dashboard'),
      onClick: () =>
        runAndHideMenu(() =>
          navigate(
            connectedUser.roles.includes('ACCOUNTANT')
              ? '/accounting'
              : '/dashboard'
          )
        ),
    },
    {
      type: 'item',
      icon: <SettingsOutlined className="text-xs" />,
      label: t('Settings'),
      onClick: () => runAndHideMenu(() => navigate('/settings/profile')),
    },
    { type: 'divider' },
    {
      type: 'item',
      icon: <Percent />,
      label: t('Accounting'),
      onClick: () =>
        runAndHideMenu(() => navigate('/dashboard/accounting/export-data')),
    },
    {
      type: 'item',
      icon: <ExitToAppOutlined className="text-xs rotate-180" />,
      label: t('Sign out'),
      onClick: () => runAndHideMenu(() => handleSignOut()),
    },
  ];

  const handleToggleMenu = () => setIsMenuOpen((isMenuOpen) => !isMenuOpen);

  return (
    <div className={styles.profile}>
      <div
        className={cls(
          'flex items-center gap-2 ml-2 justify-center h-full',
          styles.menuToggler
        )}
        onClick={handleToggleMenu}
        onBlur={() => setIsMenuOpen(false)}
      >
        <Image
          alt="user avatar"
          url={connectedUser.avatar || userAvatar}
          cls="w-[32px] h-[32px] min-w-[32px] rounded-full object-cover"
        />

        <p className="f-14px font-size-sm whitespace-nowrap font-semibold color-black">
          {connectedUser.name} {connectedUser.surname}
        </p>

        <ArrowDropDownIcon />
      </div>

      {isMenuOpen && (
        <div
          className="flex flex-col bg-first absolute right-1 rounded-xl drop-shadow-2xl overflow-hidden mt-2"
          ref={menuRef}
        >
          {menuItems.map((item, idx) =>
            item.type === 'divider' ? (
              <hr key={idx} />
            ) : (
              <button
                className="flex cursor-pointer text-[#797e8a] gap-2 font-bold hover:bg-[#eff3fd] px-5 py-3 duration-300 whitespace-nowrap"
                key={idx}
                onClick={item.onClick}
              >
                {item.icon}
                <p>{item.label}</p>
              </button>
            )
          )}
        </div>
      )}
    </div>
  );
};

export default Profile;
