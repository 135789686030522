import { chatAPI } from './apis';

export default class ChatService {
  static getChatById(chatId: string, signal?: AbortSignal) {
    return chatAPI.get(`v1/chats/${chatId}`, {
      signal,
    });
  }

  static async getChat(
    loit: string | undefined,
    lot: string,
    signal?: AbortSignal
  ) {
    const response = await chatAPI.get(`/v1/chats?loid=${loit}&lot=${lot}`, {
      signal,
    });
    return response;
  }

  static async createChat(
    loit: string | undefined,
    lot: string,
    signal?: AbortSignal
  ) {
    const requestBody = {
      linkedObjId: loit,
      linkedObjType: lot,
    };
    const response = await chatAPI.post(`/v1/chats`, requestBody, {
      signal,
    });
    return response;
  }

  static async addMessage(
    idChat: string,
    message: string,
    ids?: string[],
    signal?: AbortSignal
  ) {
    const requestBody = {
      what: message,
      mentionIds: ids,
    };
    return chatAPI.post(`/v1/chats/${idChat}/messages`, requestBody, {
      signal,
    });
  }

  static async deleteLastMessage(idChat: string, signal?: AbortSignal) {
    return chatAPI.delete(`/v1/chats/${idChat}/messages/last`, {
      signal,
    });
  }

  static async updateLastMessage(
    idChat: string,
    newMessage: string,
    signal?: AbortSignal
  ) {
    const requestBody = {
      what: newMessage,
    };
    return chatAPI.put(`/v1/chats/${idChat}/messages/last`, requestBody, {
      signal,
    });
  }

  static async getAllChats(signal?: AbortSignal) {
    const response = await chatAPI.get(`/v1/chats`, {
      signal,
    });
    return response;
  }

  static async archiveChat(id: string, signal?: AbortSignal) {
    const response = await chatAPI.patch(`/v1/chats/${id}/archived`, {
      signal,
    });
    return response;
  }

  static async unArchiveChat(id: string, signal?: AbortSignal) {
    const response = await chatAPI.patch(`/v1/chats/${id}/unarchived`, {
      signal,
    });
    return response;
  }

  static async readChat(id: string, signal?: AbortSignal) {
    const response = await chatAPI.patch(`/v1/chats/${id}/read`, {
      signal,
    });
    return response;
  }
}
