import { settingsAPI } from './apis';

export default class SubscriptionsService {
  static BASE_ROUTE = '/settings';

  static getAllPlans(signal?: AbortSignal) {
    return settingsAPI.get(`/v1/plans/search/displayable`, {
      signal,
    });
  }

  static getPlanInfo(planId: string, signal?: AbortSignal) {
    return settingsAPI.get(`/v1/plans/${planId}`, {
      signal,
    });
  }
}
