import * as yup from 'yup';

export const BillFormSchema = yup.object().shape({
  clientId: yup.string().required(),
  date: yup.string().required(),
  headerInfo: yup.string(),
  footerInfo: yup.string(),
  paymentDeadline: yup.string(),
  globalDiscount: yup.number(),
  advancePayment: yup.number(),
  globalDiscountPercentage: yup.number(),
  invoiceItems: yup
    .array()
    .of(
      yup.object().shape({
        productId: yup.string().nullable(),
        order: yup.string().required(),
        totalHT: yup.number().required(),
        description: yup.string(),
        discountPercentage: yup.string(),
        productDesignation: yup.string(),
        discount: yup.string(),
        quantity: yup.string().required(),
        unit: yup.string().nullable(),
        productVatRate: yup.string(),
        productPriceUHT: yup.string(),
        invoiceId: yup.string(),
      })
    )
    .min(1),
});

export const ProductFormSchema = yup.object().shape({
  name: yup.string().required(),
  designation: yup.string().required(),
  priceUHT: yup.number().required(),
  vatRate: yup.number().required(),
  unit: yup.string().required(),
  category: yup.string().required(),
});

export const ClientFormSchema = yup.object().shape({
  name: yup.string().required(),
  taxId: yup.string().required(),
  categoryCode: yup.string().matches(/411/).min(4).required().max(8),
  address: yup.string().required(),
  type: yup.string().required(),
  email: yup.string().email().required(),
});
