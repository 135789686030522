import type { FC } from 'react';

import { useNavigate } from 'react-router-dom';

import { useAppSelector, useAppTranslation } from '@/hooks';
import { cls } from '@/utils/funcs';

interface TabsProps {
  selected: string;
}

const Tabs: FC<TabsProps> = ({ selected }) => {
  const navigate = useNavigate();
  const { t } = useAppTranslation();
  const connectedUser = useAppSelector((store) => store.global.connectedUser);

  const SECTIONS = [
    { name: t('EXPORT_DATA'), href: 'export-data', permission: 'ALL' },
    {
      name: 'IMPORT_BOOKENTRIES',
      href: 'bookentries-import',
      permission: 'ACCOUNTANT',
    },
    { name: 'ACCOUNTING_PLAN', href: 'accounting-plan', permission: 'ALL' },
    { name: 'FISCAL_YEARS', href: 'fiscal-year', permission: 'ALL' },
    {
      name: 'IMPORT_ACCOUNTING_PLAN',
      href: 'accounting-plan-import',
      permission: 'ACCOUNTANT',
    },
    { name: 'Book entries', href: 'book-entries', permission: 'ACCOUNTANT' },
    { name: 'Ledger', href: 'ledger', permission: 'ACCOUNTANT' },
    {
      name: 'CATEGORY_BALANCE',
      href: 'category-balance',
      permission: 'ACCOUNTANT',
    },
  ] as const;

  return (
    <>
      <div className="flex ">
        {SECTIONS.map(
          ({ href, name, permission }) =>
            (permission == 'ALL' ||
              connectedUser.roles.includes(permission)) && (
              <div
                key={href}
                className={cls(
                  'font-bold px-4 py-3',
                  name === selected
                    ? 'text-[#0075E7]  border-b-2 border-[#0075E7] z-20'
                    : 'text-black cursor-pointer'
                )}
                onClick={() => navigate(`/dashboard/accounting/${href}`)}
              >
                {t(name)}
              </div>
            )
        )}
      </div>

      <hr className="h-0.5 bg-[#E6E8EA] -translate-y-4 z-10" />
    </>
  );
};

export default Tabs;
