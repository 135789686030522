import type { FC } from 'react';

import { Button } from '@/components/global';
import { cls } from '@/utils/funcs';

interface NoDataProps {
  illustration: string;
  title: string;
  description: string;
  btnLabel?: string;
  btnHandler?: () => void;
  isFullpage?: boolean;
}

const NoData: FC<NoDataProps> = ({
  illustration,
  title,
  description,
  btnLabel,
  btnHandler,
  isFullpage = true,
}) => {
  return (
    <section
      className={cls(
        'p-6 p-8 flex flex-col items-center justify-center gap-4 border-1 border-solid rounded-md bg-red w-full',
        isFullpage && 'mt-[81px] h-[calc(100vh-81px)] overflow-y-scroll'
      )}
    >
      <div className="mx-auto max-w-[375px] flex flex-col items-center text-center gap-2">
        <img src={illustration} alt="Data Not Found" className="w-full" />

        <p className="text-xl font-bold">{title}</p>

        <p className="text-[#828A89] text-sm">{description}</p>

        {btnLabel && (
          <Button
            label={btnLabel}
            cls="w-full bg-[#2B9F93] hover:opacity-90 transition-opacity duration-200 mt-2"
            onClick={btnHandler}
          />
        )}
      </div>
    </section>
  );
};

export default NoData;
