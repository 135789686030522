import * as yup from 'yup';

export type SubscriptionFormType = {
  firstName: string;
  lastName: string;
  companyName: string;
  country: string;
  postalCode: string;
  phoneNumber: string;
  address: string;
};

export const subscriptionFormSchema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  companyName: yup.string().required(),
  country: yup.string().required(),
  address: yup.string().required(),
});

export const buyCreditFormSchema = yup.object().shape({
  credit: yup.number().required().positive().min(1),
});
