import { useEffect, useMemo, useState } from 'react';
import type { FC } from 'react';

import { InsertChartOutlined, TaskOutlined } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';

import { hide } from '@/assets/images';
import { useAppDispatch, useAppSelector, useAppTranslation } from '@/hooks';
import { OperationsService } from '@/services';
import {
  setKpiValidationReceipt,
  setKpiValidationTransaction,
} from '@/store/actions/action-creators';
import { APP_VERSION } from '@/utils/constants';
import { cls } from '@/utils/funcs';

import { LockedBackdrop } from '../../Sidebar/LockedBackdrop';

interface SidebarProps {
  locked?: boolean;
}

const Sidebar: FC<SidebarProps> = ({ locked = false }) => {
  const { t } = useAppTranslation();
  const dispatch = useAppDispatch();
  const validatedReceiptNumber = useAppSelector(
    (store) => store.global.validatedReceiptNumber
  );
  const validatedTransactionNumber: number | null = useAppSelector(
    (store) => store.global.validatedTransactionNumber
  );
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(false);
  const [selectedItem, setSelectedItem] = useState('Performances');
  const location = useLocation();

  const navItems = useMemo(
    () => [
      {
        type: 'item',
        name: 'Dashboard',
        icon: <InsertChartOutlined />,
        href: '/accounting/dashboard',
      },
      {
        type: 'item',
        name: 'Declaration',
        icon: <TaskOutlined />,
        notificationCount: 8,
        href: '/accounting/declaration',
      },
    ],
    [validatedTransactionNumber, validatedReceiptNumber]
  );

  const toggleSidebarExpansion = () =>
    setIsSidebarExpanded((isSidebarExpanded) => !isSidebarExpanded);

  useEffect(() => {
    if (location.pathname.includes('settings')) {
      setSelectedItem('/settings');
    } else {
      setSelectedItem(location.pathname);
    }
  }, [location.pathname]);

  useEffect(() => {
    OperationsService.getKpiValidation().then((res) => {
      dispatch(setKpiValidationReceipt(res.data?.validatedReceiptNumber));
      dispatch(
        setKpiValidationTransaction(res.data?.validatedTransactionNumber)
      );
    });
  }, [dispatch]);

  return (
    <div className="relative">
      {locked && <LockedBackdrop />}
      <div
        className={cls(
          'hidden min-[600px]:flex min-[600px]:flex-col min-[600px]:relative',
          isSidebarExpanded ? 'min-w-[250px]' : 'min-w-[65px]'
        )}
      >
        <div className="h-[100%] fixed overflow-y-auto scroll-bar-style overflow-x-hidden bg-first pt-[80px]">
          <div
            className={cls(
              'h-full flex duration-500 flex-col transition-width',
              isSidebarExpanded ? 'w-[250px]' : 'w-[65px]'
            )}
          >
            <div className="grow items-center justify-center flex bg-red">
              <div className="ml-auto mr-auto w-[80%] h-full pt-[3vh] pb-[3vh] bg flex flex-col gap-2">
                {navItems.map(({ type, name, notificationCount, icon, href }) =>
                  type === 'item' ? (
                    <a
                      href={href}
                      key={href}
                      className={cls(
                        href === selectedItem && 'bg-blue-low-opacity',
                        isSidebarExpanded ? 'gap-1 pr-2' : 'gap-4 pl-[3px]',
                        'flex rounded-lg items-center py-3.5 cursor-pointer hover:bg-[#eef1fc] dark:hover:bg-[#1b1d2c] transition-colors duration-200'
                      )}
                    >
                      <div
                        className={cls(
                          'pr-2 pl-3',
                          href === selectedItem
                            ? 'stroke-[#3B71FE]'
                            : 'stroke-[#8D98AF]'
                        )}
                      >
                        {icon}
                      </div>
                      <div className="flex justify-between w-full gap-2 items-center truncate">
                        <p
                          className={cls(
                            href === selectedItem
                              ? 'color-blue'
                              : 'color-black opacity-60',
                            'font-semibold w-full truncate'
                          )}
                        >
                          {t(name)}
                        </p>
                        {!!notificationCount && (
                          <p className="text-xs bg-[#FDF9EC] text-[#7A5802] font-medium px-2 py-1 rounded-full">
                            {notificationCount}
                          </p>
                        )}
                      </div>
                    </a>
                  ) : (
                    <hr key={href} className="my-2" />
                  )
                )}
              </div>
            </div>

            <div className="h-[400px] transition-all duration-300	w-[90%] ml-auto mr-auto justify-end items-center flex flex-col cursor-pointer">
              <div className="flex justify-center pt-6 pb-6">
                <button onClick={toggleSidebarExpansion}>
                  <img
                    src={hide}
                    alt="Hide"
                    style={{ rotate: isSidebarExpanded ? undefined : '180deg' }}
                  />
                </button>
              </div>
              {/* //! Keep it just in case  */}
              {/* <div className="flex justify-center">
                {isSidebarExpanded ? (
                  <Logo />
                ) : (
                  <img alt="Bore-logo" src={UveyIcon} className="h-[45px]" />
                )}
              </div> */}
              <p className="text-center pt-4 text-black pb-4 text-xs">
                V {APP_VERSION}
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* 600px (mobile) Navbar */}
      <div className="min-[600px]:hidden max-[600px]:block bg-first fixed bottom-0 w-full z-40">
        <div className="flex justify-around p-2">
          {navItems.map(({ name, icon, notificationCount, href }) => (
            <a
              key={href}
              href={href}
              className={`${
                href === selectedItem && 'bg-blue-low-opacity'
              } flex flex-col rounded-lg items-center p-1 gap-1 `}
            >
              <div
                className={`pr-2 pl-3 ${
                  href === selectedItem
                    ? 'stroke-[#3B71FE]'
                    : 'stroke-[#989A9E]'
                }`}
              >
                {icon}
                <h3 className="bg-blue text-xs color-button-text px-1 ml-3 rounded-full	absolute top-1 ">
                  {notificationCount}
                </h3>
              </div>
              <p
                className={`${
                  href === selectedItem
                    ? 'color-blue'
                    : 'color-black opacity-60'
                }   font-semibold w-full text-xs`}
              >
                {t(name as string)}
              </p>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
