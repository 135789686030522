import { type FC, type MouseEventHandler, type ReactNode } from 'react';

import { Close, Info, Warning } from '@mui/icons-material';

import Button from '../Button';
import { type BannerSensitivity } from '../types';

interface BannerProps {
  /**
   * @default true
   */
  open: boolean;

  /**
   * @default true
   */
  isCloseable?: boolean;

  /**
   * @default null
   */
  onClose?: () => void;

  /**
   * @default "Default"
   */
  sensitivity?: BannerSensitivity;

  /**
   * @default ""
   */
  title?: string;

  /**
   * @default ""
   */
  msg?: string;

  /**
   * @default false
   */
  isButton?: boolean;

  /**
   * @default ""
   */
  buttonLabel?: string;

  /**
   * @default null
   */
  onClick?: MouseEventHandler;

  /**
   * @default null
   */
  icon?: ReactNode;
}

const Banner: FC<BannerProps> = ({
  open = true,
  isCloseable = false,
  isButton = false,
  title,
  msg,
  buttonLabel = 'Click me',
  icon,
  onClick,
  sensitivity = 'Default',
  onClose,
}) => {
  const getBannerSensitivityCLS = (sensitivity: BannerSensitivity) => {
    const bannerCLS = {
      Default: 'bg-white',
      Caution: 'bg-[#FEF9DA]',
      Critical: 'bg-[#FFF5FA]',
    };

    return bannerCLS[sensitivity];
  };

  const getBannerSensitivityIcon = (sensitivity: BannerSensitivity) => {
    const bannerCLS = {
      Default: <Info htmlColor="#6A7383" fontSize="inherit" />,
      Caution: <Info htmlColor="#C84801" fontSize="inherit" />,
      Critical: <Warning htmlColor="#DF1B41" fontSize="inherit" />,
    };

    return bannerCLS[sensitivity];
  };

  return (
    <>
      {open ? (
        <div
          className={`p-2.5 flex flex-col gap-1 rounded-md border border-[#D5DBE1] ${getBannerSensitivityCLS(
            sensitivity
          )}`}
        >
          <div className="flex items-start gap-3">
            <div className="text-[18px] h-[18px] flex justify-center items-center">
              {icon || getBannerSensitivityIcon(sensitivity)}
            </div>

            <div className="flex flex-col gap-1.5">
              {title && (
                <div className="flex items-center justify-between">
                  {title && (
                    <div className="flex items-center gap-1">
                      <h2 className="text-sm font-bold text-[#30313D]">
                        {title}
                      </h2>
                    </div>
                  )}

                  {isCloseable && (
                    <button onClick={onClose}>
                      <Close fontSize="small" />
                    </button>
                  )}
                </div>
              )}
              <p className="text-sm text-[#6A7383]"> {msg}</p>

              <div>
                {isButton && (
                  <Button
                    onClick={onClick}
                    label={buttonLabel}
                    sensitivity="Faided"
                    size="small"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Banner;
