import type { FC } from 'react';

import { Outlet } from 'react-router-dom';

import { Navbar } from './Navbar';
import Sidebar from './Sidebar';

interface AccountingProps {}

const Accounting: FC<AccountingProps> = () => {
  return (
    <div className="min-h-[100vh] flex-col flex">
      <Navbar />

      <div className="grow bg-sec flex">
        <Sidebar />
        <Outlet />
      </div>
    </div>
  );
};

export default Accounting;
