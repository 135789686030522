import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { warningExpiredSubscription } from '@/assets/images';

import Button from '../Button/Button';

function SubscriptionExpired() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="h-full flex items-center justify-center">
      <div className="bg-first min-w-[700px] max-w-[800px] w-[50%] ml-auto mr-auto p-12 mt-auto mb-auto">
        <div className="w-[60%] flex flex-col  gap-y-3  ml-auto mr-auto">
          <img
            alt="warningExpiredSubscription"
            className="w-[73px] mb-4"
            src={warningExpiredSubscription}
          />
          <p className="text-2xl color-black font-bold">
            {t('Subscription Expired')}
          </p>
          <p className="text-sm color-grey mb-4">
            {t('Subscription Expired Description')}
          </p>
          <Button
            label={t('Renew My Subscription')}
            cls="bg-[#23C38E] pl-12 pr-12"
            onClick={() => navigate('/wizard/subscription?renew=1')}
          />
        </div>
      </div>
    </div>
  );
}

export default SubscriptionExpired;
