import {
  type ComponentProps,
  type FC,
  Fragment,
  type MouseEventHandler,
} from 'react';

import { Controller } from 'react-hook-form';
import Select, { components } from 'react-select';

import { useAppTranslation } from '@/hooks';

import { getCls } from '../helpers';

// import type { IconPosition } from '../types';

const DEFAULT_CONTAINER_CLS = 'flex flex-col gap-1';
const DEFAULT_LABEL_CLS = 'text-left block text-sm text-[#30313D] font-bold';
const DEFAULT_ERROR_MSG_CLS = 'text-xs text-red-400';
const DEFAULT_BUTTON_CLS =
  'bg-white border-t border-blue-600 text-blue-500 p-1 py-3 flex justify-center w-full h-fit cursor-pointer shadow-md hover:bg-gray-200';

interface RSelectProps extends ComponentProps<typeof Select> {
  /**
   * @default ''
   */
  containerCls?: string;

  /**
   * @default ''
   */
  labelCls?: string;

  /**
   * @default ''
   */
  errorMsgCls?: string;

  /**
   * @default ''
   */
  label?: string /* | DefaultTFuncReturn */;

  /**
   * @default ''
   */
  errorMsg?: string /* | DefaultTFuncReturn */;

  // hasError?: boolean;

  /**
   * @default true
   */
  applyDefaultLabelCls?: boolean;

  /**
   * @default true
   */
  applyDefaultErrorMsgCls?: boolean;

  /**
   * @default true
   */
  applyDefaultContainerCls?: boolean;

  /**
   * @default null
   */
  control?: any;

  /**
   * @default null
   */
  refs?: any;

  /**
   * @default ''
   */
  optionalButton?: string;

  /**
   * @default ''
   */
  optionalButtonCls?: string;

  /**
   * @default true
   */
  applyDefaultOptionalButtonCls?: boolean;

  /**
   * @default null
   */
  optionButtonFunc?: MouseEventHandler<HTMLButtonElement>;

  /**
   *
   * @default null
   */
  onSelect?: (...event: any[]) => void;
}

/**
 * Global Select Component (based on `react-select`)
 * @param {string} label label text
 * @param {string} errorMsg error message text
 * @param {string} containerCls container class
 * @param {string} labelCls label class
 * @param {string} errorMsgCls error message class
 * @param {boolean} applyDefaultLabelCls whether to apply the default label class
 * @param {boolean} applyDefaultErrorMsgCls whether to apply the default error message class
 * @param {boolean} applyDefaultContainerCls whether to apply the default container class
 * @param {any[]} control react-hook-form's control object (this is mandatory for non-native HTML elements)
 * @param {any[]} refs react-hook-form's register function references
 * @param {string} optionalButton optional button text
 * @param {string} optionalButtonCls optional button class
 * @param {boolean} applyDefaultOptionalButtonCls whether to apply the default optional button class
 * @param {Function} optionButtonFunc function to be called when the optional button is clicked
 * @param {Function} onSelect This function will replace the onChange behavior
 * @param {any[]} ...props list of props to pass to react-select
 * @example <RSelect id="selectedNumber" label="Selected number:" placeholder="Select a number.." options={[{ label: 'One', value: 'one' }, { label: 'Two', value: 'two' }]} errorMsg={errors.selectedNumber && 'Please select a number.'} control={control} refs={register('selectedNumber')} />
 */
const RSelect: FC<RSelectProps> = ({
  label = '',
  errorMsg = '',
  containerCls = '',
  labelCls = '',
  errorMsgCls = '',
  applyDefaultLabelCls = true,
  applyDefaultErrorMsgCls = true,
  applyDefaultContainerCls = true,
  control = null,
  refs = null,
  optionalButton = '',
  optionalButtonCls = '',
  applyDefaultOptionalButtonCls = true,
  optionButtonFunc = () => {},
  onSelect = () => {},
  ...props
}) => {
  const { t } = useAppTranslation();
  containerCls = getCls(
    applyDefaultContainerCls,
    DEFAULT_CONTAINER_CLS,
    containerCls
  );

  labelCls = getCls(applyDefaultLabelCls, DEFAULT_LABEL_CLS, labelCls);
  optionalButtonCls = getCls(
    applyDefaultOptionalButtonCls,
    DEFAULT_BUTTON_CLS,
    optionalButtonCls
  );
  errorMsgCls = getCls(
    applyDefaultErrorMsgCls,
    DEFAULT_ERROR_MSG_CLS,
    errorMsgCls
  );

  const Menu = (props: any) => {
    return (
      <Fragment>
        <components.Menu {...props}>
          <div>
            {props.selectProps.fetchingData ? (
              <span className="fetching">{t('Fetching data...')}</span>
            ) : (
              <div>{props.children}</div>
            )}
            {optionalButton && (
              <button
                type="button"
                className={optionalButtonCls}
                onClick={optionButtonFunc}
              >
                {optionalButton}
              </button>
            )}
          </div>
        </components.Menu>
      </Fragment>
    );
  };

  return (
    <div className={containerCls}>
      {label && (
        <label htmlFor={props.id} className={labelCls}>
          {label}
        </label>
      )}

      <Controller
        name={refs?.name}
        control={control}
        render={({ field }) => {
          return (
            <Select
              components={{ Menu }}
              styles={{
                control: (styles) => ({
                  ...styles,
                  borderRadius: 4,
                  height: 40,
                  minHeight: 28,
                  fontSize: 14,
                  boxShadow: 'none',
                  minWidth: 80,
                  textAlign: 'left',
                }),

                dropdownIndicator: (styles) => ({
                  ...styles,
                  padding: 1,
                  marginLeft: 10,
                  minWidth: 20,
                  right: 15,
                  position: 'relative',
                }),

                indicatorsContainer: () => ({
                  maxWidth: 20,
                }),

                indicatorSeparator: (styles) => ({
                  ...styles,
                  display: 'none',
                }),

                placeholder: (styles) => ({
                  ...styles,
                  fontSize: 14,
                }),

                option: (styles) => ({
                  ...styles,
                  fontSize: 14,
                  padding: 5,
                  textAlign: 'left',
                }),
              }}
              isSearchable={false}
              {...props}
              {...{
                ...field,
                value:
                  props && props?.options
                    ? props.options.find(
                        (option: any) => option.value === field.value
                      )
                    : null,
                onChange: (val: any) => {
                  field.onChange(val.value);
                  onSelect(val);
                },
              }}
            />
          );
        }}
      />

      {errorMsg && <p className={errorMsgCls}>{errorMsg}</p>}
    </div>
  );
};

export default RSelect;
