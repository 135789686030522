import _ from './action-types';

const getActionCreator = (actionType: string) => (payload: any) => ({
  payload,
  type: actionType,
});

//? User actions
export const setIsPreloadState = getActionCreator(_.SET_IS_PRELOAD_STATE);
export const setConnectedUser = getActionCreator(_.SET_CONNECTED_USER);
// ==================================================================================//

//? Company actions
export const setCompany = getActionCreator(_.SET_COMPANY_INFO);
export const setIsLoadingCompany = getActionCreator(
  _.SET_IS_LOADING_COMPANY_INFO
);
export const setCompanySubscription = getActionCreator(
  _.SET_COMPANY_SUBSCRIPTION
);

export const setNotifications = getActionCreator(_.SET_NOTIFICATIONS);
// ==================================================================================//

//?Sidebar actions
export const setKpiValidationReceipt = getActionCreator(
  _.SET_KPI_VALIDATION_RECEIPT
);
export const setKpiValidationTransaction = getActionCreator(
  _.SET_KPI_VALIDATION_TRANSACTION
);

export const setUnreadChatsCounter = getActionCreator(
  _.SET_UNREAD_CHATS_COUNTER
);

// ==================================================================================//
export const setPlanInfo = getActionCreator(_.SET_PLAN_INFO);

// ==================================================================================//
//? navbar actions

export const setCreditCounter = getActionCreator(_.SET_CREDIT_COUNTER);

export const setFiscalYear = getActionCreator(_.SET_FISCAl_YEAR);
