import axios from 'axios';

import {
  defaultAPIRequestMiddleware,
  defaultAPIResponseMiddleware,
} from '@/lib/interceptors';

const BASE_URL =
  window.ENV[process.env.NODE_ENV || 'development'].apiNotificationUrl;

const notificationsAPI = axios.create({
  baseURL: BASE_URL,
});

notificationsAPI.interceptors.request.use(
  defaultAPIRequestMiddleware,
  (error) => Promise.reject(error)
);

notificationsAPI.interceptors.response.use(
  (res) => res,
  (error) => defaultAPIResponseMiddleware(error, notificationsAPI)
);

export default notificationsAPI;
