import { lazy } from 'react';

import { Navigate, Route } from 'react-router-dom';

import { AuthLayout } from '@/components/layouts';
import { PublicGuard } from '@/lib/guards';
import UpdatePassword from '@/pages/auth/ResetPassword/UpdatePassword';

import type { RouteType } from '.';

const SignIn = lazy(() => {
  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(import('@/pages/auth/SignIn')), 500);
  });
});

const SignUp = lazy(() => {
  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(import('@/pages/auth/SignUp')), 500);
  });
});

const ResetPassword = lazy(() => {
  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(import('@/pages/auth/ResetPassword')), 500);
  });
});

const AccountantSignUp = lazy(() => {
  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(import('@/pages/auth/AccountantSignUp')), 500);
  });
});
const outletElements = [
  {
    element: <Navigate to="sign-in" />,
    href: '',
  },
  {
    element: <SignIn />,
    href: 'sign-in',
  },
  {
    element: <SignUp />,
    href: 'sign-up',
  },
  {
    element: <ResetPassword />,
    href: 'reset-password',
  },
  {
    element: <UpdatePassword />,
    href: 'update-password/:resetToken',
  },
  {
    element: <AccountantSignUp />,
    href: 'accountant/sign-up',
  },
];

const routes: RouteType[] = [
  {
    children: (
      <>
        {outletElements.map(({ element, href }, idx) => (
          <Route key={idx} element={element} path={href} />
        ))}
      </>
    ),
    element: (
      <PublicGuard>
        <AuthLayout />
      </PublicGuard>
    ),
    path: 'auth',
  },
];

export default routes;
