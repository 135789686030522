import type { TranslationsType } from '..';

const FR_TRANSLATIONS: TranslationsType = {
  // ==============================
  // App - Auth - SignUp
  // ==============================
  Register: "S'inscrire",
  'Create your account and manage your accounting like a pro':
    'Créons votre compte et gérez votre comptabilité comme un pro',
  'First Name': 'Prénom',
  'Last Name': 'Nom',
  Email: 'Email',
  Password: 'Mot de passe',
  'Confirm password': 'Confirmer le mot de passe',
  'I accept the terms and conditions and the privacy policy.':
    "J'accepte les conditions générales et la politique de confidentialité.",
  Validate: 'Valider',
  'Sign in with Gmail': 'Connectez-vous avec Gmail',
  'Already have an account?': 'Vous avez déjà un compte ?',
  'Sign In': 'Se connecter',
  'The password must be valid.':
    'Le mot de passe doit comporter au moins 8 caractères, et inclure au moins une lettre majuscule, une lettre minuscule, un chiffre et un caractère spécial.',
  'Passwords do not match.': 'Les mots de passe ne correspondent pas.',
  'Please enter your first name.': 'Veuillez taper votre prénom.',
  'Please enter your last name.': 'Veuillez taper votre nom.',
  'This email already exists.': 'Cet email existe déjà.',
  'You must choose one option': 'Vous devez choisir une option',
  Create: 'Créer',
  'This feature not available in this plan':
    "Cette fonction n'est pas disponible dans ce plan",
  'forget password text':
    "Vous recevrez instantanément un e-mail avec un lien sécurisé de réinitialisation du mot de passe. Assurez-vous de vérifier votre boîte de réception.\n Si vous rencontrez des problèmes ou avez besoin d'assistance, n'hésitez pas à contacter notre équipe de support",
  'invitation description':
    "Félicitations ! Vous avez reçu une invitation à rejoindre Uvey . En acceptant cette invitation, vous aurez accès à toutes les fonctionnalités exclusives et aux avantages offerts par notre Solution.\n Nous sommes impatients de vous accueillir au sein de Uvey. Si vous avez des questions ou avez besoin d'assistance, n'hésitez pas à nous contacter.",
  'Follow the instructions to create your account or log in if you already have one.':
    'Suivez les instructions pour créer votre compte ou vous connecter si vous en avez déjà un',
  'Start enjoying the exclusive benefits reserved for UVEY members.':
    'Commencez à profiter des avantages exclusifs réservés aux membres de UVEY.',
  'We are delighted to offer you a range of plans meticulously designed to meet your accounting management needs.':
    'Nous sommes ravis de vous proposer une gamme de plans méticuleusement conçus pour répondre à vos besoins de gestion comptable.\n Nous vous invitons à examiner attentivement nos offres afin de choisir le plan qui répond le mieux à vos attentes professionnelles.',
  'Congratulations! Your payment has been processed successfully. Thank you for placing your trust in Uvey. You can now take full advantage of all the premium features of our service.':
    'Félicitations ! Votre paiement a été traité avec succès. Nous vous remercions de faire confiance à Uvey. Vous pouvez désormais profiter pleinement de toutes les fonctionnalités premium de notre service.',
  'Your data export operation may take some time. An email will be sent to you with a link to download your files as soon as they are ready.':
    "Votre opération d'exportation de données peut prendre un certain temps. Un e-mail vous sera envoyé avec un lien pour télécharger vos fichiers dès qu'ils seront prêts.",
  "This data export operation enables you to retrieve all your company's receipts and bank statements, as well as your accounting export.":
    "Cette opération d'exportation de données vous permet de récupérer tous les justificatifs et relevés bancaires de votre entreprise, ainsi que votre export comptable.",
  'Congratulations! Your data export is now complete. You can download the file containing all your financial information by clicking on the link below. If you have any questions or require further assistance, please do not hesitate to contact us.':
    "Félicitations ! Votre exportation de données est maintenant terminée. Vous pouvez télécharger le fichier contenant l'ensemble de vos informations financières en cliquant sur le lien ci-dessous. Si vous avez des questions ou besoin d'une assistance supplémentaire, n'hésitez pas à nous contacter.",

  // ==============================

  // ==============================
  // App - Auth - ResetPassword
  // ==============================
  'Reset your password': 'Réinitialisez votre mot de passe',
  'The link will be sent to your email address.':
    'Le lien sera envoyé à votre adresse e-mail.',
  // 'Email': 'Email',
  'Please enter a valid email address.':
    'Veuillez entrer une adresse e-mail valide.',
  'Submit Reset': 'Lancer la réinitialisation',
  'An email has been sent to the entered address.':
    "Un email a été envoyé à l'adresse saisie.",
  "Don't have an account yet?": "Vous n'avez pas encore un compte ?",
  'Enter a new password for your account.':
    'Veuillez saisire un nouveau mot de passe pour votre compte.',
  'Reset password link is invalid or has expired':
    "Le lien de réinitialisation du mot de passe n'est pas valide ou a expiré.\n" +
    'Cliquez sur le bouton ci-dessous pour demander un nouvel e-mail de réinitialisation.',
  'The reset password link has expired.':
    'Le lien de réinitialisation du mot de passe a expiré.',
  'The reset password link is already used.':
    'Le lien de réinitialisation du mot de passe est déjà utilisé.',
  'The reset password link is invalid.':
    "Le lien de réinitialisation du mot de passe n'est pas valide.",
  // 'Register': "S'inscrire",
  // ==============================

  // ==============================
  // App - Auth - SignIn
  // ==============================
  // 'Sign In': 'Se connecter',
  // Email: 'Email',
  // Password: 'Mot de passe',
  YEAR_IS_LOCKED: 'L’année est verrouillée',
  'Stay connected': 'Rester connecté',
  'Forgot your password?': 'Mot de passe oublié ?',
  // 'Sign In': 'Se connecter',
  // 'Sign in with Gmail': 'Connectez-vous avec Gmail',
  // "Don't have an account yet?": "Vous n'avez pas encore un compte ?",
  // Validate: 'Valider',
  'Email or password is incorrect.': 'Email ou mot de passe incorrect.',
  'This email is locked.': 'Cet e-mail est verrouillé.',
  'Your email has not been verified yet.':
    "Votre email n'a pas encore été vérifié.",
  'Welcome back to login. As a returning customer, you have access to all your previously saved information.':
    'Bienvenue à nouveau pour vous connecter. En tant que client récurrent, vous avez accès à toutes vos informations précédemment enregistrées.',
  // 'Please enter a valid email address.':
  //   'Veuillez entrer une adresse e-mail valide.',
  // 'Password must be between 8 and 32 characters.':
  //   'Le mot de passe doit comporter entre 8 et 32 caractères.',
  // ==============================

  // ==============================
  // App - Wizard
  // ==============================
  'Company Information': 'Informations société',
  'Bank Accounts': 'Comptes bancaires',
  Subscription: 'Abonnement',
  'Bank account deleted successfully': 'Compte bancaire supprimé avec succès',
  'Thank you for your payment!': 'Merci pour votre paiement !',
  'Go to dashboard': 'Aller au tableau de bord',
  'We sent you an email': 'Nous vous avons envoyé un e-mail',
  COMPANY_ADDRESS_ERROR_MESSAGE:
    "Veuillez entrer l'adresse complète de votre entreprise sans utiliser des lettres arabes.",
  // ==============================

  // ==============================
  // App - Wizard - CompanyInfo
  // ==============================
  'Welcome to Bore!': 'Bienvenue sur Uvey!',
  "Sign up now, it's free and only takes two minutes to manage your accounts.":
    "Inscrivez-vous maintenant et révolutionnez votre comptabilité ! Votre temps, notre priorité. Libérez-vous pour l'essentiel.",
  'Company Name': "Nom de l'entreprise",
  'Please enter your company name.':
    'Veuillez entrer le nom de votre entreprise.',
  'Searching for the company...': "A la recherche de l'entreprise...",
  'Type your company name.': 'Taper le nom de votre entreprise.',
  'ex. abyCompany': 'ex. abyCompany',
  'Company Form': 'Forme de l’entreprise',
  'Please select your company form.':
    'Veuillez sélectionner la forme de votre entreprise.',
  'ex. SARL': 'ex. SARL',
  'Company Address': 'Adresse de l’entreprise',
  'ex. North Urban Center': 'ex. Centre Urbain Nord',
  'Please enter your company address.':
    "Veuillez entrer l'adresse de votre entreprise.",
  'ex. Tunis': 'ex. Tunis',
  'Company Logo': 'Logo de l’entreprise',
  'Click to upload': 'Cliquez pour uploader',
  'or drag & drop': 'ou glisser-déposer',
  'SVG, PNG, JPG or GIF (max. 800x400px)':
    'SVG, PNG, JPG ou GIF (max. 800x400px)',
  Next: 'Suivant',
  Previous: 'Précédent',
  'Single-Person Limited Liability Company (SUARL)':
    'Société Unipersonnelle à Responsabilité Limitée (SUARL)',
  'Limited Liability Company (SARL)': 'Société à Responsabilité Limitée (SARL)',
  'Public limited company (SA)': 'Société Anonyme (SA)',
  'Tax Number': "Numéro d'identification fiscale",
  'Please enter your company Tax Number.':
    'Veuillez saisir le numéro fiscal de votre entreprise.',
  Company: 'Entreprise',
  'Add a new company': 'Ajouter une nouvelle entreprise',
  'First Name *': 'Prénom *',
  'Last Name *': 'Nom *',
  Country: 'Pays',
  'Postal Code': 'Code postal',
  Phone: 'Numero de téléphone',
  // ==============================

  // ==============================
  // App - Wizard - BankAccounts
  // ==============================
  // 'Welcome to Bore!': 'Bienvenue sur Bore !',
  // "Sign up now, it's free and only takes two minutes to manage your accounts.":
  //   "Inscrivez-vous maintenant, c'est gratuit et ne prend que deux minutes pour gérer votre comptabilité.",
  'Add a bank account': 'Ajouter un compte bancaire',
  'Please type your RIB.': 'Veuillez tapez votre RIB.',
  'The RIB must contain 20 digits.': 'Le RIB doit contenir 20 chiffres.',
  'This bank account is already added to your list.':
    'Ce compte bancaire est déjà ajouté à votre liste.',
  MAX_NB_BANK_ACCOUNTS_REACHED:
    'Vous pouvez ajouter un maximum de dix comptes bancaires.',
  'Please verify your RIB.': 'Veuillez vérifier votre RIB.',
  'Please check your bank.': 'Veuillez vérifier votre bank.',
  'ex. 20 digits': 'ex. 20 chiffres',
  'ex. WeCraft': 'ex. WeCraft',
  Currency: 'Devise',
  'Please select a currency.': 'Veuillez sélectionner un devise.',
  'Add a bank': 'Ajouter une banque',
  'List of added bank accounts': 'Liste des comptes bancaires ajoutés',
  // 'Next': 'Suivant',
  'Error occured.': 'Erreur est survenue.',
  'impossible to delete': 'Suppression impossible, les relevés existent.',
  'Return to statements': 'Retour aux relevés',
  'Bank statements': 'Relevés bancaires',
  // ==============================

  // ==============================
  // App - Wizard - Subscription
  // ==============================
  // 'Welcome to Bore!': 'Bienvenue sur Bore !',
  // "Sign up now, it's free and only takes two minutes to manage your accounts.":
  //   "Inscrivez-vous maintenant, c'est gratuit et ne prend que deux minutes pour gérer votre comptabilité.",
  // ==============================

  // ==============================
  // App - Wizard - Subscription - PricingPlans
  // ==============================
  'Basic plan': 'Forfait de base',
  'Business plan': "Plan d'affaires",
  'Enterprise plan': "Plan d'entreprise",
  'Per month': 'Par mois',
  Start: 'Commencer',
  Default: 'Défaut',
  Free: 'Gratuit',
  Trail: 'Essai',
  // ==============================

  // ==============================
  // App - Wizard - Subscription - PaymentForm
  // ==============================
  'Billing Information': 'Détails de facturation',
  'Firstname *': 'Prénom *',
  Firstname: 'Prénom',
  'Lastname *': 'Nom *',
  Lastname: 'Nom',
  'Company Name *': 'Nom de l’entreprise *',
  // 'Company Name': 'Company de l’entreprise',
  'Country / Region *': 'Pays / Région *',
  'Country / Region': 'Pays / Région',
  'Postal Code *': 'Code postal *',
  'Phone *': 'Téléphone *',
  'Address *': 'Adresse *',
  Address: 'Adresse',
  // 'Start': 'Commencer',
  'Your order': 'Votre commande',
  VAT: 'TVA',
  Total: 'Total',
  'Pay by credit card with e-dinar card or with your Paymee account.':
    'Payer par carte bancaire avec carte e-dinar ou avec votre compte Paymee.',
  Order: 'Commander',
  'Invoice details': 'Détails de la facture',
  'Your command': 'Votre commande',
  'Please enter a firstname.': 'Veuillez saisir un prénom.',
  'Please enter a lastname.': 'Veuillez saisir un nom.',
  'Please enter a company name.': "Veuillez saisir un nom d'entreprise.",
  'Please enter a country and/or region.':
    'Veuillez entrer un pays et/ou une région.',
  'Please enter a valid postal code.': 'Veuillez entrer un code postal valide.',
  'Please enter a valid phone number.':
    'Veuillez entrer un numéro de téléphone valide.',
  'Please enter an address.': 'Veuillez entrer une adresse.',
  // ==============================

  // ==============================
  // App - Dashboard
  // ==============================
  'Physical Entity': 'Entité physique',
  'Moral Entity': 'Entité morale',
  'Find your company': 'Chercher votre entreprise',
  Light: 'Clair',
  Dark: 'Sombre',
  Performances: 'Performances',
  Receipts: 'Justificatifs',
  Transactions: 'Transactions',
  Statements: 'Relevés',
  Documents: 'Documents',
  // docs errors
  INVALID_PATH: 'Chemin invalide',
  INVALID_FILE_NAME: 'Nom de fichier invalide',
  FILE_ALREADY_EXISTS: 'Le fichier existe déjà',
  INVALID_REQUEST_FILE_UPLOAD: 'Téléchargement de fichier de demande invalide',
  NO_FILE_FOUND: 'Aucun fichier trouvé',
  NO_FOLDER_FOUND: 'Aucun dossier trouvé',
  SIZE_LIMIT_EXCEEDED: 'Limite de taille dépassée',
  USER_NOT_FOUND: 'Fichier non trouvé',
  FILE_IS_LOCKED: 'Le fichier est verrouillé',
  FOLDER_IS_LOCKED: 'Le dossier est verrouillé',

  Notifications: 'Notifications',
  'PNG, JPG, PDF (max. 900 MB)': 'PNG, JPG, PDF (max. 900 MB)',
  'Upload a file': 'Télécharger un fichier',
  Size: 'Taille',
  owner: 'Propriétaire',
  DELETE_FILE: 'Supprimer',
  DOWNLOAD_FILE: 'Télécharger',
  "Can't upload statement": 'Impossible de télécharger le relevé',
  "Image can't be loaded, please check your internet connection":
    "Impossible de charger l'image, veuillez vérifier votre connexion Internet",
  'Add a new statement': 'Ajouter une nouvelle déclaration',
  'Processing error': 'Erreur de traitement',
  'Being analyzed': "En cours d'analyse",
  'Something went wrong please contact support for help.':
    "Un problème s'est produit. Veuillez contacter le service d'assistance pour obtenir de l'aide.",
  'Contact support': 'Contacter le support',
  'there is something wrong in end balance or accumulated amount of transactions':
    'Il y a un problème dans le solde final ou le montant accumulé des transactions.',
  'wrong end balance': 'Solde final inexact',
  'Bad bank statement rib': 'Rib incorrecte',
  'Rib explication tooltip':
    'Vos données bancaires sont incorrectes veuillez vérifier le relevé de banque',
  'Please check the bank statement you uploaded':
    "Veuillez vérifier le relevé bancaire que vous avez téléchargé, il semble que vous ayez téléchargé le mauvais relevé ou qu'il manque des informations importantes.",
  'Please check the bank statement date':
    'Veuillez vérifier le relevé bancaire que vous avez téléchargé, il semble que la date du relevé ne corresponde pas à la cellule que vous avez choisie.',
  'Delete statement': 'Supprimer la déclaration',
  'Return to transactions': 'Retour aux transactions',
  'Add Manual Transaction': 'Ajouter une transaction',
  ' Channel ': 'Canal',
  'Credit/Debit': 'Crédit/Débit',
  'Transaction type': 'Type de transaction',
  Title: 'Titre',
  'Payment channel is required': 'Canal de paiement obligatoire',
  'ShareholderUser is required': 'Un assoicié est nécessaire',
  'Money flow is required': "Flux d'argent obligatoire",
  'Title is required': 'Titre obligatoire',
  'Transaction date is required': 'Date de transaction obligatoire',
  'Amount is required': 'Montant obligatoire',
  'Amount is required in TND': 'Montant obligatoire en TND',
  'Amount must be greater than 0': 'Montant doit être supérieur à 0',
  'Currency is required': 'Devise obligatoire',
  'Add a new transaction': 'Ajouter une nouvelle transaction',
  'Successfully Added': 'Ajouté avec Succés',
  'The new transaction is now part of the existing list, seamlessly integrated.':
    'La nouvelle transaction fait désormais partie de la liste existante, parfaitement intégrée.',
  'Thank you for adding a new transaction':
    "Merci d'avoir ajouté une nouvelle transaction",
  'Add an account': 'Ajouter un compte',
  'Refresh receipts': 'Rafraîchir les reçus',
  'There is a gap amount of': 'Il y a une lacune dans la quantité de',
  Bills: 'Factures',
  SET_FIRST_COUNTER: 'Définir le premier compteur',
  SET_FIRST_COUNTER_DESC:
    'Veuillez définir le premier compteur pour commencer à gérer vos factures.',
  'Deleivery Notes': 'Bons de livraison',
  Products: 'Produits',
  'Go to homepage': "Aller à la page d'accueil",
  'Delete all': 'Supprimer tout',
  CASH: 'CAISSE',
  // ==============================
  // App - Accounting - Book Entry
  // ==============================
  'Book entries': 'Écritures comptables',
  'Piece Number': 'Numéro de pièce',
  Journal: 'Journal',
  'Category Code': 'compte comptable',
  Debit: 'Débit',
  Credit: 'Crédit',
  'Delete Book Entry': "Supprimer l'écriture comptable",
  'Are you sure you want to delete this book entry?':
    'Êtes-vous sûr de vouloir supprimer cette écriture comptable ?',
  CONFIRM_BOOK_ENTRY_DELETION:
    "Si vous cliquez sur supprimer, l'écriture comptable sera supprimée",
  Update: 'Mettre à jour',
  'Update Book Entry': 'Mettre à jour l’écriture comptable',
  'Add a new line': 'Ajouter une nouvelle ligne',
  'Add Book Entry': 'Ajouter une écriture comptable',
  label: 'Libellé',

  // ==============================
  // App - Accounting - Ledger
  // ==============================
  'Ledger Page': 'Page du grand livre',
  'No Ledger found': 'Aucun grand livre trouvé',
  Balance: 'Solde',
  Ledger: 'Grand livre',
  'There are no Ledger entries for this category.':
    'Il n’y a pas d’entrées dans le grand livre pour cette catégorie.',

  // ==============================
  // App - Accounting - Import Accounting Plan
  // ==============================
  'Import Accounting plan': 'Importer le plan comptable',
  'Accounting Plan Page': 'Page du plan comptable',
  'import accounting plan server error msg':
    "Un problème est survenu lors de l'importation du fichier. Veuillez réessayer.",
  BOOKENTRIES_IMPORT_SERVER_ERROR_MSG:
    "Erreur lors de l'importation des écritures comptables",
  CATEGORY_CODE_TOO_LONG: 'Une catégorie a dépassé les 8 chiffres.',
  EMPTY_CATEGORY_CODE:
    'Une catégorie est vide, veuillez fournir un code de catégorie pour chaque entrée.',
  RECORD_TOO_SHORT:
    'Une entrée est incomplète, veuillez fournir un code de catégorie et un libellé pour chaque entrée.',
  'Erase current accounting plan': 'Effacer le plan comptable actuel',
  'No file selected': 'Aucun fichier sélectionné',
  'Select File': 'Sélectionner un fichier',
  'Accounting plan imported successfully': 'Plan comptable importé avec succès',
  BOOKENTRIES_IMPORTED_SUCCESSFULLY:
    'Les écritures comptables ont été importées avec succès',

  // ==============================
  // App - Accounting - Categories Balance
  // ==============================
  CATEGORY_CODE: 'Code de catégorie',
  LABEL: 'Libellé',
  CREDIT: 'Crédit',
  DEBIT: 'Débit',
  CREDIT_BALANCE: 'Credit Balance',
  DEBIT_BALANCE: 'Debit Balance',
  BALANCE: 'Balance',
  NO_DATA_FOUND: 'Aucune donnée trouvée',
  EXPORT: 'Exporter',
  EXPORT_CSV: 'Exporter en CSV',
  EXPORT_XLS: 'Exporter en XLS',

  // ==============================
  // App - Accounting - Fiscal Years
  // ==============================
  'Fiscal year': 'Exercice comptable',
  'fiscal year already exists': 'L’exercice comptable existe déjà',
  'error while creating the fiscal year':
    'Erreur lors de la création de l’exercice comptable',
  'error while locking the fiscal year':
    'Erreur lors du verrouillage de l’exercice comptable',
  'error while unlocking the fiscal year':
    'Erreur lors du déverrouillage de l’exercice comptable',
  'Fiscal years Page': 'Page des exercices comptables',
  'Add New Fiscal Year': 'Ajouter un nouvel exercice comptable',
  'You can create a new fiscal year ':
    'Vous pouvez créer un nouvel exercice comptable',
  'Year not valid': 'Année non valide',
  'Year is required': 'Année est obligatoire',
  Lock: 'Verrouiller',
  unLock: 'Déverrouiller',
  // ==============================
  // App - Dashboard - Export fille
  // ==============================
  Download: 'Télécharger',
  Completed: 'Terminé',
  Files: 'Dossiers',
  'Exported data': 'Données exportées',
  'Choice date range': 'Choix de la date',
  'Account verification': 'Vérification du compte',
  'Enter your password': 'Entrez votre mot de passe',
  'Auth verification': "Vérification de l'identité",
  'Export fille': "Fichier d'exportation",
  'Export Data Page': "Page d'exportation des données",
  'There are no Book Entries': "Il n'y a pas d'écritures comptables",
  'Seems like the filter you applied have no results, consider resetting or changing the filters.':
    "Il semble que le filtre que vous avez appliqué n'ait pas de résultats, pensez à réinitialiser ou à modifier les filtres.",
  EXPORT_HISTORY: 'Historique d’exportation',
  FORMAT: 'Format',
  REQUESTED_AT: 'Demandé à',
  READY: 'Prêt',
  // ==============================

  // ==============================
  // App - Dashboard - Home
  // ==============================
  'Hello, world!': 'Bonjour, le monde!',
  // ==============================

  // ==============================
  // App - Dashboard - Drive
  // ==============================

  'deleting folder': 'Suppression du dossier',
  'folder deleted successfully': 'Dossier supprimé avec succès',
  'you can not move a folder to its subfolder':
    'Vous ne pouvez pas déplacer un dossier vers son sous-dossier',
  'moving folder': 'Déplacement du dossier',
  'folder moved successfully': 'Dossier déplacé avec succès',
  'downloading folder': 'Téléchargement du dossier',
  'folder downloaded successfully': 'Dossier téléchargé avec succès',
  'something went wrong while downloading folder':
    'Une erreur s’est produite lors du téléchargement du dossier',
  'creating folder': 'Création du dossier',
  'folder created successfully': 'Dossier créé avec succès',
  'uploading folder': 'Téléchargement du dossier',
  'folder uploaded successfully': 'Dossier téléchargé avec succès',
  'deleting file': 'Suppression du fichier',
  'file deleted successfully': 'Fichier supprimé avec succès',
  'something went wrong while deleting file':
    'Une erreur s’est produite lors de la suppression du fichier',
  'downloading file': 'Téléchargement du fichier',
  'file downloaded successfully': 'Fichier téléchargé avec succès',
  'something went wrong while downloading file':
    'Une erreur s’est produite lors du téléchargement du fichier',
  'something went wrong while renaming file':
    'Une erreur s’est produite lors du renommage du fichier',
  'you can not move a file to its current folder':
    'Vous ne pouvez pas déplacer un fichier vers son dossier actuel',
  'moving file': 'Déplacement du fichier',
  'file moved successfully': 'Fichier déplacé avec succès',
  'something went wrong while moving file':
    'Une erreur s’est produite lors du déplacement du fichier',
  'Delete folder': 'Supprimer le dossier',
  'Are you sure you want to delete this folder?':
    'Êtes-vous sûr de vouloir supprimer ce dossier ?',
  'New Folder': 'Nouveau dossier',
  'upload Folder': 'Télécharger le dossier',
  Folders: 'Dossiers',
  Open: 'Ouvrir',
  Rename: 'Renommer',
  Move: 'Déplacer',
  Delete: 'Supprimer',
  'No document available in this folder':
    'Aucun document disponible dans ce dossier',
  Moving: 'Déplacement',
  Renaming: 'Renommage',
  Creating: 'Création',
  'upload folder': 'Télécharger un dossier',
  'Selected path': 'Chemin sélectionné',
  // ==============================

  // ==============================
  // App - Settings
  // ==============================
  'Settings (02)': 'Paramétrages',
  Profile: 'Profil',
  Surname: 'Nom de famille',
  'Phone number': 'Numéro de téléphone',
  Enterprise: 'Entreprise',
  'Profile updated successfully.': 'Profil mis à jour avec succès.',
  'Legal Status': 'Statut juridique ',
  TAX_ID: 'Identifiant fiscal',
  'Password Page': 'Page de mot de passe',
  'Old Password': 'Mot de passe actuel',
  'New Password': 'Nouveau mot de passe',
  'Confirm new password': 'Confirmer le nouveau mot de passe',
  'Save changes': 'Enregistrer les modifications',
  Team: 'Équipe',
  ExportData: 'Exportation de données',
  OcrManagement: 'Gestion des pages',
  AddPages: 'Offres des pages',
  GREATER_THAN_ZERO: 'Le nombre doit être supérieur à 0.',
  // 'Subscription': 'Subscription',
  'Last Access Date': 'Date du dernier accès',
  'Invite a user': 'Inviter un utilisateur',
  Name: 'Nom',
  'Please enter a valid name.': 'Veuillez saisir un nom valide.',
  'Subscription ends on': "L'abonnement se termine le",
  MONTH: 'Par mois',
  'Export data page': "Page d'exportation des données",
  'Launching the export operation': "Démarrer l'opération d'exportation",
  PAGES_MANAGEMENT: 'Gestion des Pages',
  PAGES_OFFERS: 'Offres des pages',
  TRANSFER_HISTORY: 'Historique des transferts',
  senderId: 'Expéditeur',
  receiverId: 'Destinataire',
  numberPagesSent: 'Nombre de pages envoyées',
  SEND_PAGES: 'Envoyer',
  NO_HISTORY_TRANSFER: 'Aucun historique de transfert disponible.',
  TRANSFER_FORM: 'Formulaire de transfert',
  TRANSFER: 'Transfert',
  nbPages: 'Nombre de pages',
  REMBOURSEMENT: 'Remboursement',
  TRANSFER_SUCCESS: 'Transfert effectué avec succès',
  TRANSFER_FAILED: 'Le transfert a échoué. Veuillez réessayer.',
  PAGES_CREDIT_LIMIT_EXCEEDED:
    "L'abonnement de l'entreprise à qui vous transférez des pages a déjà un maximum de pages de crédit.",
  // ==============================
  // App - Performance
  // ==============================
  Turnover: "Chiffre d'affaires",
  Charge: 'Charge',
  'Operating result': "Résultat d'exploitation",
  Treasury: 'Trésorerie',
  'Compared to': 'Comparé à',
  'last year': "l'année dernière",

  //****** */
  // ==============================

  // ==============================
  // App - Receipts
  // ==============================
  Status: 'Statut',
  Amount: 'Montant',
  'Amount HT': 'Montant HT',
  'Total Amount': 'Montant total',
  All: 'Toutes',
  'Not associated/analyzed': 'Non associé/analysé',
  Associated: 'Associé',
  'Not associated': 'Non associé',
  Filter: 'Filtre',
  Category: 'Catégorie',
  'Delete filters': 'Supprimer les filtres',
  Apply: 'Appliquer',
  Search: 'Recherche',
  From: 'Fournisseur',
  Supplier: 'Fournisseur',
  'Payment Method': 'Mode de paiement',
  totalAmount: 'Montant total',
  'All Taxes Included': 'Toutes taxes incluses',
  'Add a VAT line': 'Ajouter une ligne TVA',
  invoiceDate: 'Date de facturation',
  vatAmount: 'Montant',
  vatTaxAmount: 'Taxe sur la TVA',
  vatTaxRate: 'Taux en %',
  'My receipt': 'Mes justificatifs',
  'Duplication error': 'Erreur de duplication',
  'confirm deletion': 'Confirmer la suppression',
  'are you sure you want to delete this receipt?':
    'Voulez-vous supprimer ce justificatif?',
  error: 'Erreur',
  'you can not delete the base duplication':
    'Vous ne pouvez pas supprimer la base',
  success: 'Succès',
  'Duplication deleted': 'Duplication supprimée',
  'Duplication validated': 'Duplication validée',
  'Error validating duplication': 'Erreur de validation de duplication',
  'Duplication Receipts': 'Duplication des justificatifs',
  'receipt already validated': 'Le justificatif est déjà validé',
  validate: 'Valider',
  GENERATE_CERTIFICATE: 'Générer un certificat RAS',
  next: 'Suivant',
  Aucune: 'Aucune',
  Aucun: 'Aucun',
  'Not analyzed': 'Non analysé',
  'No data table': "Aucun justificatif n'a été trouvé",
  Max: 'Max',
  Min: 'Min',
  'Add supporting receipts': 'Ajouter les justificatifs',
  FILE_IS_EMPTY: 'Le fichier est vide',
  'Next step': 'Continuer',
  Upload: 'Uploader',
  'Document providing physical proof that something has been done':
    'Document apportant la preuve physique que quelque chose a été réalisé',
  'error msg pdf':
    "Il semble que le pdf du justificatif n'est pas disponible, essayez de télécharger à nouveau le document.",
  'No document available in pdf': "Aucun document n'est disponible en pdf",
  'error msg image':
    "Il semble que l'image du justificatif n'est pas disponible, essayez de télécharger à nouveau le document.",
  'No image available': 'Aucune image disponible',
  Downloads: 'Téléchargements',
  Analysis: 'Analyse',
  CURRENCY_NOT_CONVERTED: 'Devise detectée est',
  Done: 'Terminé',
  Uploaded: 'Téléchargé',
  UPLOADED: 'Téléchargé',
  'Your file is not valid.': 'Votre fichier est invalide.',
  'The receipt is validated': 'Le justificatif est validé',
  NOT_ASSOCIATED: 'Non associé',
  NOT_ANALYZED: 'Non analysé',
  ANALYSIS_FAILURE: 'Analyse échouée',
  ANALYZED: 'Analysé',
  ANALYSIS_SUCCESS: 'Analysé',
  ANALYSIS_IN_PROGRESS: 'Analyse en cours',
  ANALYSIS_MAY_TAKE_LONGER: 'L’analyse peut prendre plus de temps',
  ASSOCIATED: 'Associé',
  VALIDATED: 'Validée',
  NOT_PAID: 'Non payée',
  PARTIALLY_PAID: 'Partiellement payée',
  PAID: 'Payée',
  Paid: 'Payée',
  'Back to receipts': 'Retour aux justificatifs',
  'View in fullscreen': 'Voir en plein écran',
  'Sales invoice': 'Facture de vente',
  'Purchase invoice': "Facture d'achat",
  'Invoice Type': 'Type de facture',
  TVA: 'TVA',
  'Date not found': 'Date non trouvée',
  "You're about to delete this receipt, are you sure ?":
    'Vous êtes sur le point de supprimer ce justificatif, êtes-vous sûr ?',
  'Delete receipt': 'Supprimer le justificatif',
  'Delete all receipts': 'Supprimer tous les justificatifs',
  "You're about to delete all the selected receipts, are you sure?":
    'Vous êtes sur le point de supprimer tous les justificatifs sélectionnés, êtes-vous sûr?',
  'date not found': 'Date non trouvée',
  'Ventilate receipt': 'Ventiler le justificatif',
  Fund: 'Caisse',
  Sum: 'Somme',
  'Deductible VAT': 'TVA déductible',
  'VAT-collected': 'TVA collectée',
  'Associates - current accounts': 'Associés - comptes courants',
  'No current accounts': 'Absence des comptes courants',
  'No vat collected and no vat deductible':
    'Absence de TVA collectée et de TVA déductible',
  'No funds': 'Absence des caisses',
  'Not converted': 'Non converti',
  Converted: 'Converti',
  'amount detected': 'Montant détecté',
  DETECTED_SUPPLIER: 'Fournisseur détecté',
  RECEIPT_VENTILATION_NOT_FOUND:
    'La validation est impossible sans ventilation par catégorie',
  RECEIPT_CATEGORY_CODE_NOT_FOUND:
    'La validation est impossible sans catégorie',
  RECEIPT_DATE_NOT_FOUND:
    'La validation est impossible sans la date de facturation',
  FORBIDDEN_VALIDATION: "Vous n'êtes pas autorisé à valider le justificatif",
  'There is no receipt suggestion': "Il n'y a pas de suggestion de reçu",
  'You still can search for a receipt using the search bar above':
    'Vous pouvez toujours rechercher un reçu en utilisant la barre de recherche ci-dessus.',
  'or get all receipts': 'ou obtenir tous les reçus',
  'Are you sure to unlock this receipt ?':
    'Êtes-vous sûr de vouloir déverrouiller ce reçu ?',
  'You are about to unlock this receipt, please confirm your action.':
    'Vous êtes sur le point de déverrouiller ce reçu, veuillez confirmer votre action.',
  'The validation is canceled': 'La validation est annulée',
  'Amount not found': 'Montant non trouvé',
  'Updating the amount from the receipt settings':
    'Mise à jour du montant à partir des paramètres',
  'invoiceDate is a required field':
    'La date de facturation est un champ obligatoire',
  'totalAmount is a required field':
    'Le montant total est un champ obligatoire',
  SUPPLIER_NOT_FOUND: 'Fournisseur introuvable',
  'Receipt updated successfully': 'Le reçu a été mis à jour avec succès',
  'Add a new payment action': 'Ajouter une nouvelle action de paiement',
  Source: 'Source',
  'Payment flow': 'Flux de paiement',
  'All the amounts must be in TND': 'Tous les montants doivent être en TND',
  DATE_CONFUSION:
    "La date de l'opération ne correspond pas à la date de la facture",
  ADD_New_PAYMENT_ACTION: 'Ajouter une nouvelle action de paiement',
  PAYMENT_FLOW: 'Flux de paiement',
  ALL_AMOUNT_MUST_BE_IN_TND: 'Tous les montants doivent être en TND',
  UNKOWN_SOURCE: 'Source inconnue',
  ADD_A_New_CLIENT: 'Ajouter Un Nouveu Client',
  ADD_NEW_SUPPLIER: 'Ajouter Un Nouveu Fournisseur',
  RECEIPT_INVOICE_DATE_FISCAL_YEAR_WRONG:
    'The invoice does not correspond to the correct fiscal year',
  'Transaction name': 'Nom de la transaction',
  MISSING_FIELDS: 'Données manquantes',
  transactionDate: 'Date de la transaction',
  amount: 'Montant',
  name: 'Nom',
  NAME_TRANSACTION: 'Transaction du',
  CANCEL_INVOICE: 'Annuler la facture',
  CANCEL_INVOICE_MESSAGE:
    "Vous êtes sur le point d'annuler cette facture, êtes-vous sûr ?",
  RECEIPT_ALREADY_VALIDATED_BY_ACCOUNTANT: 'Reçu déjà validé par le comptable',
  receipts: 'Justificatifs',
  EMAIL_ALIAS_INFO:
    'Vous pouvez transférer ou envoyer vos justificatifs à cette adresse et ils seront automatiquement ajoutés à votre compte.',
  SPLITTING_SHORTCUTS: 'Raccourcis de division',
  SPLITTING_SHORTCUTS_INFO:
    'Vous pouvez diviser rapidement votre document en utilisant ces patterns.',
  ONE_PAGE_PER_RECEIPT: 'Une page par justificatif',
  TWO_PAGES_PER_RECEIPT: 'Deux pages par justificatif',
  THREE_PAGES_PER_RECEIPT: 'Trois pages par justificatif',
  Shortcuts: 'Raccourcis',
  FILE_SPLIT_SUCCESSFULLY: 'Fichier divisé avec succès',
  FILE_SPLIT_ERROR: 'Erreur de division de fichier',
  UPLOAD_MULTI_RECEIPT_FILE: 'Télécharger un fichier multi-justificatif',
  // 'Team': 'Team',
  // 'Subscription': 'Subscription',
  // ==============================
  // App - Transactions
  // ==============================
  Filters: 'Filtres',
  Sort: 'Trier',
  by: 'par',
  Date: 'Date',
  'Sale of': 'Vente de',
  Service: 'Service',
  'Operations to validate': 'À valider par le comptable',
  'Validated operations': 'Opérations validées',
  Operations: 'Opérations',
  'Change my plan': 'Changer mon forfait',
  'Validate the operation': "Valider l'opération",
  'To categorize': 'À catégoriser',
  Invalidate: 'Invalider',
  Invalidated: 'Non validée',
  'Validate by Owner': 'Validé par le propriétaire',
  'Validate by accoutant': 'Validée par le comptable',
  'The transaction is validated': 'La transaction est validée',
  'The transaction is invalidated':
    'la validation de la transcation est annulée',
  'Error validating transaction': 'Erreur dans la validation de la transaction',
  'Account balances': 'Soldes des comptes',
  'Software subscription': 'Abonnement logiciel',
  'Deposit received': 'Caution reçue',
  'VAT paid': 'TVA payée',
  'To choose': 'À choisir',
  Perhaps: 'Peut-être?',
  'This receipt is already fully paid':
    'Ce justificatif est déjà entièrement payé',
  'Import transactions': 'Importer des transactions',
  'Drop CSV file here or click to upload':
    'Déposez le fichier CSV ici ou cliquez pour le télécharger',
  'Matching columns': 'Correspondance des colonnes',
  Column: 'Colonne',
  fileColumns: 'Colonnes de fichiers',
  Verification: 'Vérification',
  verificationReport: 'Rapport de vérification',
  'Report on transactions in your file.':
    'Rapport sur les transactions dans votre dossier.',
  'Total errors': "Nombre d'erreur",
  'Number of warnings': 'Nombre des alertes',
  'you have transactions for which information we consider important is missing from your file.':
    'Vous avez des transactions pour lesquelles il manque des informations que nous considérons comme importantes dans votre fichier.',
  'You have existing transactions': 'Vous avez des transactions existantes',
  'as well as others with a transaction date within a month for which you have a bank statement.':
    "ainsi que d'autres dont la date de transaction se situe dans un mois pour lesquelles vous disposez d'un relevé bancaire.",
  'For additional information, please find below the transactions concerned.':
    "Pour plus d'informations, Veuillez trouver ci-dessous les transactions concernées.",
  'You can continue to import your transactions.':
    'Vous pouvez continuer pour importer vos transactions.',
  'duplicated transactions': 'Transactions dupliquées',
  'transactions that are missing some information':
    'Les transaction qui manque quelque informations',
  'transactions with an existing month':
    'Les transactions avec un mois existant',
  'Delete transaction': 'Supprimer la transaction',
  "You're about to delete this transaction, are you sure ?":
    'Vous êtes sur le point de supprimer cette transaction, êtes-vous sûr ?',
  'import transaction': 'Importer des transactions',
  'you still have errors in your report':
    'Votre rapport contient encore des erreurs',
  'Delivery Notes': 'Bons de livraison',
  'Category code': 'Code category',
  'Delete Client': 'Supprimer le client',
  'Add a new product': 'Ajouter un nouveau produit',
  Price: 'Prix',
  Unit: 'Unité',
  Issuer: 'Émetteur',
  'Operation Date': "Date d'opération",
  Unassign: 'Retirer',
  Assign: 'Affecter',
  // ==============================

  // ==============================
  // App - Chat
  // ==============================
  Close: 'Fermer',
  'Delete the last message': 'Suppression du dernier message',
  "You're about to delete the last message, are you sure?":
    'Êtes-vous sur de vouloir supprimer le dernier message?',
  Messages: 'Messages',
  'Search your chat': 'Cherchez votre discussion',
  Current: 'Actuel',
  Archived: 'Archivé',
  Archive: 'Archiver',
  WRITE_A_MESSAGE: 'Ecrire un message ...',
  'About the transaction': 'A propos la transaction',
  'Transaction Date': 'Date de transaction',
  'Transaction Type': 'Type de transaction',
  Chats: 'Discussions',
  new: 'nouveau',
  categoryCode: 'Code de catégorie',
  invoicesNumber: 'Nombre de factures',
  paymentPending: 'Paiement en attente',
  salesInvoiced: "Chiffre d'affaires facturé",
  'About the declaration': 'A propos la déclaration',
  YouHaveBeenTaggedOnChat: 'Vous avez été identifié sur le chat : ',

  // ==============================
  // App - Other - Global Error Handler
  // ==============================
  'Server error. Please try again later.':
    'Erreur au niveau du serveur. Veuillez réessayer plus tard.',
  'Service Unavailable. Please try again later.':
    'Service non disponible. Veuillez réessayer plus tard.',
  Unauthorized: 'Non autorisé',
  "You don't have permissions to access this resource.":
    "Vous n'êtes pas autorisé à accéder à cette ressource.",
  'Timeout error': "Erreur d'expiration du délai",
  // ==============================

  // ==============================
  // App - Other - Error Boundary
  // ==============================
  'Unexpected Error Occured': "Une erreur inattendue s'est produite",
  // ==============================

  // ==============================
  // App - Other - Subscription Expired
  // ==============================
  'Subscription Expired': 'Votre abonnement a expiré',
  'Subscription Expired Description':
    'Votre abonnement a expiré, merci de cliquer sur Renouveler mon abonnement pour réactiver votre compte.',
  'Renew My Subscription': 'Renouveler mon abonnement',
  'Subscription Expiry Approaching':
    "L'abonnement arrive bientôt à expiration, merci de cliquer sur Renouveler mon abonnement.",
  'Subscription Days Left': 'Expire dans {{days}} jours',
  'Expires in less than 1 day': "Expire dans moins d'un jour",
  // ==============================

  // ==============================
  // App - Other - Not Found
  // ==============================
  'Page Not Found': 'Page non trouvée',
  // ==============================

  // App - Handle errors
  // ==============================
  AUTH_SIGNIN_BAD_CREDENTIALS: "L'e-mail ou le mot de passe est incorrect.",
  AUTH_SIGNIN_USER_LOCKED: 'Ce compte est actuellement verrouillé',
  AUTH_SIGNIN_USER_NOT_ACTIVATED: "Ce compte n'est pas activé",
  AUTH_SIGNIN_INVALID_RECAPTCHA_TOKEN:
    'Le jeton captcha est invalide ou a expiré',
  AUTH_EMAIL_ALREADY_EXISTS: 'Cet email existe déjà',
  AUTH_SIGNUP_EMAIL_ALREADY_EXISTS:
    'Cet email est déjà utilisé dans un autre compte',
  INTERNAL_ERROR: "Une erreur de serveur interne s'est produite",
  COMPANY_COMPANY_EXIST: "L'entreprise existe déjà",
  COMPANY_ID_NOT_FOUND: 'Utilisateur non trouvé',
  COMPANY_INVALID_CREATE_COMPANY_REQUEST:
    "Quelque chose s'est mal passé, veuillez revérifier les informations de votre entreprise.",
  SETTINGS_ID_MEMBER_NOT_FOUND: 'Le membre est introuvable.',
  SETTINGS_COMPANY_NOT_FOUND: 'La société est introuvable.',
  SETTINGS_INVITATION_EXISTS:
    "Une invitation a déjà été envoyée à l'adresse email fournie.",
  SETTINGS_MEMBER_EXISTS: "Un membre avec l'email fourni existe déjà",
  SETTINGS_ID_NOT_FOUND:
    "L'identifiant d'utilisateur n'appartient à aucun compte",
  UNAUTHORIZED_UNAUTHORIZED: "L'accès non autorisé",
  'Oops! Page not found': 'Oups!\n Page non trouvée',
  "This page doesn't exist or get removed. We suggest you back to home":
    "Cette page n'existe pas ou a été supprimée. Nous vous suggérons de retourner à l'accueil.",
  'Go back home': "Retour à l'accueil",
  'Access denied': 'Oups!\n Accès refusé',
  "You don't have permission to access requested page.":
    "Vous n'avez pas l'autorisation d'accéder à la page demandée. Nous vous suggérons de retourner à l'accueil.",
  'Oh no!': 'Oh non!',
  'So sorry, something not working well please try again later or feel free to contact support.':
    "Désolé, quelque chose ne fonctionne pas bien. Veuillez réessayer plus tard ou n'hésitez pas à contacter le service d'assistance.",
  'Something went wrong': "Quelque chose n'a pas fonctionné",
  ADD_A_NEW_MEMBER:
    'Ajouter un nouveau membre ? Santé et succès de la collaboration dans votre espace de travail.',
  ADD_A_NEW_CLIENT_DESCRIPTION:
    "Vous pouvez toujours modifier un client après sa création. Cependant, les modifications apportées à un client n'affecteront pas les factures validées, mais uniquement les factures futures.",
  ADD_A_NEW_PRODUCT_DESCRIPTION:
    "Nouveau produit ajouté ! Nous sommes impatients d'atteindre l'excellence ensemble.",

  // ==============================
  // Month
  // ==============================
  January: 'Janvier',
  February: 'Février',
  March: 'Mars',
  April: 'Avril',
  May: 'Mai',
  June: 'Juin',
  July: 'Juillet',
  August: 'Août',
  September: 'Septembre',
  October: 'Octobre',
  November: 'Novembre',
  December: 'Décembre',

  // ==============================
  // accounting
  // ==============================
  active: 'Actif',
  pending: 'En attente',
  stable: 'Stable',
  unstable: 'Instable',
  'Add credit': 'Ajouter des crédits',
  'Credit price': 'Prix du crédit',
  'Add a new ventilation': 'Ajouter une nouvelle ventilation',
  'TVA amount': 'Montant TVA',
  Companies: 'Entreprises',
  Missing: 'Manquants',
  Validated: 'Validée',
  'Not validated': 'Non validés',
  'many uploaded': 'téléchargés',

  // ==============================
  // General translations
  // ==============================
  'Drag & drop': 'Glissement et dépôt',
  Dashboard: 'Tableau de bord',
  Settings: 'Paramètres',
  Accounting: 'Comptabilité',
  'Sign out': 'Déconnexion',
  'Validated by owner': 'Validé par le propriétaire',
  'Validated by accountant': 'Validée par le comptable',
  'Unjustified operations': 'Opérations non justifiées',
  'There are no transactions': 'Absence de transactions',
  'Seems like the filter you applied have no results, consider resetting or changing the filters':
    "Il semble que le filtre que vous avez appliqué n'a donné aucun résultat. Vous devriez envisager de le réinitialiser ou de modifier les filtres.",
  filter: 'filtres',
  'Clear filter': 'Effacer le filtre',
  Currencies: 'Devises',
  Select: 'Sélectionner',
  'Select category': 'Sélectionner une catégorie',
  'Select All': 'Sélectionner tout',
  Receipt: 'Justificatif',
  'Global statistics': 'Statistiques globales',
  'Bank balance': 'Solde bancaire',
  'Associated Current Account': 'Compte courant associé',
  'No data found in your search on this page':
    "Aucune donnée n'a été trouvée dans votre recherche sur cette page",
  RIB: 'RIB',
  Label: 'Label',
  "The date doesn't match": 'La date ne correspond pas',
  'Total amount': 'Montant total',
  'Original amount': 'Montant original',
  'Go back to receipts': 'Retourner aux reçus',
  'Go back to transactions': 'Retourner aux transactions',
  'Category - Ventilation': 'Catégorie - Ventilation',
  'You received an invite': 'Vous avez reçu une invitation',
  'has invited you to join': 'vous invite à rejoindre',
  organization: "l'organisation",
  "We're delighted to welcome you to a new era of simplified, efficient accounting management.":
    'Nous sommes ravis de vous accueillir dans une nouvelle ère de gestion comptable simplifiée et efficace.',
  "Your Digital Accounting Partner designed to simplify and automate your company's financial management.":
    'Votre Partenaire Comptable Numérique conçue pour simplifier et automatiser la gestion financière de votre entreprise. En vous inscrivant, vous faites le premier pas vers une expérience comptable moderne, intuitive et parfaitement adaptée à vos besoins.',
  'To ensure the security of your account, please enter your password below.':
    'Pour assurer la sécurité de votre compte, veuillez entrer votre mot de passe ci-dessous. Cela nous permet de confirmer votre identité et de garantir un accès sécurisé à vos informations.',
  'To personalize your export experience, please select a specific date for which you wish to export your accounting data.':
    "Afin de personnaliser votre expérience d'exportation, veuillez choisir une date spécifique pour laquelle vous souhaitez exporter vos données comptables.",
  Add: 'Ajouter',
  PENDING: 'En attente',
  VALIDATED_OWNER: 'Validé par le propriétaire',
  VALIDATED_ACCOUNTANT: 'Validé par le comptable',
  // ==============================
  // Accountant & User Declaration
  // ==============================
  Type: 'Type',
  PAYED_OUTDATED: 'Payé en retard',
  PAYED: 'Payé',
  DECLARED: 'Déclaré',
  DRAFT: 'Brouillon',
  Draft: 'Brouillon',
  CANCELLED: 'Annulée',
  Canceled: 'Annulée',
  'No Uploaded Files': 'Pas de fichiers',
  Attachments: 'Fichiers',
  Notes: 'Notes',
  'Payed by': 'Payé par',
  Paiment: 'Paiment',
  'Declared by': 'Déclaré par ',
  Cancel: 'Annuler',
  Save: 'Enregister',
  DECLARED_OUTDATED: 'Déclaré en retard',
  'No notes': 'Pas de notes',
  'Legal entity': 'Personne morale',
  Individuals: 'Personne physique',
  'declaration canceled': 'Déclaration annulée',
  'due date': "Date d'échéance",
  'not payed': 'Déclaration sociale',
  'must be payed': 'déclaration financière',
  Declarations: 'Déclarations',
  Uncancel: 'Désannuler',
  'You are in the process of uncanceling this declaration':
    'Vous êtes en train de désannuler cette déclaration ',
  'Uncancel declaration': 'Désannuler la déclaration',
  'Go back to declarations': 'Retourner aux déclarations',
  Declaration: 'Déclaration',
  'for the entreprise ': "pour l'entreprise ",
  'declaration title': 'Titre',
  'declaration status': 'Statut',
  'declaration declared when': 'Déclaré le',
  'declaration payed when': 'Payé le',
  'This declaration in over its due date':
    'Le délai pour cette déclaration est dépassé.',
  late: 'Late',
  'this declaration has not been treated yet':
    "Cette déclaration n'a pas encore été traitée",
  'this declaration has been cancelled': 'Cette déclaration a été annulée',
  'this declaration has not been declared yet':
    "Cette déclaration n'a pas encore été déclarée",
  'this declaration has not been payed yet':
    "Cette déclaration n'a pas encore été payée",
  'this declaration does not need to be payed':
    'Cette déclaration ne doit pas être payée',
  Billing: 'Facturation',
  'Create new invoice': 'Créer une nouvelle facture',
  UNSUPPORTED_FILE_TYPE: 'Type de ficher non supporté',
  DECLARATION_DATE_MUST_BE_BEFORE_PAY_DATE:
    'La date de déclaration doit être avant la date de paiement',
  'This product will be used only once, no need to save it.':
    "Ce produit ne sera utilisé qu'une seule fois, il n'est pas nécessaire de le sauvegarder.",
  'Ventilation is required, please ventilate this receipt':
    'Une ventilation est nécessaire, veuillez ventiler ce reçu',
  "Feel free to add the category you want, and make sure your category code follows our requirements, you can't create a category with an existing category code.":
    "N'hésitez pas à ajouter la catégorie que vous souhaitez, et assurez-vous que votre code de catégorie est conforme à nos exigences. Vous ne pouvez pas créer une catégorie avec un code de catégorie existant.",
  UPDATE_RECEIPT: 'Mise à jour du justificatif',
  FOLDER_NAME_REQUIRED: 'Le nom du dossier est obligatoire',
  FOLDER_CREATING: 'Donnez un nom significatif à votre dossier',
  SOMETHING_WENT_WRONG: "Un problème s'est produit, veuillez réessayer.",
  CODE_ALREADY_EXISTS: 'Le code client existe déjà.',
  Suppliers: 'Fournisseurs',

  // ==============================
  // Billings
  // ==============================
  ADD_A_NEW_CLIENT_TITLE: 'Ajouter un nouveau client',
  UPDATE_CLIENT_TITLE: 'Mettre à jour client',
  CATEGORY_CODE_REQUIREMENT:
    'Le code de la catégorie doit contenir 411 et sa longueur doit être supérieure à 3 et inférieure à 8.',
  Stamp: 'Timbre fiscal',
  INVOICE_UPDATED_SUCCESSFULLY: 'La facture a été mise à jour avec succès.',
  INVOICE_VALIDATED_SUCCESSFULLY: 'La facture a été validée avec succès.',
  INVOICE_FINALIZED_SUCCESSFULLY: 'La facture a été finalizée avec succès.',
  BACK_TO_DASHBOARD: 'Retour au tableau de bord',
  Preview: 'Aperçu',
  CREATE_INVOICE: 'Créer une facture',
  ISSUE_DATE: "Date d'émission",
  DEADLINE: 'Délai',
  DUE_DATE: "Date d'échéance",
  Finalize: 'Finaliser',
  CREATE_A_NEW_PRODUCT: 'Créer un nouveau produit',
  UPDATE_FOOTER: 'Mettre à jour le pied de page',
  APPLY_NUMBER_TO_CHARACTER: 'Afficher le montant en lettre',
  ADD_DESCRIPTION: 'Ajouter une description',
  APPLY_DISCOUNT: 'Appliquer une remise',
  CURRENCY_IS_REQUIRED: 'La devise est obligatoire',
  VAT_DETAILS: 'Détails TVA',
  VAT_AMOUNT: 'Montant TVA',
  VAT_RATE: 'Taux TVA',
  Base_HT: 'Base HT',
  FOOTER_UPLOADED_SUCCESSFULLY: 'Le pied de page a été téléchargé avec succès',
  FOOTER_UPLOAD_FAILED: 'Le téléchargement du pied de page a échoué',
  GLOBAL_DISCOUNT: 'Remise globale',
  Summary: 'Récapitulatif',
  'Total before tax': 'Total HT',
  'Total after discount': 'Total HT après remise',
  Total_VAT: 'Total TVA',
  'Total after tax': 'Montant TTC',
  SPECIAL_NOTES: 'Mention Spéciale',
  'Document generated by': 'Document généré par',
  STATEMENT_CREATED_SUCCESSFULLY: 'Le relevé a été créé avec succès',
  SEND_TO_INVOICES: 'Envoyer aux factures',
  SEND_TO_STATEMENT: 'Envoyer aux relevés',
  RECEIPT_CREATED_SUCCESSFULLY: 'Le reçu a été créé avec succès',
  FILE_NAME_NOT_FOUND: "Le nom du fichier n'a pas été trouvé",
  Product: 'Produit',
  Quantity: 'Quantité',
  'Prix Unit': 'Prix Unit',
  'Price before tax': 'Prix HT',
  DOCUMENT_CREATED_SUCCESSFULLY: 'Document créé avec succès',
  Quote: 'Devis',
  'Quote Number': 'Numéro de devis',
  'Delivery note': 'Bon de livraison',
  'Delivery note Number': 'Numéro de bon de livraison',
  Quotes: 'Devis',
  'Delivery notes': 'Bons de livraison',
  Unarchived: 'Non archivé',
  'you are about to delete this delivery note, are you sure?':
    'Vous êtes sur le point de supprimer ce bon de livraison, êtes-vous sûr?',
  'you are about to delete this quote, are you sure?':
    'Vous êtes sur le point de supprimer ce devis, êtes-vous sûr?',
  'you are about to archive this delivery note, are you sure?':
    "Vous êtes sur le point d'archiver ce bon de livraison, êtes-vous sûr?",
  'you are about to archive this quote, are you sure?':
    "Vous êtes sur le point d'archiver ce devis, êtes-vous sûr?",
  'The invoice has been duplicated': 'La facture a été dupliquée',
  'The quote has been duplicated': 'Le devis a été dupliqué',
  'The delivery note has been duplicated': 'Le bon de livraison a été dupliqué',
  Edit: 'Modifier',
  Review: 'Réviser',
  Duplicate: 'Dupliquer',
  'Delete invoice': 'Supprimer la facture',
  'Delete quote': 'Supprimer le devis',
  'Delete delivery note': 'Supprimer le bon de livraison',
  'There is no invoice': "Il n'y a pas de facture",
  'There is no quote': "Il n'y a pas de devis",
  'There is no delivery note': "Il n'y a pas de bon de livraison",
  'You are about to delete this invoice, are you sure?':
    'Vous êtes sur le point de supprimer cette facture, êtes-vous sûr(e) ?',
  'You can create a new invoice by clicking on the button above':
    'Vous pouvez créer une nouvelle facture en cliquant sur le bouton ci-dessus',
  'You can create a new delivery note by clicking on the button above':
    'Vous pouvez créer un nouveau bon de livraison en cliquant sur le bouton ci-dessus',
  'You can create a new quote by clicking on the button above':
    'Vous pouvez créer un nouveau devis en cliquant sur le bouton ci-dessus',
  'You can add a new invoice by clicking on the button above':
    'Vous pouvez ajouter une nouvelle facture en cliquant sur le bouton ci-dessus',
  'You can add a new delivery note by clicking on the button above':
    'Vous pouvez ajouter un nouveau bon de livraison en cliquant sur le bouton ci-dessus',
  'You can add a new quote by clicking on the button above':
    'Vous pouvez ajouter un nouveau devis en cliquant sur le bouton ci-dessus',
  'The delivery note has been transformed into bill':
    'Le bon de livraison a été transformé en facture',
  'Transform into delivery note': 'Transformer en bon de livraison',
  'transform into bill': 'Transformer en facture',
  'The quote has been transformed into bill':
    'Le devis a été transformé en facture',
  'Create new quote': 'Créer un nouveau devis',
  'Create new delivery note': 'Créer un nouveau bon de livraison',
  'Archive Quote': 'Archiver le devis',
  'Archive Delivery Note': 'Archiver le bon de livraison',
  DELIVERY_NOTE: 'Bons de livraison',
  WITH_HOLDING_TAX: 'Retenue à la source',
  INVOICE_COLOR: 'Couleur primaire de l’aperçu',
  JANUARY: 'Janvier',
  FEBRUARY: 'Février',
  MARCH: 'Mars',
  APRIL: 'Avril',
  MAY: 'Mai',
  JUNE: 'Juin',
  JULY: 'Juillet',
  AUGUST: 'Août',
  SEPTEMBER: 'Septembre',
  OCTOBER: 'Octobre',
  NOVEMBER: 'Novembre',
  DECEMBER: 'Décembre',
  BASIS_SUBJECT_TO_VAT: 'Base soumise à la TVA',
  // ==============================
  REACHED_THE_END_OF_THE_PAGE:
    'Il semble que vous ayez atteint la fin de la page',
  ADD_A_NEW_FOLDER:
    'Vous pouvez ajouter ou créer un nouveau dossier en cliquant sur le bouton ci-dessus.',
  NO_DOCUMENT_FOUND: 'Aucun document trouvé',
  UPLOADING_FILES: 'Téléchargement de fichiers',
  FILE_UPLOADED_SUCCESSFULLY: 'Fichier téléchargé avec succès',
  LATE: 'En retard',
  DECLARED_BY: 'Déclaré par',
  PAYED_BY: 'Payé par',
  PAYED_AT: 'Payé à',
  PAYMENT_TYPE: 'Type de paiement',
  SAVE_THE_CHANGES: "N'oubliez pas de sauvegarder vos modifications",
  DISABLED: 'Désactivé',
  RECEIPT_VALIDATED: 'Ce reçu est validé',
  INIT: 'Non archivé',
  ARCHIVED: 'Archivé',
  INVOICE_DETAILS: 'Détails de la facture',
  RECEIPT_VENTILATION: 'Ventilation de la réception',
  ATTACHMENTS: 'Pièces jointes',
  EMPTY_PAYMENT: 'Le flux de paiement est vide',
  EMPTY_VENTILATION: 'La ventilation est vide',
  EMPTY_VENTILATION_DESCRIPTION:
    'Vous pouvez créer une nouvelle ventilation en cliquant sur le bouton ci-dessus ou attribuer un nouveau reçu.',
  No_Files: 'Aucun fichier trouvé',
  UPLOAD_FILE:
    'Vous pouvez joindre des fichiers en cliquant sur le bouton ci-dessus. Nous vous remercions de votre attention.',
  TVA_PERCENTAGE: 'TVA Pourcentage',
  RECEIPT_ARE_VALIDATED: 'Le reçu est validé',
  COMING_SOON: 'Bientôt disponible',
  GAP_AMOUNT: 'Mauvaise ventilation',
  RECEIPT_VENTILATION_NOT_EQUILIBRATED:
    'Ventilation de la réception non équilibrée',
  EMPTY_INBOX: 'Messagerie vide',
  DECLARED_ON: 'Déclarée le',
  EXPORT_DATA: 'Export données',
  ACCOUNTING_PLAN: 'Plan comptable',
  Number: 'Numéro',
  FISCAL_YEARS: 'Années fiscales',
  IMPORT_ACCOUNTING_PLAN: 'Importer plan comptable',
  IMPORT_BOOKENTRIES: 'Importer les écritures comptables',
  CATEGORY_BALANCE: 'Catégorie de la balance',
  PROFILE_PAGE: 'Page de profil',
  PROFILE_AVATAR: 'Avatar du profil',
  CHOOSE_FILE: 'Choisir un fichier',
  User: 'Utilisateur',
  OWNER: 'Propriétaire',
  USER: 'Utilisateur',
  ACCOUNTANT: 'Comptable',
  CONFIRM: 'Confirmer',
  MY_CURRENT_PLAN: 'Mon plan actuel',
  BUY_PAGES: 'Ajouter des pages',
  Purchase: 'Acheter',
  PURCHASE: 'Achat',
  'Amount before discount': 'Montant avant remise',
  Discount: 'Remise',
  'Amount after discount': 'Montant après remise',
  'Total net to pay': 'Net à payer',
  'Select Type': 'Sélectionner le type',
  INDIVIDUAL: 'Particulier',
  COMPANY: 'Entreprise',
  UPLOADING: 'Chargement',
  ADD_A_NEW_CATEGORY: 'Ajouter une nouvelle catégorie',
  UPDATE_CATEGORY: 'Mettre à jour la catégorie',
  ADD_NEW_CLIENT: 'Ajouter un nouveau client',
  SEND_MAIL_TO_THE_CLIENT: 'Envoyer un courrier au client',
  SUPPLIER_IS_REQUIRED: 'Le fournisseur est tenu',
  NOT_PAYED: 'Non payé',
  PARTIALLY_PAYED: 'Partiellement payé',
  SAVE_CHANGES: 'Enregistrer les modifications',
  RECEIPT_DELETED_SUCCESSFULLY: 'Reçu supprimé avec succès',
  DELETE_ATTACHMENT: 'Supprimer la pièce jointe',
  YOURE_ABOUT_TO_DELETE_THIS_ATTACHMENT:
    'Vous êtes sur le point de supprimer cette pièce jointe, êtes-vous sûr ?',
  "YOU'RE_ABOUT_TO_DELETE_THIS_MEMBER":
    'Vous êtes sur le point de supprimer ce membre (invitation), êtes-vous sûr ?',
  PLEASE_ENTER_TAX_ID: "Veuillez saisir votre numéro d'identification fiscale",
  SELECT_COMPANY_TYPE: 'Sélectionner le type de client',
  PLEASE_SELECT_COMPANY_TYPE: 'Veuillez sélectionner le type de client',
  PLEASE_ENTER_EMAIL: "Veuillez saisir l'adresse électronique",
  PLEASE_ENTER_LABEL: 'Veuillez saisir le nom',
  PLEASE_ENTER_ADDRESS: "Veuillez saisir l'adresse",
  SEARCH_BY_CODE_OR_LABEL_NAME: "Recherche par code ou nom d'étiquette",
  VENTILATION: 'Ventilation',
  TRANSACTION_UPDATED_SUCCESSFULLY: 'Transaction mise à jour avec succès',
  YOU_CANT_VALIDATE_THIS_TRANSACTION:
    'Vous ne pouvez pas valider cette transaction',
  THERE_IS_A_GAP_IN_THE_AMOUNT:
    "Ventilation erronée, il y a un écart dans le montant ; veuillez corriger l'écart afin de valider la transaction.",
  REPORT: 'Reporté',
  NEGATIVE_NUMBER: 'La ventilation contient un nombre négatif.',
  ATTACHMENT_SUCCESSFULLY_DELETED:
    'La pièce jointe a été supprimée avec succès',
  GET_STARTED: 'Commencer',
  BUY_NOW: 'Acheter maintenant',
  PAYMENT_FAILED: 'Échec du paiement',
  GO_BACK_TO_PAYMENT: 'Retour au paiement',
  PAYMENT_FAILED_DESCRIPTION:
    "Nous sommes désolés ! Votre paiement n'a pas pu être traité. Veuillez vérifier les détails de votre paiement et réessayer. Si le problème persiste, n'hésitez pas à contacter notre équipe de support pour obtenir de l'aide. Merci d'avoir choisi Uvey !",
};

export default FR_TRANSLATIONS;
