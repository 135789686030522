import type { TransferModelRequest } from '@/services/models/SubscriptionModels';

import { saasAPI } from './apis';

export default class SaasService {
  static getConnectedUser(signal?: AbortSignal) {
    return saasAPI.get(`/v1/auth/me`, {
      signal,
    });
  }

  static refreshAccessToken(companyId: string, signal?: AbortSignal) {
    return saasAPI.post(`/v1/auth/refresh`, null, {
      signal,
      headers: {
        'content-type': 'application/json',
        'X-Tenant-Id': companyId,
      },
      withCredentials: true,
    });
  }

  static checkPassword(password: string, signal?: AbortSignal) {
    const data = {
      password: password,
    };

    return saasAPI.post('/v1/auth/check-password', data, {
      signal,
    });
  }

  static async getMembersByCompanyId(signal?: AbortSignal) {
    return saasAPI.get(`/v1/members`, {
      signal,
    });
  }

  static getCredits(signal?: AbortSignal) {
    return saasAPI.get(`/v1/subscriptions/remaining-pages-credit`, {
      signal,
    });
  }

  static addCredits(credit?: number, signal?: AbortSignal) {
    return saasAPI.patch(
      `/v1/subscriptions/remaining-pages-credit/${credit}/credit`,
      {
        signal,
      }
    );
  }

  static purchasePages(credit?: number, signal?: AbortSignal) {
    return saasAPI.patch(`/v1/subscriptions/${credit}/purchase`, {
      signal,
    });
  }

  static async getAMember(idMember: string, signal?: AbortSignal) {
    return saasAPI.get(`/v1/members/${idMember}`, { signal });
  }

  static async getUserLogo(idUser: string, signal?: AbortSignal) {
    return await saasAPI(`/v1/users/${idUser}/logo`, {
      signal,
    });
  }

  static async getUserById(idUser: string, signal?: AbortSignal) {
    const params = {
      id: idUser,
    };
    return saasAPI.get(`/v1/users`, { signal, params });
  }

  static transferSubscriptionPages(
    data: TransferModelRequest,
    signal?: AbortSignal
  ) {
    return saasAPI.patch(`/v1/subscriptions/transfer-pages`, data, {
      signal,
    });
  }

  static async getTransferHistoryWithPagination(
    fiscalYear: number | null | undefined,
    pageNumber: number | undefined,
    pageSize?: number | undefined,
    amountMin?: number | undefined | null,
    amountMax?: number | undefined | null,
    signal?: AbortSignal
  ) {
    let url = '/v1/subscriptions/transfer-history';
    const queryParameters: string[] = [];
    if (fiscalYear) {
      queryParameters.push(`fiscalYear=${fiscalYear}`);
    }
    if (pageNumber) {
      queryParameters.push(`pageNumber=${pageNumber}`);
    }
    if (pageSize) {
      queryParameters.push(`pageSize=${pageSize}`);
    }
    if (amountMin) {
      queryParameters.push(`amountMin=${amountMin}`);
    }
    if (amountMax) {
      queryParameters.push(`amountMax=${amountMax}`);
    }
    if (fiscalYear) {
      queryParameters.push(`fiscalYear=${fiscalYear}`);
    }

    if (queryParameters.length > 0) {
      url += '?' + queryParameters.join('&');
    }
    return saasAPI.get(url, {
      signal,
    });
  }
}
