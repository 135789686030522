const isLate = (when: string | undefined, shouldBeBefore: string) => {
  if (!when) return;
  let _date1 = new Date(when).getTime();
  let _date2 = new Date(shouldBeBefore).getTime();

  return _date1 >= _date2;
};

enum DeclarationStatusEnum {
  INITIAL = 'INIT',
  PAYED = 'PAYED',
  DECLARED = 'DECLARED',
  CANCELLED = 'CANCELLED',
}
const parseAmountWithComma = (amountString: string) => {
  const cleanedAmountString = amountString.replace(',', '.');
  return parseFloat(cleanedAmountString) || null;
};

const getFileExtension = (filename: string | undefined): string => {
  const extension = filename?.split('.').pop()?.toLowerCase();
  return extension ?? '';
};

const getPasswordStyle = (isPasswordVisible: boolean) => {
  const baseStyle = {
    WebkitTextSecurity: 'disc',
    textSecurity: 'disc',
  };

  return isPasswordVisible ? baseStyle : {};
};

export {
  isLate,
  getFileExtension,
  parseAmountWithComma,
  DeclarationStatusEnum,
  getPasswordStyle,
};
